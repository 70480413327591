import { LinkBuilder, Routes } from './interfaces';
import { getBrokerUrlSegment } from 'utils/urlCore';

function sanitizeSegment(segment: string) {
  return segment ? segment.replace(/ /g, '-').toLowerCase() : '';
}

export const lwRoutes: Partial<Routes> = {
  /********** Static routes **********/
  addListing: '/add-listing',
  advertisingTerms: '/advertising-terms',
  terms: '/terms-conditions',
  termsAndConditionsForPhotos: '/terms-and-conditions-for-submitting-photos',

  /********** Sitemap routes **********/
  sitemap: {
    route: '/sitemap',
    link: (location: LinkBuilder) => {
      const sitemapLocation = location.canonicalLocation ? `/${sanitizeSegment(location.canonicalLocation)}` : '';
      return `/sitemap${sitemapLocation}`;
    }
  },

  /********** Broker member and search routes **********/
  brokerDirectory: { route: '/find-agent/:restofPath*', link: '/find-agent' },
  brokerResultsMember: {
    route: `/profile/:brokerName/:brokerId`,
    link: ({ contactName, companyName, accountId }) => {
      const brokerUrlSegment = getBrokerUrlSegment(contactName, companyName);
      return `/profile/${brokerUrlSegment}/${accountId}`;
    }
  },
  brokerResultsMemberListings: {
    route: `/profile/:brokerName/:brokerId/properties`,
    link: ({ contactName, companyName, accountId }) => {
      const brokerUrlSegment = getBrokerUrlSegment(contactName, companyName);
      return `/profile/${brokerUrlSegment}/${accountId}/properties`;
    }
  },

  /********** Property listing routes **********/
  listing: '/pid/:restofPath*',
  property: {
    route: '/:propertyGeo/pid/:restofPath*',
    link: (location: LinkBuilder) => `/${location.canonicalLocation}/pid/${location.propertyId}`
  },

  /********** Property search routes **********/
  baseSearchUrl: {
    link: (location: LinkBuilder) => {
      const { propertyType, stateName, cityName, countyName, regionName, customSegments } = location;
      const locationInState = cityName || countyName || (regionName ? `${regionName}-region` : '');
      const stateNameSegment = stateName ? `/${sanitizeSegment(stateName)}-land-for-sale` : '';
      const locationInStateSegment = stateName && locationInState ? `/${sanitizeSegment(locationInState)}` : '';

      if (customSegments) {
        return !stateName
          ? `/land/${customSegments}`
          : `${stateNameSegment}${locationInStateSegment}/${customSegments}`;
      }

      const propertyTypeSegment = propertyType ? `/${sanitizeSegment(propertyType)}` : '';

      return `${stateNameSegment}${locationInStateSegment}${propertyTypeSegment}` || '/land';
    }
  },
  // On LW, the search routes are the last routes we try to match on the server. This makes them a catch-all route.
  searchWithCriteria: ''
};
