import React from 'react';
import GlobalStore from 'store/globalStore';
import GlobalHandler from 'handler/globalHandler';
import ShowMoreLessList from 'components/ui/ShowMoreLessList';
import styles from './FooterLW.scss';
import { renderLinks } from 'components/layout/Footer/footerLinkHelpers';
import LazyLoad from 'react-lazyload';

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
  footerContext: any;
}

const FooterLW: React.FunctionComponent<Props> = props => {
  const { store, footerContext } = props;
  const { addListing, contact, terms, sitemap, privacy, caPrivacy, home, brokerDirectory } = store.routing;
  const { stateLinkList, sortedStateLinkList } = footerContext;

  const aboutUsLinkList = renderLinks(
    {
      Home: { url: home, newTab: false },
      'Contact Us': { url: contact, newTab: false },
      'Terms and Conditions': { url: terms, newTab: false },
      'Privacy Policy': { url: privacy, newTab: true },
      'CA: Do Not Sell My Personal Info': { url: caPrivacy, newTab: true },
      Sitemap: { url: sitemap.link({}), newTab: false }
    },
    styles
  );

  const resourceLinkList = renderLinks(
    {
      'Add a Listing': { url: addListing, newTab: false },
      Advertise: { url: 'https://network.land.com/advertise-brand-advertising/', newTab: true },
      'Find an Agent': { url: brokerDirectory?.link, newTab: false }
    },
    styles
  );

  const now = new Date();
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles['state-links']}>
          <div className={styles.heading}>Listings By State</div>
          <ShowMoreLessList
            collapsedLength={10}
            fnGetList={() => stateLinkList}
            fnGetExpandedList={() => sortedStateLinkList}
            moreText="View More"
            lessText="View Less"
            buttonStyle="footer"
            wrapItemsInUL={true}
          />
        </div>
        <div className={styles['resource-links']}>
          <div className={styles.heading}>Resources</div>
          <ul className={styles.ul} role="menu">
            {resourceLinkList}
          </ul>
        </div>
        <div className={styles['about-links']}>
          <div className={styles.heading}>About Us</div>
          <ul className={styles.ul} role="menu">
            {aboutUsLinkList}
          </ul>
        </div>
      </div>
      <div className="be-ix-link-block">{/*Brightedge Link Block Target Div*/}</div>
      <div className={styles['page-end']}>
        <div className={styles.social}>
          <LazyLoad>
            <span className={styles['social-follow']}>Follow Us</span>
            <a
              aria-label="Facebook Link"
              rel="noopener noreferrer"
              target="_blank"
              role="button"
              href="https://www.facebook.com/LandWatch"
            >
              <span className={styles.facebook} />
            </a>
            <a
              aria-label="Twitter Link"
              rel="noopener noreferrer"
              target="_blank"
              role="button"
              href="https://twitter.com/landwatch"
            >
              <span className={styles.twitter} />
            </a>
            <a
              aria-label="Instagram Link"
              rel="noopener noreferrer"
              target="_blank"
              role="button"
              href="https://www.instagram.com/landwatch/"
            >
              <span className={styles.instagram} />
            </a>
          </LazyLoad>
        </div>
        <div className={styles.info}>
          <p className={styles.copy}>&copy;{` ${now.getFullYear()} CoStar Group`}</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterLW;
