export function isNull(value: any): boolean {
  return value === null;
}

export function isUndefined(value?: any): boolean {
  return typeof value === 'undefined';
}

export function isNullOrUndefined(value?: any): boolean {
  return isNull(value) || isUndefined(value);
}

/**
 * Does a basic deep equal comparison excluding components or methods.
 */
export function isEqual(value1: any, value2: any): boolean {
  if (isNullOrUndefined(value1) || isNullOrUndefined(value2)) {
    return false;
  }

  return JSON.stringify(value1) === JSON.stringify(value2);
}

export function isString(value: any): boolean {
  if (isNullOrUndefined(value)) {
    return false;
  }

  return typeof value === 'string';
}

export function isObject(value: any): boolean {
  const type = typeof value;
  return value != null && (type == 'object' || type == 'function');
}

export function isNumber(value: any): boolean {
  if (isNullOrUndefined(value)) {
    return false;
  }

  if (isString(value) || isObject(value)) {
    return false;
  }

  return !isNaN(value);
}

export function isFunction(obj: any): boolean {
  if (!obj) {
    return false;
  }

  return Object.prototype.toString.call(obj) === '[object Function]';
}

export function isEmpty(value: any): boolean {
  if (isNullOrUndefined(value)) {
    return true;
  }

  if (typeof value === 'string') {
    return value.trim().length === 0;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }

  // In the lodash version of isEmpty numbers and booleans are considered empty.
  if (!isNaN(value) || typeof value === 'boolean') {
    return true;
  }

  return false;
}

export function without(array: ReadonlyArray<any>, value: any): Array<any> {
  if (isNullOrUndefined(array) || isNullOrUndefined(value)) {
    return [];
  }

  if (array.length === 0) {
    return [];
  }

  const withoutArray: Array<any> = array.filter(arrayValue => {
    if (!isEqual(arrayValue, value)) {
      return arrayValue;
    }
  });

  return withoutArray;
}

export function uniq(array: ReadonlyArray<string | number>): Array<any> {
  if (isNullOrUndefined(array)) {
    return null;
  }

  if (!array.length) {
    return [];
  }

  const obj = {};
  array.forEach(value => {
    obj[value] = value;
  });

  return Object.values(obj);
}

/**
 * Returns a new array with all falsey values stripped out of the passed in array.
 * @param array
 * @returns A new array with all falsey values removed.
 */
export function compact(array: Array<any>) {
  let index = -1,
    resIndex = 0;

  const length = !array ? 0 : array.length,
    result = [];

  while (++index < length) {
    const value = array[index];
    if (value) {
      result[resIndex++] = value;
    }
  }

  return result;
}

export function last(array: Array<any>) {
  const length = !array ? 0 : array.length;
  return length ? array[length - 1] : undefined;
}

export function first(array: Array<any>) {
  return array && array.length ? array[0] : undefined;
}

let idCounter = 0;
export function uniqueId(prefix = '') {
  return (prefix + ++idCounter).toString();
}

export function castArray(value) {
  if (isNullOrUndefined(value)) {
    return [];
  }

  return Array.isArray(value) ? value : [value];
}

export const debounce = (callback, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(this, args), wait);
  };
};

export const noop = () => {
  /* Do nothing. */
};

/* Use to create a deep copy of an object or array.
 * MUST NOT INCLUDE: Dates, functions, undefined, Infinity, RegExps, Maps, Sets, Blobs,
 * FileLists, ImageDatas, sparse Arrays, Typed Arrays or other complex types. */
export function basicDeepCopy(value: any): any {
  return JSON.parse(JSON.stringify(value));
}
