export const defaultPasswordError = `The password does not meet the requirements. Please try again.`;
export const noThumbnail = 2680603772;
export const noPhotoProvided = 3765161205;
export const MobileLargeWidth = 431;

// standard MP screen breakpoints
export enum BreakPoints {
  smallest = 375,
  small = 601,
  medium = 901,
  listViewMapView = 990,
  tablet = 1025,
  mediumLarge = 1201,
  largerThanIpadPro = 1401,
  large = 1601
}

export enum ZoomLevels {
  // Currently, we only display map parcel layer if zoom level is >= 15 (2/19/2025)
  parcelZoom = 15
}

// Classes of images used on the MP sites.
export enum ImageClass {
  // Section 1: based on usage:
  agentPortraitLarge,
  brokerLogoLarge,
  brokerLogoLand,
  brokerLogoStickyFooter,
  brokerLogoLw,
  detailDesktop,
  detailMobile,
  filterSuggest,
  flyer,
  huge,
  icons,
  imageAttachment,
  map,
  shareProperty,
  thumbnail,
  financeCenterLogo,
  upgradedHeroCarouselSmallImg,
  diamondHeroCarouselLargeImg,
  diamondHeroCarouselMobileImg,
  diamondHeroCarouselTabletImg,
  LandMagazineArticleImg,
  placardMediumColumn,
  placardLargeColumn,
  placardHugeColumn,
  homeShowcaseTwoColumns,
  homeShowcaseFourColumns,
  homeFavoriteLargeDesktop,
  homeFavoriteLargeTablet,
  homeFavoriteTwoImageTablet,
  homeFavoriteLargeMobile,
  homeFavoriteSmallDesktop,
  homeFavoriteSmallTablet,
  homeFavoriteSmallMobile,

  // Section 2: based on window size:
  desktop,
  extraLgScreen,
  mobile,
  tablet,

  // Section 3: based on listing level:
  // basic and standard are the same
  basic,
  free,
  premium,
  signature,
  standard,

  // Section 4: based on ad/upsell:
  // featured and gold are the same
  featured,
  gold,
  // platinum, showcase are the same
  platinum,
  showcase,
  diamond,
  diamondMobile,
  diamondSmallTablet,
  diamondTablet,
  diamondDesktop
}

// ImageClasses stores ImageInfo about each class of images used by the system
export const ImageClasses: ImageClassInfo[] = {} as ImageClassInfo[];

// determines how images are sized by the aws lambda
export enum ImageFit {
  // must provide height, width ignored, returned image matches requested height, width maintains the image's aspect ratio
  constrainHeight = 'h',

  // must provide width, height ignored, returned image matches requested width, height maintains the image's aspect ratio
  constrainWidth = 'w',

  // must provide height and width, returned image size will wholly fit within requested dimensions and maintain aspect ratio
  contain = 'c',

  // must provide height and width, returned image size will fully cover the requested dimensions and maintain aspect ratio
  // Note that, at most, one axis (height or width), may be larger than the requested dimension so that the other dimension
  // match the requested size.
  cover = 'l'
}

// what format should the returned image be
export enum ImageFormat {
  default = '',
  jpg = 'j',
  pjpg = 'pj',
  png = 'p',
  raw = 'r',
  webp = 'w'
}

export enum ImageMimeType {
  webp = 'image/webp',
  jpeg = 'image/jpeg'
}

// image size as a string with 'px' for styles
// encapsulates requestable options for a resized image
export interface ImageClassInfo {
  fit: ImageFit;
  size: ImageSize;
  quality: ImageQuality;
}

// what quality level should the returned image be
export enum ImageQuality {
  low = 40,
  medium = 60,
  high = 80,
  default = 80,
  highest = 100
}

// image size
export interface ImageSize {
  height: number;
  width: number;
}

export interface ImageSizePx {
  height: string;
  width: string;
}

// stores image sizes as a string with 'px' for styles
export const ImageSizesPx = {};

export interface ImageSource {
  imageType: string;
  imageUrl: string;
  media: string;
}

export const ImageSourceMediaQueries = {
  none: '',
  small: `(min-width: ${BreakPoints.small}px)`,
  medium: `(min-width: ${BreakPoints.medium}px)`,
  large: `(min-width: ${BreakPoints.large}px)`
};

// generate an image url by specifying each segment's value as a parameter
export const ImageUrlRaw = (
  domain: string,
  id: number | string,
  size: ImageSize,
  fit: ImageFit,
  quality: ImageQuality,
  format: ImageFormat = ImageFormat.default
): string => {
  const imageFormat = format !== ImageFormat.default ? `/${format}` : '';
  return `${domain}/resizedimages/${size.height}/${size.width}/${fit}/${quality}${imageFormat}/1-${id}`;
};

// generate an image url by specifying an image class (preferred method)
export const ImageUrl = (
  iClass: ImageClass,
  domain: string,
  id: number | string,
  format: ImageFormat = ImageFormat.default
): string => {
  const imageInfo = ImageClasses[ImageClass[iClass]];
  return ImageUrlRaw(domain, id, imageInfo.size, imageInfo.fit, imageInfo.quality, format);
};

// Create ImageInfo for passed-in values and add it to ImageClasses.
function CreateImageInfo(
  key: string,
  fit: ImageFit = ImageFit.constrainHeight,
  quality: ImageQuality = ImageQuality.default
) {
  // save the fit, format, and quality
  const iInfo = {
    fit: fit,
    quality: quality
  } as ImageClassInfo;

  // set size and override other settings as needed based on class
  // a height or width of 0 means the other value is the constraint
  switch (ImageClass[key]) {
    // Make these the same size as agentPortraitLarge
    // so they get cached and only need to be downloaded
    // once.
    case ImageClass.brokerLogoStickyFooter:
    case ImageClass.brokerLogoLand:
    case ImageClass.brokerLogoLw:
    case ImageClass.agentPortraitLarge:
      iInfo.size = { height: 150, width: 150 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.basic:
    case ImageClass.standard:
    case ImageClass.premium:
      iInfo.size = { height: 162, width: 243 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.brokerLogoLarge:
      iInfo.size = { height: 70, width: 0 };
      iInfo.fit = ImageFit.constrainHeight;
      break;

    case ImageClass.desktop:
      iInfo.size = { height: 229, width: 374 };
      break;

    case ImageClass.detailDesktop:
      iInfo.size = { height: 600, width: 0 };
      iInfo.fit = ImageFit.constrainHeight;
      break;

    case ImageClass.detailMobile:
      iInfo.size = { height: 400, width: 0 };
      iInfo.fit = ImageFit.constrainHeight;
      break;

    case ImageClass.extraLgScreen:
      iInfo.size = { height: 214, width: 350 };
      break;

    case ImageClass.featured:
    case ImageClass.gold:
      iInfo.size = { height: 216, width: 320 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.filterSuggest:
      iInfo.size = { height: 0, width: 77 };
      iInfo.fit = ImageFit.constrainWidth;
      break;

    case ImageClass.flyer:
      iInfo.size = { height: 394, width: 0 };
      iInfo.fit = ImageFit.constrainHeight;
      break;

    case ImageClass.free:
      iInfo.size = { height: 134, width: 201 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.huge:
      iInfo.size = { height: 10000, width: 0 };
      iInfo.fit = ImageFit.constrainHeight;
      break;

    case ImageClass.icons:
      iInfo.size = { height: 25, width: 25 };
      break;

    case ImageClass.imageAttachment:
      iInfo.size = { height: 0, width: 5000 };
      iInfo.fit = ImageFit.constrainWidth;
      break;

    case ImageClass.map:
      iInfo.size = { height: 160, width: 210 };
      break;

    case ImageClass.mobile:
      iInfo.size = { height: 343, width: 560 };
      break;

    case ImageClass.LandMagazineArticleImg:
      iInfo.size = { height: 175, width: 350 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.diamond:
    case ImageClass.diamondTablet:
    case ImageClass.diamondDesktop:
      iInfo.size = { height: 360, width: 990 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.shareProperty:
      iInfo.size = { height: 113, width: 159 };
      iInfo.quality = ImageQuality.high;
      break;

    case ImageClass.signature:
      iInfo.size = { height: 185, width: 277 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.tablet:
      iInfo.size = { height: 259, width: 423 };
      break;

    case ImageClass.thumbnail:
      iInfo.size = { height: 81, width: 122 };
      break;

    case ImageClass.financeCenterLogo:
      iInfo.size = { height: 0, width: 230 };
      break;

    // Listing Detail Page Hero Carousel.
    // The smaller images displayed on Desktop/Tablet (not the first large image).
    case ImageClass.upgradedHeroCarouselSmallImg:
      iInfo.size = { height: 344, width: 477 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.diamondHeroCarouselLargeImg:
      iInfo.size = { height: 690, width: 960 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.platinum:
    case ImageClass.showcase:
    case ImageClass.diamondMobile:
      iInfo.size = { height: 304, width: 430 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.diamondHeroCarouselMobileImg:
      iInfo.size = { height: 358, width: 430 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.diamondHeroCarouselTabletImg:
      iInfo.size = { height: 500, width: 600 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.placardMediumColumn:
      iInfo.size = { height: 0, width: MobileLargeWidth };
      iInfo.fit = ImageFit.constrainWidth;
      break;

    case ImageClass.diamondSmallTablet:
    case ImageClass.placardLargeColumn:
      iInfo.size = { height: 0, width: BreakPoints.small };
      iInfo.fit = ImageFit.constrainWidth;
      break;

    case ImageClass.placardHugeColumn:
      iInfo.size = { height: 0, width: BreakPoints.medium };
      iInfo.fit = ImageFit.constrainWidth;
      break;

    case ImageClass.homeShowcaseTwoColumns:
    case ImageClass.homeShowcaseFourColumns:
      iInfo.size = { height: 280, width: 456 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.homeFavoriteLargeDesktop:
      iInfo.size = { height: 440, width: 712 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.homeFavoriteTwoImageTablet:
      iInfo.size = { height: 320, width: 868 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.homeFavoriteLargeTablet:
      iInfo.size = { height: 440, width: 868 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.homeFavoriteLargeMobile:
      iInfo.size = { height: 276, width: 568 };
      iInfo.fit = ImageFit.cover;
      break;

    case ImageClass.homeFavoriteSmallDesktop:
      iInfo.size = { height: 212, width: 348 };
      iInfo.fit = ImageFit.cover;
      break;
    case ImageClass.homeFavoriteSmallTablet:
      iInfo.size = { height: 212, width: 426 };
      iInfo.fit = ImageFit.cover;
      break;
    case ImageClass.homeFavoriteSmallMobile:
      iInfo.size = { height: 130, width: 276 };
      iInfo.fit = ImageFit.cover;
      break;
  }

  // save it
  ImageClasses[key] = iInfo;
}

// create a ImageSizePx for the given className and add it to ImageSizesPx
export function CreateImageSizePx(key: string) {
  const iInfo = ImageClasses[key];
  const iSizePx = {
    height: `${iInfo.size.height}px`,
    width: `${iInfo.size.width}px`
  } as ImageSizePx;

  ImageSizesPx[key] = iSizePx;
}

// for each image class...
for (const val in Object.keys(ImageClass)) {
  const key = ImageClass[val];
  if (key) {
    // create an ImageInfo object and add it to ImageClasses
    CreateImageInfo(key);

    // create an ImageSizePx object and add it to ImageSizesPx
    CreateImageSizePx(key);
  }
}

export interface PictureSources {
  fallbackUrl: string;
  sources: Array<ImageSource>;
}

// This method should be used any time we're replacing one image like a JPEG with a
// list of possible images by type. The fallbackUrl will likely stay a JPEG url for
// the forseeable future.
export const GetImageSources = (imageClass: ImageClass, domain: string, documentId: number): PictureSources => {
  const fallbackUrl = ImageUrl(imageClass, domain, documentId);
  return {
    fallbackUrl,
    sources: [
      {
        imageType: ImageMimeType.webp,
        imageUrl: ImageUrl(imageClass, domain, documentId, ImageFormat.webp),
        media: ImageSourceMediaQueries.none
      },
      {
        imageType: ImageMimeType.jpeg,
        imageUrl: fallbackUrl,
        media: ImageSourceMediaQueries.none
      }
    ]
  };
};

// Returns an array of picture source elements for property cards.
export const PropertyCardImageSources = (domain: string, documentId: number): Array<ImageSource> => {
  return [
    // WEBP
    {
      imageType: ImageMimeType.webp,
      imageUrl: ImageUrl(ImageClass.extraLgScreen, domain, documentId, ImageFormat.webp),
      media: ImageSourceMediaQueries.large
    },
    {
      imageType: ImageMimeType.webp,
      imageUrl: ImageUrl(ImageClass.desktop, domain, documentId, ImageFormat.webp),
      media: ImageSourceMediaQueries.medium
    },
    {
      imageType: ImageMimeType.webp,
      imageUrl: ImageUrl(ImageClass.tablet, domain, documentId, ImageFormat.webp),
      media: ImageSourceMediaQueries.small
    },
    {
      imageType: ImageMimeType.webp,
      imageUrl: ImageUrl(ImageClass.mobile, domain, documentId, ImageFormat.webp),
      media: ImageSourceMediaQueries.none
    },
    // JPEG
    {
      imageType: ImageMimeType.jpeg,
      imageUrl: ImageUrl(ImageClass.extraLgScreen, domain, documentId),
      media: ImageSourceMediaQueries.large
    },
    {
      imageType: ImageMimeType.jpeg,
      imageUrl: ImageUrl(ImageClass.desktop, domain, documentId),
      media: ImageSourceMediaQueries.medium
    },
    {
      imageType: ImageMimeType.jpeg,
      imageUrl: ImageUrl(ImageClass.tablet, domain, documentId),
      media: ImageSourceMediaQueries.small
    },
    {
      imageType: ImageMimeType.jpeg,
      imageUrl: ImageUrl(ImageClass.mobile, domain, documentId),
      media: ImageSourceMediaQueries.none
    }
  ];
};
