import utils from 'utils/convenience';

export default class ModalHelper<T> {
  isExpanded = false;
  isSubmitting = false;
  isValid = false;
  data: T = null;
  message = '';
  sent = false;
  success = true;
  continuation: () => void = null;

  constructor(currentState: Partial<ModalHelper<T>>, updateInfo: any) {
    utils.coalesceState(this, currentState, updateInfo);
  }
}
