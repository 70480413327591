/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { Fragment, useState } from 'react';
import cx from 'classnames';
import styles from './ShowMoreLessList.scss';
import { SITE_IDS } from 'utils/enums';
import Restricted from 'components/ui/Restricted';

interface Props {
  collapsedLength: number; // Maximum number of items to show when we are showing less items.
  fnGetList: () => JSX.Element[];
  fnGetExpandedList?: () => JSX.Element[]; // An optional alternative list in expanded state.
  moreText?: string;
  lessText?: string;
  buttonStyle?: string; // Optional CSS styling on the more/less button.
  arrowStyle?: string; // Optional CSS styling on the arrow.
  wrapItemsInUL?: boolean;
}

const ShowMoreLessList = (props: Props) => {
  const [isShowingMore, setIsShowingMore] = useState(false);

  const wrapMoreOrLessButtonInUL = (button: JSX.Element) => {
    return <ul role="menu">{button}</ul>;
  };

  const moreOrLessButton = (): JSX.Element => {
    const { moreText, lessText, buttonStyle, arrowStyle } = props;
    const propButtonStyle = buttonStyle === 'footer' ? styles['footer-trigger'] : buttonStyle;
    const classNames = cx(propButtonStyle, styles.trigger);

    return (
      <li
        aria-haspopup="listbox"
        className={classNames}
        role="menuitem"
        onClick={() => setIsShowingMore(!isShowingMore)}
      >
        {isShowingMore ? lessText || 'Less' : moreText || 'More'}
        <Restricted to={[SITE_IDS.LANDANDFARM, SITE_IDS.LAND]}>
          <span className={cx(styles.arrow, isShowingMore && styles['arrow-up'], arrowStyle)} />
        </Restricted>
      </li>
    );
  };

  const { collapsedLength, fnGetList, fnGetExpandedList, wrapItemsInUL } = props;

  // use the callback functions to get the lists of elements.
  const list = (fnGetList && fnGetList()) || [];
  const expandedList = (fnGetExpandedList && fnGetExpandedList()) || [];

  // determine the lists for both the collapsed and expanded states
  const lessList = list.slice(0, collapsedLength);

  // From all the lists provided, returns all unique elements which won't be visible.
  // This list of "hidden" elements is then included in the HTML for SEO purposes.
  const hiddenListForSEO = isShowingMore ? [] : list.slice(collapsedLength);

  const moreList = fnGetExpandedList ? expandedList : list; // Use the alternative expanded list if provided.

  // Determine which list elements will be visible and which will be hidden.
  const visibleList = isShowingMore ? moreList : lessList;
  const showButton = moreList.length > lessList.length;

  return (
    <Fragment>
      {wrapItemsInUL ? <ul role="menu">{visibleList}</ul> : visibleList}
      {showButton && (wrapItemsInUL ? wrapMoreOrLessButtonInUL(moreOrLessButton()) : moreOrLessButton())}
      <ul hidden role="menu">
        {hiddenListForSEO}
      </ul>
    </Fragment>
  );
};

export default ShowMoreLessList;
