import utils from 'utils/convenience';
import { LeadSource } from 'utils/enums';

// NOTE: The only data that should be put into this class are UI settings that exist only on the front-end.
// Data that comes from the backend (such as search info, listing info, or broker info) should NOT be placed in here.
export default class GlobalUI {
  contactBrokerModalToggle = false;
  reportListingModalToggle = false;
  feedbackModalToggle = false;
  showFinanceCenterContactModal = false;
  previousSearchScroll = 0;
  urlHash = null;
  leadSource: LeadSource = 0;
  showCheckboxes = true;
  isDirections = false; // TODO #2509647: does this really need to be in the globalUI?
  markupStyle: string = null; // TODO #2509647: does this really need to be in the globalUI?
  // isCreditFarm is currently unused, but we are leaving it in place for
  // now in case product changes their mind and decides to use it again.
  isCreditFarm = false;
  isBuyerAgentAd = false;
  brokerContactMessage = ''; // TODO #2509647: does this really need to be in the globalUI?

  constructor(currentState: Partial<GlobalUI> = {}, updateInfo: Partial<GlobalUI> = {}) {
    utils.coalesceState(this, currentState, updateInfo);
  }
}
