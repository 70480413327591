import LoaApp from 'components/App';
import LoginInfo from 'store/models/loginInfo';
import utils from 'utils/convenience';

// This class is explicitly NOT part of handleUserAuthentication as it is
// the only part that is need by the main bundle right away. Its being
// called from App.tsx.
export default class HandleUserAuthenticationCore {
  classMembers: string[];
  app: LoaApp;
  knownDevice = 'knownDevice';

  constructor(loaApp: LoaApp) {
    this.app = loaApp;
  }

  async isLoggedIn(): Promise<boolean> {
    const rawResponse = await fetch('/api/authenticate/isloggedin', { credentials: 'same-origin' });
    const isLoggedIn = await rawResponse.json();
    if (isLoggedIn !== this.app.state.loginInfo.isLoggedIn) {
      await this.getUser();
    }
    return isLoggedIn;
  }

  async getUser(): Promise<LoginInfo> {
    const { siteId } = this.app.state;
    const rawResponse = await fetch(`/api/authenticate/logininfo/${siteId}`, { credentials: 'same-origin' });
    const loginInfo = await rawResponse.json();

    this.app.setState({
      loginInfo: new LoginInfo(this.app.state.loginInfo, loginInfo || { isLoggedIn: false })
    });

    return loginInfo;
  }

  async loginViaToken(token): Promise<LoginInfo> {
    const { siteId } = this.app.state;
    const rawResponse = await fetch(`/api/authenticate/loginviatoken/${token}/${siteId}`, {
      method: 'POST'
    });

    let loginResponse = {
      isLoggedIn: false,
      userInfo: null,
      message: `We are unable to process your login at this time. Please contact ${this.app.state.settings.contact.Support}.`,
      hashedEmail: null
    };

    if (rawResponse.ok) {
      loginResponse = await rawResponse.json();
      loginResponse.message = loginResponse.isLoggedIn ? null : 'Login via token did not succeed.';
    }

    const shouldDisplayLoginPopover =
      loginResponse.isLoggedIn && loginResponse.userInfo?.isSeller && !utils.getLoginPopoverDisplayedFromCookies();

    if (shouldDisplayLoginPopover) {
      window.localStorage.setItem('loginPopoverDisplayed', 'true');
    }

    this.app.setState({
      loginInfo: new LoginInfo(this.app.state.loginInfo, {
        isLoggedIn: loginResponse.isLoggedIn,
        userInfo: loginResponse.userInfo,
        hashedEmail: loginResponse.hashedEmail
      }),
      shouldDisplayLoginPopover
    });

    return loginResponse;
  }

  // This is to handle customer service being able to log in as someone else from within LOA Admin.
  // It is also used by PCC app to log the current user into MP apps.
  checkLoginRequest = async history => {
    if (!window.location.search) {
      // bail if nothing is available
      return;
    }

    const query = window.location.search.substring(1);
    if (!query) {
      // bail if nothing is available
      return;
    }

    const kvPairs = query.split('&');
    for (const kvp of kvPairs) {
      const part = kvp.split('=');
      if (part[0] === 'logintoken') {
        // replace history state with url and no querystring, so that back button won't trigger login a second time
        history.replace(window.location.pathname);
        const loginResponse = await this.loginViaToken(part[1]);

        // Show failure to login to everyone, if login failed.
        // Only show identification popup if we came from the admin site with a logintoken.
        if (!loginResponse.isLoggedIn) {
          alert('Failed to login.');
        }
        break;
      }
    }
  };
}
