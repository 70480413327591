function createElement(id: string, tag: string) {
  const el = document.createElement(tag);
  el.id = id;
  document.body.appendChild(el);
  return el;
}

export function getElementByIdOrCreate(id: string, tag = 'div') {
  return document.getElementById(id) || createElement(id, tag);
}
