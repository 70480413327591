import { Component } from 'react';
import GlobalStore from 'store/globalStore';
import GlobalHandler from 'handler/globalHandler';
import { BreakPoints } from 'utils/variables';
import { retry } from 'utils/retry';
import styles from './Header.scss';
import cx from 'classnames';
import { PAGE_TYPE } from 'utils/enums';

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
}

interface State {
  showMobileMenu: boolean;
  closeNavLink: boolean;
  modals: any;
  menu: any;
  header: any;
  headerShell: any;
  LocationBar: any;
}

class Header extends Component<Props, State> {
  currentUrl = '';
  buyMenu: any = null;
  state = {
    showMobileMenu: false,
    closeNavLink: false,
    modals: null,
    menu: null,
    header: null,
    headerShell: null,
    LocationBar: null
  };

  openMobileMenu = () => {
    this.setState(() => ({ showMobileMenu: true }));
    window.scroll(0, 0);
    if (this.state.menu === null) {
      this.importMenu();
    }
  };

  closeMobileMenu = () => {
    this.setState(
      () => ({ showMobileMenu: false, closeNavLink: true }),
      () => this.setState(() => ({ closeNavLink: false }))
    );
    window.scroll(0, 0);
  };

  importComponent = (keyName: string, componentName: string) => {
    const { componentDirectory } = this.props.store;

    retry(() => import(`components/layout/${componentDirectory}/${componentName}`)).then(component =>
      this.setState(() => ({ ...this.state, [`${keyName}`]: component.default }))
    );
  };

  importMenu() {
    const { siteIdentity, windowWidth } = this.props.store;
    const { isLAF, isLW, isLand } = siteIdentity;
    if (this.state.menu === null) {
      if ((isLAF || isLW) && windowWidth < BreakPoints.largerThanIpadPro && this.state.showMobileMenu) {
        retry(() => import(`components/layout/MobileMenu/MobileMenu`)).then(component =>
          this.setState(() => ({ ...this.state, menu: component.default }))
        );
      }
      if (
        isLand &&
        ((windowWidth <= BreakPoints.medium && this.state.showMobileMenu) || windowWidth >= BreakPoints.medium)
      ) {
        this.importComponent('menu', 'Menu');
      }
    }
  }

  handleLogoutLocation = () => {
    return window.location.pathname.toLocaleLowerCase().indexOf('/account/') === -1
      ? window.location.pathname.toLocaleLowerCase() + window.location.search
      : '/';
  };

  importLocationBar = () => {
    retry(
      () =>
        import(
          /* webpackChunkName: "MobileHeaderLocationBar" */ 'components/pages/PropertySearch/Search/MobileHeaderLocationBar'
        )
    ).then(component => this.setState(() => ({ LocationBar: component.default })));
  };

  showMobileHeaderLocationBar = () => {
    const { isLand } = this.props.store.siteIdentity;
    const { pageType, isDiamondListing } = this.props.store.routeContext;
    const pagesWithoutLocationBar = [PAGE_TYPE.BrokerDirectory, PAGE_TYPE.HomePage, PAGE_TYPE.BrokerDetailsPage];
    const shouldPageHaveLocationBar = pageType && !pagesWithoutLocationBar.includes(pageType as PAGE_TYPE);
    const isMobileOrTablet = this.props.store.windowWidth < BreakPoints.medium;

    return isLand && isMobileOrTablet && shouldPageHaveLocationBar && !isDiamondListing;
  };

  onPageLoad = () => {
    // remove the listener
    window.removeEventListener('load', this.onPageLoad);

    const { componentHeaderName } = this.props.store;
    this.importMenu();

    // When the header is loaded, it will set this.state.header to a truthy
    // value so that the header shell will no longer render.
    this.importComponent('header', componentHeaderName);
  };

  componentDidMount() {
    // load HeaderShell to avoid content layout shift while actual header is loading
    const { componentHeaderShell, componentHeaderName } = this.props.store;
    if (componentHeaderShell) {
      this.importComponent('headerShell', componentHeaderShell);
    } else {
      this.importComponent('header', componentHeaderName);
    }

    // load the actual header when the page has loaded
    window.addEventListener('load', this.onPageLoad);
  }

  componentDidUpdate(prevProps: Props) {
    const { isMobileDevice, loginModal, resetModal, registerModal, magazineModal, globalUI, searchUI } =
      this.props.store;

    if (prevProps.store.shouldDisplayLoginPopover) {
      setTimeout(
        () => {
          this.props.handler.app.setState({ shouldDisplayLoginPopover: false });
        },
        isMobileDevice ? 5000 : 10000
      );
    }

    if (!this.state.LocationBar && this.showMobileHeaderLocationBar()) {
      this.importLocationBar();
    }
    if (
      (!prevProps.store.magazineModal.isExpanded && magazineModal.isExpanded) ||
      (!prevProps.store.loginModal.isExpanded && loginModal.isExpanded) ||
      (!prevProps.store.resetModal.isExpanded && resetModal.isExpanded) ||
      (!prevProps.store.registerModal.isExpanded && registerModal.isExpanded) ||
      // todo: normalize the following to use ModalHelper (like the above)
      (!prevProps.store.globalUI.contactBrokerModalToggle && globalUI.contactBrokerModalToggle) ||
      (!prevProps.store.globalUI.feedbackModalToggle && globalUI.feedbackModalToggle) ||
      (!prevProps.store.searchUI.isSaveSearchExpanded && searchUI.isSaveSearchExpanded) ||
      (!prevProps.store.globalUI.showFinanceCenterContactModal && globalUI.showFinanceCenterContactModal)
    ) {
      retry(() => import(/* webpackChunkName: "Modals" */ 'components/common/Modals')).then(component =>
        this.setState(() => ({ modals: component.default }))
      );
    }
    this.importMenu();
  }

  render() {
    const { store, handler } = this.props;
    const {
      showMobileMenu,
      closeNavLink,
      modals: Modals,
      menu: Menu,
      header: Header,
      headerShell: HeaderShell
    } = this.state;
    const showSearchBar = this.showMobileHeaderLocationBar();
    const headerProps = {
      store,
      handler,
      Modals,
      Menu,
      showSearchBar,
      closeNavLink,
      showMobileMenu,
      openMobileMenu: this.openMobileMenu,
      closeMobileMenu: this.closeMobileMenu,
      LocationBar: this.state.LocationBar,
      LogoutLocation: this.handleLogoutLocation
    };

    const { routeContext } = store;

    const isHomePage = routeContext.pageType === PAGE_TYPE.HomePage;
    const isPropertyDetailPage = routeContext.pageType === PAGE_TYPE.PropertyDetailPage;
    const isDiamondPD = isPropertyDetailPage && routeContext.isDiamondListing;
    /*display transparent Header on Land.com Home page and Land.com Property Detail Pages with Diamond Upgrades*/
    const displayTransparentHeader = isHomePage || isDiamondPD;

    return (
      <div className={cx(displayTransparentHeader ? styles['transparent-header'] : styles.header)}>
        {Header && <Header {...headerProps} />}
        {
          // only render the headerShell when the actual header hasn't yet loaded
          !this.state.header && HeaderShell && <HeaderShell {...headerProps} />
        }
      </div>
    );
  }
}

export default Header;
