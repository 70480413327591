import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import GlobalStore from 'store/globalStore';
import GlobalHandler from 'handler/globalHandler';
import ShowMoreLessList from 'components/ui/ShowMoreLessList';
import styles from './FooterLAF.scss';
import { getAddListingFullUrl } from 'utils/urlCore';
import { renderLinks } from 'components/layout/Footer/footerLinkHelpers';
import LazyLoad from 'react-lazyload';

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
  footerContext: any;
}

const FooterLAF: React.FunctionComponent<Props> = props => {
  const { store, footerContext } = props;
  const { contact, terms, privacy, caPrivacy, home } = store.routing;
  const { stateLinkList, sortedStateLinkList } = footerContext;

  const aboutUsLinkList = renderLinks(
    {
      Home: { url: home, newTab: false },
      'Contact Us': { url: contact, newTab: false },
      'Terms and Conditions': { url: terms, newTab: false },
      'Privacy Policy': { url: privacy, newTab: true },
      'CA: Do Not Sell My Personal Info': { url: caPrivacy, newTab: true }
    },
    styles
  );

  const resourceLinkList = renderLinks(
    {
      'Add a Property': { url: getAddListingFullUrl(store), newTab: false },
      'Search Land for Sale': { url: '/search/all-land/', newTab: false },
      Advertise: { url: 'https://network.land.com/advertise-brand-advertising/', newTab: true }
    },
    styles
  );

  /*
  These links will be Static per Product, There will be a follow up one day
  make this dynamic.
  */
  const popularLinksList = renderLinks(
    {
      'Ranches for Sale in Texas': { url: '/search/texas/ranches-for-sale/' },
      'Cheap Land': { url: '/search/cheap-land-for-sale/' },
      'Mobile Home Parks for Sale': { url: '/search/present-use-mobile-home-park/' },
      'Colorado Ranches for Sale': { url: '/search/colorado/ranches-for-sale/' },
      'Land Auctions': { url: '/search/auctions/' },
      'Cheap Land for Sale in Texas': { url: '/search/cheap-texas-land-for-sale/' },
      'Dairy Farms for Sale': { url: '/search/present-use-dairy-farm/' },
      'Marinas for Sale': { url: '/search/common-features-marina/' },
      'Waterfront Homes for Sale in Michigan': { url: '/search/michigan/waterfront-for-sale/' },
      'Farms in New York': { url: '/search/new-york/farms-for-sale/' },
      'Hunting Land for Sale': { url: '/search/hunting-land-for-sale/' },
      'Farms for Sale in Missouri': { url: '/search/missouri/farms-for-sale/' },
      'Farms for Sale in Tennessee': { url: '/search/tennessee/farms-for-sale/' },
      'Hunting Land for Sale in NM': { url: '/search/new-mexico/hunting-land-for-sale/' },
      'Acreage for Sale in Texas': { url: '/search/texas-land-for-sale/' },
      'Ranches for Sale in California': { url: '/search/california/ranches-for-sale/' },
      'Hobby Farms for Sale in Minnesota': { url: '/search/minnesota/farms-for-sale/' },
      'Land for Sale in Texas Hill Country': { url: '/search/texas/hill-country-north-region-land-for-sale/' },
      'Horse Property for Sale in Minnesota': { url: '/search/minnesota/horse-property-for-sale/' },
      'RV Parks for Sale in Texas': { url: '/search/texas-land-for-sale/common-features-rv-parking/' },
      'Oregon Ranches for Sale': { url: '/search/oregon/ranches-for-sale/' },
      'Old Farm Houses for Sale': { url: '/search/farms-for-sale/beds-over-1/' }
    },
    styles
  );

  const now = new Date();

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles['state-links']}>
          <div className={styles.heading}>Popular States</div>
          <ul role="menu" className={styles.ul}>
            <ShowMoreLessList
              collapsedLength={10}
              fnGetList={() => stateLinkList}
              fnGetExpandedList={() => sortedStateLinkList}
              moreText="More"
              lessText="Less"
              buttonStyle="footer"
              wrapItemsInUL={true}
            />
          </ul>
        </div>

        <div className={styles['popular-list-container']}>
          <div className={styles.heading}>Popular Searches</div>
          <ul className={styles.ul} role="menu">
            {popularLinksList}
          </ul>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles['page-end']}>
          <div className={styles['page-end-containers']}>
            <div className={styles['footer-logo']}>
              <Link className={styles['logo-link']} role="button" tabIndex={0} to="/" aria-label="Home">
                <span className={cx(styles.logo)} />
              </Link>
            </div>

            <div className={styles['social']}>
              <p className={styles.copy}>
                &copy;{` ${now.getFullYear()} CoStar Group`}
                <br /> All rights reserved
              </p>
              <LazyLoad>
                <span className={styles['social-follow']}>Follow Us</span>
                <a
                  aria-label="Facebook Link"
                  rel="noopener noreferrer"
                  target="_blank"
                  role="button"
                  href="https://www.facebook.com/LandandFarm"
                >
                  <span className={styles.facebook} />
                </a>
                <a
                  aria-label="Twitter Link"
                  rel="noopener noreferrer"
                  target="_blank"
                  role="button"
                  href="https://twitter.com/landandfarm"
                >
                  <span className={styles.twitter} />
                </a>
                <a
                  aria-label="Instagram Link"
                  rel="noopener noreferrer"
                  target="_blank"
                  role="button"
                  href="https://www.instagram.com/landandfarm_official/"
                >
                  <span className={styles.instagram} />
                </a>
              </LazyLoad>
            </div>
          </div>

          <div className={styles['resource-links']}>
            <div className={styles.heading}>Resources</div>
            <ul className={styles.ul} role="menu">
              {resourceLinkList}
            </ul>
          </div>
          <div className={styles['about-links']}>
            <div className={styles.heading}>About Us</div>
            <ul className={styles.ul} role="menu">
              {aboutUsLinkList}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterLAF;
