import { Routes } from './interfaces';

export const commonRoutes: Partial<Routes> = {
  account: '/account/',
  accountSettings: '/account/details/',
  brokerResultsPropertyLink: `/propertylink/:brokerId`,
  caPrivacy: 'https://www.costar.com/about/do-not-sell-my-personal-information-california-residents',
  contact: 'https://network.land.com/contact/',
  dashboard: '/dashboard/',
  errorPage: '/error/',
  financeCenter: '/financing',
  flyer: '/flyer/:propertyId*',
  home: '/',
  landFeed: '/landfeed/docs/',
  landFeedStates: '/landfeed/states/',
  login: '/login',
  passwordReset: '/reset/:resetKey',
  privacy: 'https://www.costar.com/marketplace/privacy-notice',
  savedSearch: '/account/savedsearches/',
  savedProperties: '/account/savedproperties/',
  sitemapBourough: '/sitemap/:countyName-Borough-:stateAbbreviation',
  sitemapCounty: '/sitemap/:countyName-County-:stateAbbreviation',
  sitemapParish: '/sitemap/:countyName-Parish-:stateAbbreviation',
  sitemapState: '/sitemap/:stateName',
  sitemapFeatured: '/sitemap/featured-listing/:stateAbbreviation',
  sitemapNew: '/sitemap/new-listing/:stateAbbreviation'
};
