import { Route, Redirect } from 'react-router';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const PrivateRoute = ({ component: Component, isLoggedIn, store, handler, title, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return isLoggedIn ? (
        <ErrorBoundary history={props.history} handler={handler}>
          <Component {...props} store={store} handler={handler} />
        </ErrorBoundary>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
              title: title
            }
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
