import LoaApp from 'components/App';
import { AdTargeting } from 'store/models/adTargeting';

export default class HandleGoogleAds {
  app: LoaApp;

  constructor(app: LoaApp) {
    this.app = app;
  }

  setGoogleAdTargeting(adTargeting: AdTargeting) {
    const googletag = (window.googletag = window.googletag || ({ cmd: [] } as typeof window.googletag));

    googletag.cmd = googletag.cmd || [];

    googletag.cmd.push(() => {
      googletag.pubads().clearTargeting();
      for (const key in adTargeting) {
        if (Object.prototype.hasOwnProperty.call(adTargeting, key)) {
          // Google requires that every value be a string
          // TODO: Remove this string conversion if we ever return all google ad targeting from the server. We should send the data back as strings.
          const rawValues = adTargeting[key];
          const values =
            Array.isArray(rawValues) && rawValues[0] != null ? adTargeting[key].map(v => v.toString()) : rawValues;
          googletag.pubads().setTargeting(key, values);
        }
        googletag.pubads().enableLazyLoad({
          fetchMarginPercent: 500, // Fetch slots within 5 viewports.
          renderMarginPercent: 200, // Render slots within 2 viewports.
          mobileScaling: 2.0 // Double the above values on mobile.
        });
      }
    });
  }

  destroyAds() {
    const googletag = (window.googletag = window.googletag || ({ cmd: [] } as typeof window.googletag));
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(() => googletag.destroySlots());
  }

  refreshAds() {
    const googletag = (window.googletag = window.googletag || ({ cmd: [] } as typeof window.googletag));
    googletag.cmd = googletag.cmd || [];

    googletag.cmd.push(() => {
      googletag.pubads().refresh();
    });
  }
}
