export enum TRANSACTION_TYPES {
  INVALID = -1,
  SALE = 1,
  AUCTION = 2
}

export enum LISTING_LEVELS {
  FREE = 3,
  STANDARD = 20,
  HOMES = 21,
  PREMIUM = 23,
  SIGNATURE = 30
}

export enum PROPERTY_UPGRADE_LEVELS {
  COURTESY = -10,
  TIER = 0,
  GOLD = 1,
  PLATINUM = 2,
  DIAMOND = 3
}

export enum LISTING_TYPE {
  DIAMOND = 'diamond',
  FREE = 'free',
  GOLD = 'gold',
  PLATINUM = 'platinum',
  SHOWCASE = 'showcase',
  SILVER = 'silver'
}

export enum LogSeverity {
  Information = 0,
  Verbose = 1,
  Debug = 2,
  Warning = 3,
  Error = 4,
  CriticalYellow = 5,
  CriticalOrange = 6,
  CriticalRedOrange = 7,
  CriticalRed = 8
}

export enum DATE_LISTED {
  ANY = 0,
  LAST_DAY = 1,
  LAST_WEEK = 2,
  LAST_MONTH = 3,
  LAST_TWO_MONTHS = 4
}

export enum PRICE_CHANGE_LOOKBACK_TIME {
  NONE = 0,
  LAST_THREE_DAYS = 1,
  LAST_WEEK = 2,
  LAST_MONTH = 3,
  LAST_TWO_MONTHS = 4,
  LAST_THREE_MONTHS = 5,
  ANY = 6
}

export enum PRICE_CHANGE_TYPE {
  NONE = 0,
  REDUCTION = 1,
  CHANGE = 2
}

export enum NOTIFY {
  'No Updates' = 0,
  'Daily Updates' = 1,
  'Instant Updates' = 2
}

export enum MARKET_STATUSES {
  INVALID = -1,
  AVAILABLE = 1,
  UNDER_CONTRACT = 2,
  OFF_MARKET = 3,
  SOLD = 4
}

export enum PHONE_TYPE {
  HOME = 0,
  OFFICE = 1,
  MOBILE = 2
}

export enum SELLER_PHONE_TYPES {
  PHONE_NUMBER_OFFICE,
  PHONE_NUMBER_CELL,
  PHONE_NUMBER_TOLL_FREE
}

export enum LOCATION_TYPES {
  UNSET = -1,
  DEFAULT = 0,
  CITY = 1,
  COUNTY = 2,
  PROPERTYID = 3,
  LAKE = 4,
  REGION = 5,
  STATE = 6,
  STATEABBR = 7,
  ZIP = 8,
  REMAP = 9,
  POLYGON = 10,
  BROKER = 11,
  COORDS = 12
}

export enum LOG_TYPES {
  DETAILS_VIEWED = 1,
  FLYERS_PRINTED = 2,
  SEARCH_RESULTS = 3,
  SEND_FRIEND = 4,
  SHOWCASE = 5,
  PHONE_CLICK = 6,
  EXTERNAL_LINK_CLICK = 7,
  EMAIL_LEAD = 8,
  DOCUMENT_CLICK = 9,
  PHONE_CALL = 10,
  LIKE = 11,
  SELLER_VIEWED = 12,
  SELLER_RESULTS = 13
}

export enum EXTERNAL_LINK_TYPES {
  EXTERNAL_LISTING_URL = 1,
  AUCTION_URL = 2,
  VIRTUAL_TOUR = 3,
  OTHER = 50
}

export enum PROPERTY_TYPES {
  INVALID = -1,
  FARM = 1,
  RANCH = 2,
  RECREATIONAL = 4,
  RESIDENTIAL = 8,
  TIMBERLAND = 16,
  UNDEVELOPED = 32,
  COMMERCIAL = 64,
  HUNTING = 128,
  HORSE = 256,
  LAKEFRONT = 512,
  BEACHFRONT = 1024,
  RIVERFRONT = 2048,
  WATERFRONT = LAKEFRONT | BEACHFRONT | RIVERFRONT,
  FARMSANDRANCHES = FARM | RANCH,
  HOMESITES = 4096,
  HOUSE = 8192
}

export enum LeadSource {
  None = 0,

  // all codes from rewrite that use a combination of the codes below must include Rewrite
  Rewrite = 1,

  // position on the page
  Top = 2,
  Bottom = 4,
  Middle = 8,
  Modal = 16,

  // device/view/breakpoint
  Desktop = 32,
  Tablet = 64,
  Mobile = 128,

  // source page
  PropertyProfile = 256,
  SearchResults = 512,
  BrokerProfile = 1024,
  BrokerDirectory = 2048,
  // On Click Contact For Directions Button
  ForDirections = 4096,
  NativeAdSearch = 8192,
  NativeAdHome = 16384,
  NativeAdLanding = 32768,
  BuyersAgentAd = 65536,
  ShowcaseHome = 131072,
  ShowcaseLanding = 262144,
  FeaturedAdSearch = 524288,
  FeaturedAdHome = 1048576,
  FeaturedAdLanding = 2097152
}

export enum SORT_VALUE_IDS {
  ACRES_DESC = 1,
  ACRES_ASC = 2,
  PRICE_DESC = 3,
  PRICE_ASC = 4,
  NEWEST = 6,
  PRICE_PER_ACRE_DESC = 18,
  PRICE_PER_ACRE_ASC = 19,
  RECENT = 25,
  DEFAULT = 26
}

export enum AmChartMapType {
  National = 0,
  State = 1,
  Regional = 2,
  County = 3,
  None = 4
}

export enum HomePageTypes {
  Home,
  Landing_State,
  Landing_Region,
  Landing_County
}

export enum SITE_IDS {
  LAND = 0,
  LANDANDFARM = 1080,
  LANDWATCH = 1113
}

export const BROKER_DIRECTORY_SITE_URLS = {
  [SITE_IDS.LAND]: '/broker/',
  [SITE_IDS.LANDANDFARM]: '/real-estate-agents/',
  [SITE_IDS.LANDWATCH]: '/find-agent'
};

export enum KEY_CODES {
  LEFT_ARROW = 37,
  RIGHT_ARROW = 39,
  ESCAPE = 27
}

export enum MOBILE_MENU {
  MAIN = 0,
  STATES = 1,
  EXPLORE_MAIN = 2,
  EXPLORE_LISTING = 3,
  EXPLORE_AUCTIONS = 4,
  EXPLORE_DEALS = 5,
  SIGNUP = 6,
  LOGIN = 7,
  FORGOT = 8,
  ACCOUNT = 9
}

export enum ACTIVITIES {
  AVIATION = 1009,
  BEACH = 1010,
  BOATING = 1011,
  CAMPING = 1012,
  CANOEING_KAYAKING = 1013,
  CONSERVATION = 1014,
  FISHING = 1015,
  HORSEBACK_RIDING = 1016,
  HUNTING = 1017,
  OFF_ROADING = 1018,
  RVING = 1019,
  GOLFING = 1052,
  AQUATIC_SPORTING = 1053,
  SKIING = 1065
}

export const TRANSACTION_TYPES_OPTIONS = {
  [TRANSACTION_TYPES.SALE]: 'Sale',
  [TRANSACTION_TYPES.AUCTION]: 'Auction'
};

export const MARKET_STATUSES_OPTIONS = {
  [MARKET_STATUSES.AVAILABLE]: 'Available',
  [MARKET_STATUSES.UNDER_CONTRACT]: 'Under Contract',
  [MARKET_STATUSES.OFF_MARKET]: 'Off Market',
  [MARKET_STATUSES.SOLD]: 'Sold'
};

export const MARKET_STATUSES_CLASSES = {
  [MARKET_STATUSES.AVAILABLE]: 'available',
  [MARKET_STATUSES.UNDER_CONTRACT]: 'pending',
  [MARKET_STATUSES.OFF_MARKET]: 'off-market',
  [MARKET_STATUSES.SOLD]: 'sold'
};

export const LAND_MARKET_STATUSES_CLASSES = {
  [MARKET_STATUSES.AVAILABLE]: 'for-sale',
  [MARKET_STATUSES.UNDER_CONTRACT]: 'under-contract',
  [MARKET_STATUSES.OFF_MARKET]: 'off-market',
  [MARKET_STATUSES.SOLD]: 'sold'
};

export const LAND_MARKET_STATUS_OPTIONS = {
  [MARKET_STATUSES.AVAILABLE]: 'For Sale',
  [MARKET_STATUSES.UNDER_CONTRACT]: 'Under Contract',
  [MARKET_STATUSES.OFF_MARKET]: 'Off Market',
  [MARKET_STATUSES.SOLD]: 'Sold'
};

export const PROPERTY_TYPES_OPTIONS = {
  [PROPERTY_TYPES.FARM]: 'Farm',
  [PROPERTY_TYPES.HORSE]: 'Horse',
  [PROPERTY_TYPES.RANCH]: 'Ranch',
  [PROPERTY_TYPES.RESIDENTIAL]: 'Residential',
  [PROPERTY_TYPES.UNDEVELOPED]: 'Undeveloped',
  [PROPERTY_TYPES.LAKEFRONT]: 'Lakefront',
  [PROPERTY_TYPES.HUNTING]: 'Hunting',
  [PROPERTY_TYPES.COMMERCIAL]: 'Commercial',
  [PROPERTY_TYPES.RECREATIONAL]: 'Recreational',
  [PROPERTY_TYPES.TIMBERLAND]: 'Timberland',
  [PROPERTY_TYPES.RIVERFRONT]: 'Riverfront',
  [PROPERTY_TYPES.WATERFRONT]: 'Waterfront',
  [PROPERTY_TYPES.BEACHFRONT]: 'Beachfront'
};

export const PROPERTY_TYPES_OPTIONS_LW = {
  [PROPERTY_TYPES.BEACHFRONT]: 'Oceanfront',
  [PROPERTY_TYPES.COMMERCIAL]: 'Commercial',
  [PROPERTY_TYPES.FARMSANDRANCHES]: 'Farms and Ranches',
  [PROPERTY_TYPES.HOMESITES]: 'Homesite',
  [PROPERTY_TYPES.HORSE]: 'Horse Property',
  [PROPERTY_TYPES.HOUSE]: 'House',
  [PROPERTY_TYPES.HUNTING]: 'Hunting',
  [PROPERTY_TYPES.LAKEFRONT]: 'Lakefront',
  [PROPERTY_TYPES.RECREATIONAL]: 'Recreational',
  [PROPERTY_TYPES.RIVERFRONT]: 'Riverfront',
  [PROPERTY_TYPES.TIMBERLAND]: 'Timberland',
  [PROPERTY_TYPES.UNDEVELOPED]: 'Undeveloped',
  [PROPERTY_TYPES.WATERFRONT]: 'Waterfront'
};

export const DATE_LISTED_OPTIONS = {
  [DATE_LISTED.ANY]: 'Select',
  [DATE_LISTED.LAST_DAY]: 'Past 24 Hours',
  [DATE_LISTED.LAST_WEEK]: 'Past 7 days',
  [DATE_LISTED.LAST_MONTH]: 'Past 30 days',
  [DATE_LISTED.LAST_TWO_MONTHS]: 'Past 60 days'
};

export const DATE_LISTED_DROPDOWN_OPTIONS = [
  { value: DATE_LISTED.ANY, label: 'Any' },
  { value: DATE_LISTED.LAST_DAY, label: 'Past 24 Hours' },
  { value: DATE_LISTED.LAST_WEEK, label: 'Past 7 days' },
  { value: DATE_LISTED.LAST_MONTH, label: 'Past 30 days' },
  { value: DATE_LISTED.LAST_TWO_MONTHS, label: 'Past 60 days' }
];

export const PRICE_CHANGE_LOOKBACK_TO_TEXT = {
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_THREE_DAYS]: '3 Days',
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_WEEK]: '7 Days',
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_MONTH]: '30 Days',
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_TWO_MONTHS]: '60 Days',
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_THREE_MONTHS]: '90 Days'
};

export const PRICE_CHANGE_LOOKBACK_OPTIONS = {
  [PRICE_CHANGE_LOOKBACK_TIME.NONE]: 'Select',
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_THREE_DAYS]: 'Past 3 days',
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_WEEK]: 'Past 7 days',
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_MONTH]: 'Past 30 days',
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_TWO_MONTHS]: 'Past 60 days',
  [PRICE_CHANGE_LOOKBACK_TIME.LAST_THREE_MONTHS]: 'Past 90 days'
};

export const PRICE_CHANGE_LOOKBACK_DROPDOWN_OPTIONS = [
  { value: PRICE_CHANGE_LOOKBACK_TIME.NONE, label: 'Any' },
  { value: PRICE_CHANGE_LOOKBACK_TIME.LAST_THREE_DAYS, label: 'Past 3 days' },
  { value: PRICE_CHANGE_LOOKBACK_TIME.LAST_WEEK, label: 'Past 7 days' },
  { value: PRICE_CHANGE_LOOKBACK_TIME.LAST_MONTH, label: 'Past 30 days' },
  { value: PRICE_CHANGE_LOOKBACK_TIME.LAST_TWO_MONTHS, label: 'Past 60 days' },
  { value: PRICE_CHANGE_LOOKBACK_TIME.LAST_THREE_MONTHS, label: 'Past 90 days' }
];

export const ACTIVITIES_OPTIONS = {
  [ACTIVITIES.AVIATION]: 'Aviation',
  [ACTIVITIES.BEACH]: 'Beach',
  [ACTIVITIES.BOATING]: 'Boating',
  [ACTIVITIES.CAMPING]: 'Camping',
  [ACTIVITIES.CANOEING_KAYAKING]: 'Canoeing/Kayaking',
  [ACTIVITIES.CONSERVATION]: 'Conservation',
  [ACTIVITIES.FISHING]: 'Fishing',
  [ACTIVITIES.HORSEBACK_RIDING]: 'Horseback Riding',
  [ACTIVITIES.HUNTING]: 'Hunting',
  [ACTIVITIES.OFF_ROADING]: 'Off-roading',
  [ACTIVITIES.RVING]: 'RVing',
  [ACTIVITIES.GOLFING]: 'Golfing',
  [ACTIVITIES.AQUATIC_SPORTING]: 'Aquatic Sporting',
  [ACTIVITIES.SKIING]: 'Skiing'
};

export const ACTIVITIES_DROPDOWN_OPTIONS = [
  { value: null, label: 'Any' },
  { value: ACTIVITIES.AVIATION, label: 'Aviation' },
  { value: ACTIVITIES.BEACH, label: 'Beach' },
  { value: ACTIVITIES.BOATING, label: 'Boating' },
  { value: ACTIVITIES.CAMPING, label: 'Camping' },
  { value: ACTIVITIES.CANOEING_KAYAKING, label: 'Canoeing/Kayaking' },
  { value: ACTIVITIES.CONSERVATION, label: 'Conservation' },
  { value: ACTIVITIES.FISHING, label: 'Fishing' },
  { value: ACTIVITIES.HORSEBACK_RIDING, label: 'Horseback Riding' },
  { value: ACTIVITIES.HUNTING, label: 'Hunting' },
  { value: ACTIVITIES.OFF_ROADING, label: 'Off-roading' },
  { value: ACTIVITIES.RVING, label: 'RVing' },
  { value: ACTIVITIES.GOLFING, label: 'Golfing' },
  { value: ACTIVITIES.AQUATIC_SPORTING, label: 'Aquatic Sporting' },
  { value: ACTIVITIES.SKIING, label: 'Skiing' }
];

export const PHONE_TYPE_LABELS = {
  [SELLER_PHONE_TYPES.PHONE_NUMBER_OFFICE]: 'Office',
  [SELLER_PHONE_TYPES.PHONE_NUMBER_CELL]: 'Mobile',
  [SELLER_PHONE_TYPES.PHONE_NUMBER_TOLL_FREE]: 'Toll Free'
};

export const VIDEO_TYPES = {
  YOUTUBE: 1,
  VIMEO: 3
};

export enum US_STATES {
  National = 0,
  Alabama = 1,
  Alaska = 2,
  Arizona = 4,
  Arkansas = 5,
  California = 6,
  Colorado = 8,
  Connecticut = 9,
  Delaware = 10,
  DistrictofColumbia = 11,
  Florida = 12,
  Georgia = 13,
  Hawaii = 15,
  Idaho = 16,
  Illinois = 17,
  Indiana = 18,
  Iowa = 19,
  Kansas = 20,
  Kentucky = 21,
  Louisiana = 22,
  Maine = 23,
  Maryland = 24,
  Massachusetts = 25,
  Michigan = 26,
  Minnesota = 27,
  Mississippi = 28,
  Missouri = 29,
  Montana = 30,
  Nebraska = 31,
  Nevada = 32,
  NewHampshire = 33,
  NewJersey = 34,
  NewMexico = 35,
  NewYork = 36,
  NorthCarolina = 37,
  NorthDakota = 38,
  Ohio = 39,
  Oklahoma = 40,
  Oregon = 41,
  Pennsylvania = 42,
  RhodeIsland = 44,
  SouthCarolina = 45,
  SouthDakota = 46,
  Tennessee = 47,
  Texas = 48,
  Utah = 49,
  Vermont = 50,
  Virginia = 51,
  Washington = 53,
  WestVirginia = 54,
  Wisconsin = 55,
  Wyoming = 56
}

export const StateNamesById: {
  [value: string]: any;
  [key: number]: string;
} = {
  [US_STATES.Alabama]: 'Alabama',
  [US_STATES.Alaska]: 'Alaska',
  [US_STATES.Arizona]: 'Arizona',
  [US_STATES.Arkansas]: 'Arkansas',
  [US_STATES.California]: 'California',
  [US_STATES.Colorado]: 'Colorado',
  [US_STATES.Connecticut]: 'Connecticut',
  [US_STATES.Delaware]: 'Delaware',
  [US_STATES.Florida]: 'Florida',
  [US_STATES.Georgia]: 'Georgia',
  [US_STATES.Hawaii]: 'Hawaii',
  [US_STATES.Idaho]: 'Idaho',
  [US_STATES.Illinois]: 'Illinois',
  [US_STATES.Indiana]: 'Indiana',
  [US_STATES.Iowa]: 'Iowa',
  [US_STATES.Kansas]: 'Kansas',
  [US_STATES.Kentucky]: 'Kentucky',
  [US_STATES.Louisiana]: 'Louisiana',
  [US_STATES.Maine]: 'Maine',
  [US_STATES.Maryland]: 'Maryland',
  [US_STATES.Massachusetts]: 'Massachusetts',
  [US_STATES.Michigan]: 'Michigan',
  [US_STATES.Minnesota]: 'Minnesota',
  [US_STATES.Mississippi]: 'Mississippi',
  [US_STATES.Missouri]: 'Missouri',
  [US_STATES.Montana]: 'Montana',
  [US_STATES.Nebraska]: 'Nebraska',
  [US_STATES.Nevada]: 'Nevada',
  [US_STATES.NewHampshire]: 'New Hampshire',
  [US_STATES.NewJersey]: 'New Jersey',
  [US_STATES.NewMexico]: 'New Mexico',
  [US_STATES.NewYork]: 'New York',
  [US_STATES.NorthCarolina]: 'North Carolina',
  [US_STATES.NorthDakota]: 'North Dakota',
  [US_STATES.Ohio]: 'Ohio',
  [US_STATES.Oklahoma]: 'Oklahoma',
  [US_STATES.Oregon]: 'Oregon',
  [US_STATES.Pennsylvania]: 'Pennsylvania',
  [US_STATES.RhodeIsland]: 'Rhode Island',
  [US_STATES.SouthCarolina]: 'South Carolina',
  [US_STATES.SouthDakota]: 'South Dakota',
  [US_STATES.Tennessee]: 'Tennessee',
  [US_STATES.Texas]: 'Texas',
  [US_STATES.Utah]: 'Utah',
  [US_STATES.Vermont]: 'Vermont',
  [US_STATES.Virginia]: 'Virginia',
  [US_STATES.Washington]: 'Washington',
  [US_STATES.WestVirginia]: 'West Virginia',
  [US_STATES.Wisconsin]: 'Wisconsin',
  [US_STATES.Wyoming]: 'Wyoming'
};

export enum MEDIA_TYPE_TEXT_KEYS {
  Facebook = 'Facebook',
  X = 'X',
  Instagram = 'Instagram',
  Linkedin = 'Linkedin',
  Video = 'Video'
}

export enum PAGE_TYPE {
  Account = 'Account',
  AreaPage = 'AreaPage',
  BrokerDetailsPage = 'BrokerDetailsPage',
  BrokerDirectory = 'BrokerDirectory',
  ContentPage = 'ContentPage',
  Dashboard = 'Dashboard',
  Flyer = 'Flyer',
  HomePage = 'HomePage',
  Other = 'Other',
  PropertyDetailPage = 'PropertyDetailPage',
  SearchPage = 'SearchPage'
}

export enum ACCOUNT_TYPES {
  STANDARD_BUYER = 1,
  CORPORATE_STANDARD_MASTER = 3,
  CORPORATE_CHILD = 4,
  LISTHUB_SELLER = 8,
  STANDARD_FREE_SELLER = 11,
  UNIVERSAL_STANDARD_SELLER = 12,
  UNIVERSAL_PREMIUM_SELLER = 13,
  HOMES = 20,
  UNIVERSAL_SIGNATURE_SELLER = 40,
  CORPORATE_SIGNATURE_MASTER = 41,
  LEGACY_PREMIUM_SELLER = 50,
  CORPORATE_PREMIUM_MASTER = 51
}

export enum ACCOUNT_TYPE_TIER_LABELS {
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  SIGNATURE = 'SIGNATURE',
  FREE = 'FREE',
  HOMES = 'HOMES'
}

export const ACCOUNT_TYPE_TIER = {
  [ACCOUNT_TYPES.STANDARD_BUYER]: ACCOUNT_TYPE_TIER_LABELS.STANDARD,
  [ACCOUNT_TYPES.CORPORATE_STANDARD_MASTER]: ACCOUNT_TYPE_TIER_LABELS.STANDARD,
  [ACCOUNT_TYPES.CORPORATE_CHILD]: ACCOUNT_TYPE_TIER_LABELS.STANDARD,
  [ACCOUNT_TYPES.LISTHUB_SELLER]: ACCOUNT_TYPE_TIER_LABELS.FREE,
  [ACCOUNT_TYPES.STANDARD_FREE_SELLER]: ACCOUNT_TYPE_TIER_LABELS.FREE,
  [ACCOUNT_TYPES.UNIVERSAL_STANDARD_SELLER]: ACCOUNT_TYPE_TIER_LABELS.STANDARD,
  [ACCOUNT_TYPES.UNIVERSAL_PREMIUM_SELLER]: ACCOUNT_TYPE_TIER_LABELS.PREMIUM,
  [ACCOUNT_TYPES.HOMES]: ACCOUNT_TYPE_TIER_LABELS.HOMES,
  [ACCOUNT_TYPES.UNIVERSAL_SIGNATURE_SELLER]: ACCOUNT_TYPE_TIER_LABELS.SIGNATURE,
  [ACCOUNT_TYPES.CORPORATE_SIGNATURE_MASTER]: ACCOUNT_TYPE_TIER_LABELS.SIGNATURE,
  [ACCOUNT_TYPES.LEGACY_PREMIUM_SELLER]: ACCOUNT_TYPE_TIER_LABELS.PREMIUM,
  [ACCOUNT_TYPES.CORPORATE_PREMIUM_MASTER]: ACCOUNT_TYPE_TIER_LABELS.PREMIUM
};

export enum SELLER_SEARCH_SORT_ORDER_VALUE {
  Default = 0,
  NameAscending = 1,
  NameDescending = 2,
  TotalListingsDescending = 3,
  PriceDescending = 4,
  AcreageDescending = 5
}

export enum SELLER_SEARCH_SORT_URLS {
  Default = '',
  NameAscending = 'sort-az',
  NameDescending = 'sort-za',
  TotalListingsDescending = 'sort-listings-high',
  PriceDescending = 'sort-price-high',
  AcreageDescending = 'sort-acres-high'
}

export enum SELLER_SEARCH_SORT_OPTION_TEXT {
  Default = 'Default',
  NameAscending = 'Name A-Z',
  NameDescending = 'Name Z-A',
  TotalListingsDescending = 'Most Listings',
  PriceDescending = 'Price High to Low',
  AcreageDescending = 'Acreage High to Low'
}

export const SELLER_SEARCH_SORT_OPTION_TEXT_TO_VALUE = {
  [SELLER_SEARCH_SORT_OPTION_TEXT.Default]: SELLER_SEARCH_SORT_ORDER_VALUE.Default,
  [SELLER_SEARCH_SORT_OPTION_TEXT.NameAscending]: SELLER_SEARCH_SORT_ORDER_VALUE.NameAscending,
  [SELLER_SEARCH_SORT_OPTION_TEXT.NameDescending]: SELLER_SEARCH_SORT_ORDER_VALUE.NameDescending,
  [SELLER_SEARCH_SORT_OPTION_TEXT.TotalListingsDescending]: SELLER_SEARCH_SORT_ORDER_VALUE.TotalListingsDescending,
  [SELLER_SEARCH_SORT_OPTION_TEXT.PriceDescending]: SELLER_SEARCH_SORT_ORDER_VALUE.PriceDescending,
  [SELLER_SEARCH_SORT_OPTION_TEXT.AcreageDescending]: SELLER_SEARCH_SORT_ORDER_VALUE.AcreageDescending
};

export const SELLER_SEARCH_URL_TO_VALUE = {
  [SELLER_SEARCH_SORT_URLS.Default]: SELLER_SEARCH_SORT_ORDER_VALUE.Default,
  [SELLER_SEARCH_SORT_URLS.NameAscending]: SELLER_SEARCH_SORT_ORDER_VALUE.NameAscending,
  [SELLER_SEARCH_SORT_URLS.NameDescending]: SELLER_SEARCH_SORT_ORDER_VALUE.NameDescending,
  [SELLER_SEARCH_SORT_URLS.TotalListingsDescending]: SELLER_SEARCH_SORT_ORDER_VALUE.TotalListingsDescending,
  [SELLER_SEARCH_SORT_URLS.PriceDescending]: SELLER_SEARCH_SORT_ORDER_VALUE.PriceDescending,
  [SELLER_SEARCH_SORT_URLS.AcreageDescending]: SELLER_SEARCH_SORT_ORDER_VALUE.AcreageDescending
};

export const SELLER_SEARCH_VALUE_TO_URL = {
  [SELLER_SEARCH_SORT_ORDER_VALUE.Default]: SELLER_SEARCH_SORT_URLS.Default,
  [SELLER_SEARCH_SORT_ORDER_VALUE.NameDescending]: SELLER_SEARCH_SORT_URLS.NameDescending,
  [SELLER_SEARCH_SORT_ORDER_VALUE.NameAscending]: SELLER_SEARCH_SORT_URLS.NameAscending,
  [SELLER_SEARCH_SORT_ORDER_VALUE.TotalListingsDescending]: SELLER_SEARCH_SORT_URLS.TotalListingsDescending,
  [SELLER_SEARCH_SORT_ORDER_VALUE.PriceDescending]: SELLER_SEARCH_SORT_URLS.PriceDescending,
  [SELLER_SEARCH_SORT_ORDER_VALUE.AcreageDescending]: SELLER_SEARCH_SORT_URLS.AcreageDescending
};

export enum ACCOUNT_SUB_TYPES {
  All = 0,
  Broker = 4,
  Agent = 5,
  Auctioneer = 6,
  PropertyOwner = 7,
  LandRetailer = 8
}

export enum ACCOUNT_SUB_TYPES_OPTION_TEXT {
  All = 'Any',
  Broker = 'Real Estate Broker',
  Agent = 'Real Estate Agent',
  Auctioneer = 'Land Auctioneer',
  PropertyOwner = 'Property Owner',
  LandRetailer = 'Land Retailer'
}

export enum ACCOUNT_SUB_TYPES_URL {
  Broker = 'brokers',
  Agent = 'agents',
  Auctioneer = 'auctioneer',
  PropertyOwner = 'owner',
  LandRetailer = 'retailer'
}

export const ACCOUNT_SUB_TYPES_OPTION_TEXT_TO_VALUE = {
  [ACCOUNT_SUB_TYPES_OPTION_TEXT.All]: ACCOUNT_SUB_TYPES.All,
  [ACCOUNT_SUB_TYPES_OPTION_TEXT.Broker]: ACCOUNT_SUB_TYPES.Broker,
  [ACCOUNT_SUB_TYPES_OPTION_TEXT.Agent]: ACCOUNT_SUB_TYPES.Agent,
  [ACCOUNT_SUB_TYPES_OPTION_TEXT.Auctioneer]: ACCOUNT_SUB_TYPES.Auctioneer,
  [ACCOUNT_SUB_TYPES_OPTION_TEXT.PropertyOwner]: ACCOUNT_SUB_TYPES.PropertyOwner,
  [ACCOUNT_SUB_TYPES_OPTION_TEXT.LandRetailer]: ACCOUNT_SUB_TYPES.LandRetailer
};

export const ACCOUNT_SUB_TYPES_URL_TO_VALUE = {
  [ACCOUNT_SUB_TYPES_URL.Broker]: ACCOUNT_SUB_TYPES.Broker,
  [ACCOUNT_SUB_TYPES_URL.Agent]: ACCOUNT_SUB_TYPES.Agent,
  [ACCOUNT_SUB_TYPES_URL.Auctioneer]: ACCOUNT_SUB_TYPES.Auctioneer,
  [ACCOUNT_SUB_TYPES_URL.PropertyOwner]: ACCOUNT_SUB_TYPES.PropertyOwner,
  [ACCOUNT_SUB_TYPES_URL.LandRetailer]: ACCOUNT_SUB_TYPES.LandRetailer
};

export const ACCOUNT_SUB_TYPES_VALUE_TO_URL = {
  [ACCOUNT_SUB_TYPES.Broker]: ACCOUNT_SUB_TYPES_URL.Broker,
  [ACCOUNT_SUB_TYPES.Agent]: ACCOUNT_SUB_TYPES_URL.Agent,
  [ACCOUNT_SUB_TYPES.Auctioneer]: ACCOUNT_SUB_TYPES_URL.Auctioneer,
  [ACCOUNT_SUB_TYPES.PropertyOwner]: ACCOUNT_SUB_TYPES_URL.PropertyOwner,
  [ACCOUNT_SUB_TYPES.LandRetailer]: ACCOUNT_SUB_TYPES_URL.LandRetailer
};
