import React from 'react';
import { Link } from 'react-router-dom';
import { compareHostname } from 'utils/urlCore';

interface StyledLinkProps {
  linkName: string;
  urlInfo: any;
  className: string;
}

const StyledLink: React.FunctionComponent<StyledLinkProps> = props => {
  const { className, urlInfo, linkName } = props;

  return (
    <li className={className} role="presentation">
      {/^https?:\/\//.test(urlInfo.url) ? (
        <a
          onClick={() => {
            const isExternalLink = !compareHostname(window.location.origin, urlInfo.url);
            if (!urlInfo.newTab && !isExternalLink) {
              window.scroll(0, 0);
            }
          }}
          href={urlInfo.url}
          {...(urlInfo.newTab ? { target: '_blank' } : {})}
          className={className}
          role="menuitem"
        >
          {linkName}
        </a>
      ) : (
        <Link onClick={() => window.scroll(0, 0)} to={urlInfo.url} className={className} role="menuitem">
          {linkName}
        </Link>
      )}
    </li>
  );
};

export default StyledLink;
