import GlobalStore from 'store/globalStore';

// Compares two URLs.
// Returns true if the URL hostnames are equal.
export function compareHostname(firstUrl: string, secondUrl: string): boolean {
  const firstHostname = new URL(firstUrl).hostname.toLocaleLowerCase();
  const secondHostname = new URL(secondUrl).hostname.toLocaleLowerCase();

  return firstHostname === secondHostname;
}

// Returns the full-path URL for this site to add a listing.
// Note: LandAndFarm.com is redirected to Land.com.
export function getAddListingFullUrl(store: GlobalStore): string {
  let domain = store.settings.environment.BaseDomain ?? '';

  if (store.siteIdentity.isLAF) {
    domain = domain.toLowerCase().replace('.landandfarm.com', '.land.com');
  }

  return `https://${domain}${store.routing.addListing}`;
}

export function getBrokerUrlSegment(contactName?: string, companyName?: string): string {
  const brokerName = contactName || companyName;
  return brokerName
    ? brokerName
        .replace('/', ' ')
        .replace(/[^a-z0-9 ]+/gi, '')
        .replace(/ /g, '-')
        .replace('--', '-')
        .toLowerCase()
    : 'seller';
}

export function areUrlsEqualIgnoringCaseAndSlashes(url1: string, url2: string) {
  if (!url1 || !url2) {
    return false;
  }

  return (
    decodeURI(url1).toLocaleLowerCase().split('/').join('') === decodeURI(url2).toLocaleLowerCase().split('/').join('')
  );
}
