import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';

export function thousandsmillions(n: number) {
  if (!n) {
    return '0';
  }

  if (n > 999999) {
    return `${(n / 1000000).toFixed(1).replace(/\.0+$/, '')}M`;
  }

  if (n > 999) {
    return `${(n / 1000).toFixed(1).replace(/\.0+$/, '')}K`;
  }

  return n.toString();
}

function priceToTextInternal(price: number, dashString: string, showTextWhenPriceMustBeRequested: boolean) {
  return price > 2
    ? price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }) + dashString
    : price === 2
    ? `Auction${dashString}`
    : showTextWhenPriceMustBeRequested
    ? 'Contact for Price' + dashString
    : '';
}

export function priceToText(price: number, showTextWhenPriceMustBeRequested = false) {
  return priceToTextInternal(price, ' - ', showTextWhenPriceMustBeRequested);
}

export function priceToTextNoDash(price: number, showTextWhenPriceMustBeRequested = false) {
  return priceToTextInternal(price, '', showTextWhenPriceMustBeRequested);
}

export function getAddressText(address: any) {
  return address && address.address1
    ? `${address.address1} ${address.address2}, ${address.city}, ${address.stateAbbreviation} ${address.zip}`
    : `${address.city}, ${address.stateAbbreviation} ${address.zip}`;
}

export function numberWithCommas(num: number) {
  if (!num) {
    return '0';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// Splits a line of text containing spaces into two lines of roughly equal length.
// Returns an array containing the two lines if there is a suitable space on which to split.
// Otherwise, returns an array with the original text unaltered.
export function splitTextIntoTwoLines(text: string): string[] {
  let lines = [text];

  // Find the index of the space character closest to the middle of the text.
  const centerIndex = text.length / 2;
  let index = -1;
  let centerSpace = -1;
  do {
    index = text.indexOf(' ', index + 1);
    if (index >= 0) {
      if (centerSpace < 0 || Math.abs(centerIndex - index) < Math.abs(centerIndex - centerSpace)) {
        centerSpace = index;
      }
    }
  } while (index >= 0 && index <= centerIndex);

  // Split the text into two lines of roughly equal length
  if (centerSpace > 0 && centerSpace < text.length - 1) {
    const startOfLine2 = centerSpace + 1;
    const line1 = text.substring(0, startOfLine2);
    const line2 = text.substring(startOfLine2);
    lines = [line1, line2];
  }

  return lines;
}

export const toLongDate = (dateString: string): string => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const date = new Date(dateString);
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const placardDescriptionType = (
  siteIdentity: SiteIdentity,
  listing: PropertyListing,
  hasSummary: boolean
): string => {
  const { isPlatinum, isDiamond, isShowcase } = listing;
  if (isDiamond) return 'diamond';
  if (isPlatinum) return 'platinum';
  if (isShowcase && (!hasSummary || siteIdentity.isLAF)) return 'showcase';
  return 'base';
};

export const getNumberFormatter = (isCurrency: boolean) => {
  if (isCurrency) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    return (value: number): string => formatter.format(value);
  }

  return (value: number): string => value.toLocaleString();
};
