import { SITE_IDS } from 'utils/enums';
import { SiteSettings } from 'store/commonInterfaces/GenericInterfaces';

const lwSiteSettings: SiteSettings = {
  componentDirectory: 'LandWatch',
  componentHeaderName: 'HeaderLW',
  componentHeaderShell: 'LWHeaderShell',
  manifest: 'manifest_lw.json',
  favIcon: 'favicon_lw.ico',
  appleTouchIcon: 'apple-touch-icon-lw.png',
  msApplicationTileColor: '#fa6401',
  msApplicationConfig: 'browserconfig-lw.xml',
  fontFamily: "'Open Sans', 'Segoe UI', 'Tahoma', 'sans-serif'",
  gtmCode: 'GTM-P56JWN2',
  ogSiteName: 'Landwatch.com',
  searchPlaceholderText: 'Enter a City, County, State, or ID',
  siteUrl: 'https://www.landwatch.com',
  siteFullName: 'LandWatch',
  siteShortName: 'LW',
  siteLocation: 'LandWatch.com'
};

const landSiteSettings: SiteSettings = {
  componentDirectory: 'Land',
  componentHeaderName: 'HeaderLand',
  componentHeaderShell: '',
  manifest: 'manifest.json',
  favIcon: 'favicon.ico',
  appleTouchIcon: 'apple-touch-icon.png',
  msApplicationTileColor: '#006d31',
  msApplicationConfig: 'browserconfig.xml',
  fontFamily: "Roboto, 'Franklin Gothic Medium', Tahoma, 'sans-serif'",
  gtmCode: 'GTM-NXN7XG5',
  ogSiteName: 'Land.com',
  searchPlaceholderText: 'Enter a State, County, City, or ID',
  siteUrl: 'https://www.land.com',
  siteFullName: 'Land.com',
  siteShortName: 'LAND',
  siteLocation: 'Land.com'
};

const lafSiteSettings: SiteSettings = {
  componentDirectory: 'LandAndFarm',
  componentHeaderName: 'HeaderLAF',
  componentHeaderShell: 'LAFHeaderShell',
  manifest: 'manifest_laf.json',
  favIcon: 'favicon_laf.ico',
  appleTouchIcon: 'apple-touch-icon-laf.png',
  msApplicationTileColor: '#173f3f',
  msApplicationConfig: 'browserconfig-laf.xml',
  fontFamily: "'Poppins', 'sans-serif'",
  gtmCode: 'GTM-M62B5SV',
  ogSiteName: 'LandandFarm.com',
  searchPlaceholderText: 'Enter a City, County, State, or ID',
  siteUrl: 'https://www.landandfarm.com',
  siteFullName: 'Land & Farm',
  siteShortName: 'LAF',
  siteLocation: 'LandAndFarm.com'
};

const SITE_SETTINGS = {
  [SITE_IDS.LANDWATCH]: lwSiteSettings,
  [SITE_IDS.LAND]: landSiteSettings,
  [SITE_IDS.LANDANDFARM]: lafSiteSettings
};

export default SITE_SETTINGS;
