import {
  TRANSACTION_TYPES,
  MARKET_STATUSES,
  PROPERTY_TYPES,
  LOCATION_TYPES,
  SORT_VALUE_IDS,
  LISTING_LEVELS,
  DATE_LISTED,
  ACTIVITIES,
  AmChartMapType,
  PRICE_CHANGE_LOOKBACK_TIME,
  PRICE_CHANGE_TYPE,
  PROPERTY_UPGRADE_LEVELS
} from 'utils/enums';
import { AdTargetingPta } from 'store/models/adTargeting';
import SearchCriteria from '../models/searchCriteria';

export interface CriteriaSummary {
  acresMin: number;
  acresMax: number;
  priceMin: number;
  priceMax: number;
  moreFiltersSum: number;
}

export interface SearchCriteriaDescriptionOptions {
  prefix?: string;
  withPageNumber?: boolean;
}

export interface SearchCriteriaOptions {
  acresMax?: number;
  acresMin?: number;
  activities?: ReadonlyArray<ACTIVITIES>;
  bathMax?: number;
  bathMin?: number;
  bedMax?: number;
  bedMin?: number;
  cityIdList?: ReadonlyArray<number>;
  countyIdList?: ReadonlyArray<number>;
  dateListed?: DATE_LISTED;
  hasCustomMap?: boolean;
  hasHouse?: boolean;
  hasVideo?: boolean;
  hasVirtualTour?: boolean;
  inventoryIdList?: ReadonlyArray<number>;
  isNearMeSearch?: boolean;
  isSellerSearchPage?: boolean;
  keywordQuery?: string;
  lakeIdList?: ReadonlyArray<number>;
  latitude?: number;
  readonly location?: LocationData;
  longitude?: number;
  mapEncodedCords?: string;
  mapLowerLeftLatitude?: string;
  mapLowerLeftLongitude?: string;
  mapUpperRightLatitude?: string;
  mapUpperRightLongitude?: string;
  mapZoomLevel?: number;
  marketStatuses?: ReadonlyArray<MARKET_STATUSES>;
  mineralRights?: boolean;
  ownerFinancing?: boolean;
  pageIndex?: number;
  priceMax?: number;
  priceMin?: number;
  priceChangeLookback?: PRICE_CHANGE_LOOKBACK_TIME;
  priceChangeType?: PRICE_CHANGE_TYPE;
  propertyTypes?: ReadonlyArray<PROPERTY_TYPES>;
  radius?: number;
  regionIdList?: ReadonlyArray<number>;
  sortOrderId?: SORT_VALUE_IDS;
  stateId?: number;
  sqftMax?: number;
  sqftMin?: number;
  transactionTypes?: ReadonlyArray<TRANSACTION_TYPES>;
  userSavedProperties?: boolean;
  zip?: string;
}

export class LocationData {
  idList: ReadonlyArray<number>;
  stateId: number;
  locationType?: LOCATION_TYPES;
  type?: LOCATION_TYPES;

  constructor(locationType: LOCATION_TYPES = {} as LOCATION_TYPES, idList: number[] = [], stateId = null) {
    this.idList = idList;
    this.stateId = stateId;
    this.locationType = locationType;
    this.type = locationType;
  }
}

export interface Stats {
  cities: string[];
  location: string;
  market: object;
  prices: object;
  types: string[];
}

export interface SuggestionOptions {
  criteria?: SearchCriteria;
  id: number;
  isRecent?: boolean;
  isNearMeSearch?: boolean;
  location: string;
  searchPath?: string;
  stateId?: number;
  totalCount?: number;
  type: LOCATION_TYPES;
}

export interface PropertyDocument {
  name: string;
  type: string;
  url: string;
}

export interface PropertyImageSources {
  mainPhotoSrcDesktop: string;
  mainPhotoSrcMobile: string;
  mainPhotoSrcTablet: string;
}

export interface OrderedPropertyType {
  id: number;
  count: number;
  name: string;
  url?: string;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: number;
  stateAbbreviation: string;
  stateName: string;
  zip: string;
}

export interface PropertyCounty {
  id: number;
  fips: number;
  name: string;
  lafCountyId: number;
  latitude: number;
  longitude: number;
  stateId: number;
}

export interface PropertyRegion {
  id: number;
  name: string;
}

export interface PropertyCity {
  id: number;
  countyId: number;
  name: string;
  latitude: number;
  longitude: number;
  stateId: number;
  stateAbbreviation: string;
  stateName: string;
}

export interface LinkInfo {
  labelText: string;
  url: string;
}

export interface BreadcrumbNode {
  locationText: string;
  searchPath: string;
}

export interface SellerCarouselListings {
  canonicalUrl: string;
  imageAltTextDisplay: string;
  imageIds: number[];
  id: number;
  price: number;
  status: MARKET_STATUSES;
}

export interface PropertyListing {
  accountId?: number;
  acres?: number;
  acresDisplay?: string;
  address?: string;
  adTargetingCountyId?: number;
  baths?: number;
  bathsDisplay?: string;
  beds?: number;
  bedsDisplay?: string;
  brokerCanonicalUrl?: string;
  brokerCompany?: string;
  brokerName?: string;
  brokerPhone?: string;
  canonicalUrl?: string;
  city?: string;
  companyLogoDocumentId?: number;
  county?: string;
  countyId?: number;
  description?: string;
  encodedBoundaryPoints?: string;
  externalSourceId?: string;
  halfBaths?: number;
  halfBathsDisplay?: string;
  hasCustomMap?: boolean;
  hasVideo?: boolean;
  hasVirtualTour?: boolean;
  homesqft?: number;
  homesqftDisplay?: string;
  id?: number;
  imageAltTextDisplay?: string;
  imageCount?: number;
  imageIds?: number[];
  isDiamond?: boolean;
  isFirstFreeListing?: boolean;
  isGold?: boolean;
  isCourtesy?: boolean;
  isLiked?: boolean;
  isListHubListing?: boolean;
  isPlatinum?: boolean;
  isSearchResult?: boolean;
  isShowcase?: boolean;
  latitude?: number;
  listingLevel?: number;
  listingLevelTitle?: string;
  longitude?: number;
  parentAccountId?: number;
  partnerId?: number;
  portraitDocumentId?: number;
  price?: number;
  priceDisplay?: string;
  priceChangeAmount?: number;
  priceChangePercentage?: number;
  propertyListing?: string;
  propertyTypes?: number;
  regionId?: number;
  relativeUrl?: string;
  salesDate?: string;
  schemaData?: string;
  shortPrice?: string;
  shortPriceChangeAmount?: string;
  siteListingId?: number;
  state?: string;
  stateAbbreviation?: string;
  stateCode?: string;
  stateId?: number;
  stateName?: string;
  status?: number;
  thumbnailDocumentId?: number;
  title?: string;
  types?: string[];
  upgradeLevelId?: PROPERTY_UPGRADE_LEVELS;
  youTubeVideo?: string;
  zip?: string;
}

export interface SavedSearchModel {
  accountId: number;
  active: boolean;
  created: string;
  description: string;
  expirationDate: string;
  id: number;
  isSaved: boolean;
  lastUsed: string;
  name: string;
  notify: number;
  path: string;
  polygonKey: string;
  query: string;
  staticMapImage: string;
}

export interface ImageInfo {
  documentId: number;
  height: number;
  label: string;
  width: number;
}

export interface PropertyMedia {
  lafDocumentTypeId: number;
  mediaId: string;
  mediaThumbnail: string;
  mediaType: string;
  mediaTypeId: number;
  mediaUrl: string;
}

export interface PropertyMediaData {
  matterportVirtualTour: PropertyMedia;
  vimeoVideo: PropertyMedia;
  youtubeVideo: PropertyMedia;
}

export interface Attachments {
  caption: string;
  docsPath: string;
  documentId: number;
  documentTypeId: number;
  // Currently have two different formattings of filename being sent from backend. Need to standardize.
  filename?: string;
  fileName?: string;
  isExternal: boolean;
  uploadedAccountFileId: number;
  uploadedPropertyFileId: number;
  url: string;
}

// more attributes coming from details api
export interface DetailsAPIProperty {
  accountId?: number;
  accountType?: number;
  acres?: number;
  address?: Address;
  adTargetingPta?: AdTargetingPta;
  areaLabel?: string;
  attachments?: Attachments[];
  auctionAddress?: string;
  auctionCity?: string;
  auctionDate?: string;
  auctionId?: number;
  auctionState?: string;
  auctionTitle?: string;
  auctionUrl?: string;
  baths?: number;
  beds?: number;
  breadcrumbSchema?: string;
  canonicalUrl?: string;
  city?: PropertyCity;
  cityUrl?: string;
  county?: PropertyCounty;
  countyUrl?: string;
  description?: string[];
  directions?: string[];
  documents?: PropertyDocument[];
  executiveSummary?: string;
  externalLink?: string;
  externalSourceId?: string;
  formattedDescription?: string;
  geocodeAccuracy?: number;
  halfBaths?: number;
  hasHouse?: boolean;
  hasVirtualTour?: boolean;
  highlight1?: string;
  highlight2?: string;
  highlight3?: string;
  highlight4?: string;
  homesqft?: number;
  imageDocumentIds?: number[];
  imageInfo?: ImageInfo[];
  isDiamond?: boolean;
  isFree?: boolean;
  isGold?: boolean;
  isIrrigated?: boolean;
  isLiked?: boolean;
  isListHubListing?: boolean;
  isPlatinum?: boolean;
  isResidence?: boolean;
  isShowcase?: boolean;
  lastUpdated?: string;
  latitude?: number;
  linkInfos?: LinkInfo[];
  listhubListingStatus?: string;
  partnerPhone?: string;
  listingId?: number;
  listingLevel?: LISTING_LEVELS;
  listingSchema?: string;
  longitude?: number;
  mainPhotoDocumentId?: number;
  marketStatus?: MARKET_STATUSES;
  mlsId?: string;
  parentAccountId?: number;
  partnerId?: number;
  partnerName?: string;
  price?: number;
  pricingPlan?: string;
  productSchema?: string;
  region?: PropertyRegion;
  resAndGeoSchema?: string;
  shortPrice?: string;
  shouldRedirectLDP?: boolean;
  siteListingId?: number;
  smallMapUrl?: string;
  thirdPartyMapUrl?: string;
  title?: string;
  typeIds?: number[];
  types?: string[];
  upgradeLevelId?: PROPERTY_UPGRADE_LEVELS;
  uploadedSellerFiles?: Attachments[];
  videoSchema?: string;
  virtualTour?: string;
  virtualTourLink?: string;
  youtubeVideoId?: string;
}

export interface LinkData {
  description: string;
  link: string;
}

export interface PaginationData {
  canonicalUrl: string;
  locationName: string;
  linkData: LinkData[];
  metaDescription: string;
  nextLink: string;
  pageHeader: string;
  pageHeaderCount: string;
  pageIndex: number;
  pageSubHeader: string;
  pageTitle: string;
  prevLink: string;
  relativeUrl: string;
  searchBarLocationText: string;
  siteName: string;
  siteUrl: string;
}

export interface GptSearchAds {
  bamAdElement: JSX.Element;
  bamAdElementV2?: JSX.Element;
  bannerAds: JSX.Element[];
  brokerAdElement: JSX.Element;
  fcAdElement: JSX.Element;
  farmCreditText?: string;
  preferredAdElement: JSX.Element;
  preferredAdElementV2?: JSX.Element;
  universalFCAd: JSX.Element;
  universalFCAdV2?: JSX.Element;
}

export interface FilterLink {
  count: number;
  displayText: string;
  id: number;
  relativeUrlPath: string;
}

export interface FilterSection {
  filterLinks: FilterLink[];
  section: string;
}

export interface AmChartMapData {
  data: AmChartData[];
  stateId: number;
  stateCode: string;
  type: AmChartMapType;
}

export interface AmChartData {
  data: AmChartDataItem[];
  id: string;
  name: string;
  polygonEnabled: boolean;
  stateId: number;
}

export interface AmChartDataItem {
  countyId: number;
  id: number;
  name: string;
  regionId: number;
  regionName: string;
  polygonEnabled: boolean;
}

export interface CarouselCount {
  count: number;
  label: string;
  link: string;
  subLabel: string;
}

export interface SiteIdentity {
  isLAF?: boolean;
  isLand?: boolean;
  isLW?: boolean;
}
