import utils from 'utils/convenience';
import { US_STATES } from 'utils/enums';

export default class DeviceInfo {
  readonly ipAddress: string = '0.0.0.0';
  readonly city: string = '';
  readonly state: string = '';
  readonly stateId: number = US_STATES.National;
  readonly zip: string = '';
  readonly countryCode: string = '';
  isNearMeSerach? = false;

  constructor(currentState: Partial<DeviceInfo> = {}, updateInfo: Partial<DeviceInfo> = {}) {
    utils.coalesceState(this, currentState, updateInfo);
  }
}
