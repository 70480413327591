import { NOTIFY } from 'utils/enums';
import utils from 'utils/convenience';

export default class SavedSearch {
  id = 0;
  name = '';
  description = '';
  notify = NOTIFY['No Updates'];
  criteria = '';
  path = '';
  active = true;

  constructor(currentState: Partial<SavedSearch>, updateInfo: Partial<SavedSearch>) {
    utils.coalesceState(this, currentState, updateInfo);
  }
}
