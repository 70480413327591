import LoaApp from 'components/App';
import Carousels from 'store/models/carousels';

export default class HandleCarousel {
  app: LoaApp;
  constructor(loaApp: LoaApp) {
    this.app = loaApp;
  }

  closeCarousel = async () => {
    const newCarousels = new Carousels(this.app.state.carousels, {
      displayFullScreenCarousel: false,
      openVideo: false,
      openTour: false,
      currentCarouselSlideIndex: 0
    });
    this.updateState(newCarousels);
  };

  openCarousel = async ({
    isGallery = false,
    isVideo = false,
    isTour = false,
    isPropertyMap = false,
    isLocalMap = false,
    currentImageIndex = 0
  }: {
    isGallery?: boolean;
    isVideo?: boolean;
    isTour?: boolean;
    isPropertyMap?: boolean;
    isLocalMap?: boolean;
    currentImageIndex?: number;
  }) => {
    const newCarousels = new Carousels(this.app.state.carousels, {
      displayFullScreenCarousel: true,
      openGallery: isGallery,
      openVideo: isVideo,
      openTour: isTour,
      isPropertyMap: isPropertyMap,
      isLocalMap: isLocalMap,
      currentCarouselSlideIndex: currentImageIndex
    });
    this.updateState(newCarousels);
  };

  updateState = newCarousels => {
    this.app.setState(() => ({
      carousels: newCarousels
    }));
  };
}
