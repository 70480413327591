import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import GlobalStore from 'store/globalStore';
import GlobalHandler from 'handler/globalHandler';
import cx from 'classnames';
import ShowMoreLessList from 'components/ui/ShowMoreLessList';
import Restricted from 'components/ui/Restricted';
import { MARKET_STATUSES, SITE_IDS } from 'utils/enums';
import styles from './Footer.scss';
import { FooterLinkInfo, renderLinks, renderStateLinks, sortLinks } from './footerLinkHelpers';
import FooterLAF from '../LandAndFarm/FooterLAF';
import FooterLW from '../LandWatch/FooterLW';
import LazyLoad from 'react-lazyload';

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
}

interface State {
  stateLinks: string[];
  propertyTypeLinks: string[];
}

class Footer extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      propertyTypeLinks: this.props.store.footerPropertyTypeNames || [],
      stateLinks: this.props.store.footerStateNames || []
    };
  }

  async componentDidMount() {
    const { isLAF, isLW } = this.props.store.siteIdentity;

    if (isLAF || isLW) {
      if (this.state.stateLinks.length < 1) {
        const statesTask = fetch('/api/popular/statenames/0');
        const states = await statesTask;
        if (states.ok) {
          this.setState({ stateLinks: await states.json() });
        }
      }
    } else if (this.state.propertyTypeLinks.length < 1 || this.state.stateLinks.length < 1) {
      const statesTask = fetch('/api/popular/statenames/0');
      const propertyTypesTask = fetch('/api/popular/PropertyTypeNames/0');
      const states = await statesTask;
      const propertyTypes = await propertyTypesTask;
      if (states.ok && propertyTypes.ok) {
        this.setState({ stateLinks: await states.json(), propertyTypeLinks: await propertyTypes.json() });
      }
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    return (
      prevProps.store.loginInfo.isLoggedIn !== this.props.store.loginInfo.isLoggedIn ||
      prevState.stateLinks.length >= this.state.stateLinks.length
    );
  }

  render() {
    const { store, handler } = this.props;
    const { settings, siteIdentity, propertyDetailPage } = store;
    const { isLAF, isLW, isLand } = siteIdentity;
    const stateLinkList = renderStateLinks(
      this.state.stateLinks,
      this.props.store.routing.baseSearchUrl,
      styles.li,
      cx(styles.link)
    );
    const sortedStateLinkList = renderStateLinks(
      sortLinks(this.state.stateLinks),
      this.props.store.routing.baseSearchUrl,
      styles.li,
      cx(styles.link)
    );

    if (isLAF || isLW) {
      const ctx = {
        stateLinkList: stateLinkList,
        sortedStateLinkList: sortedStateLinkList
      };

      return (
        <Fragment>
          <Restricted to={SITE_IDS.LANDANDFARM}>
            <FooterLAF {...this.props} footerContext={ctx} />
          </Restricted>
          <Restricted to={SITE_IDS.LANDWATCH}>
            <FooterLW {...this.props} footerContext={ctx} />
          </Restricted>
        </Fragment>
      );
    }

    const { addListing, contact, terms, sitemap, privacy, caPrivacy } = store.routing;

    const aboutUs: Record<string, FooterLinkInfo> = {
      'Contact Us': { url: contact, newTab: false },
      'Terms of Use': { url: terms, newTab: false },
      Sitemap: { url: sitemap.link({}), newTab: false },
      'Privacy Policy': { url: privacy, newTab: true },
      'CA: Do Not Sell My Personal Info': { url: caPrivacy, newTab: true }
    };

    const aboutLinks = renderLinks(aboutUs, styles);

    const partnerLinkKey = 'Advertise a Business';
    const partnerLinks =
      <li className={styles.li} key={partnerLinkKey} role="presentation">
        <a href={'https://network.land.com/advertise-brand-advertising/'} className={styles.link} role="menuitem">
          {partnerLinkKey}
        </a>
      </li>

    const renderPropertyTypeLinks = (start = 0, end = this.state.propertyTypeLinks.length) =>
      this.state.propertyTypeLinks.length > 0 &&
      this.state.propertyTypeLinks.slice(start, end).map(pt => {
        return (
          <li className={styles.li} key={pt} role="presentation">
            <Link
              to={store.routing.baseSearchUrl.link({ propertyType: pt })}
              className={cx(styles.link)}
              role="menuitem"
            >
              {`${pt} for Sale`}
            </Link>
          </li>
        );
      });

    const now = new Date();

    const onSearchPage = store.routeContext.pageType === 'SearchPage';
    const addMobileNavStyle = isLand && onSearchPage;

    const onPropertyDetailPage = store.routeContext.pageType === 'PropertyDetailPage';
    const addMobileContactFooterStyle =
      isLand &&
      onPropertyDetailPage &&
      propertyDetailPage?.propertyData &&
      (propertyDetailPage.propertyData.marketStatus == MARKET_STATUSES.AVAILABLE ||
        propertyDetailPage.propertyData.marketStatus == MARKET_STATUSES.UNDER_CONTRACT);

    const onBrokerDetailsPage = store.routeContext.pageType === 'BrokerDetailsPage';
    const addStickyNavStyle = isLand && onBrokerDetailsPage;

    // forces the footer links to be "stacked" (by having each link section take up 50% of the container width) when viewing the map on LOA
    const forceStackedFooter = isLand && onSearchPage && store.displayMapOnSearchPage;

    return (
      <Fragment>
        <footer
          className={cx(
            addMobileNavStyle && styles['mobile-nav-placeholder'],
            addMobileContactFooterStyle && styles['mobile-contact-footer-placeholder'],
            addStickyNavStyle && styles['footer-sticky-nav-placeholder']
          )}
        >
          <div className={styles.footer}>
            <div className={styles.content}>
              <div className={cx({ [styles.links]: !forceStackedFooter }, { [styles['stacked']]: forceStackedFooter })}>
                <div className={styles.heading}>Real Estate For Sale by Type</div>
                <ul role="menu" className={styles.ul}>
                  <ShowMoreLessList
                    buttonStyle="footer"
                    collapsedLength={5}
                    fnGetList={() => renderPropertyTypeLinks()}
                  />
                </ul>
              </div>
              <div className={cx({ [styles.links]: !forceStackedFooter }, { [styles['stacked']]: forceStackedFooter })}>
                <div className={styles.heading}>Real Estate For Sale by State</div>
                <ul role="menu" className={styles.ul}>
                  <ShowMoreLessList
                    buttonStyle="footer"
                    collapsedLength={5}
                    fnGetList={() => stateLinkList}
                    fnGetExpandedList={() => sortedStateLinkList}
                  />
                </ul>
              </div>
              <nav className={cx({ [styles.links]: !forceStackedFooter }, { [styles['stacked']]: forceStackedFooter })}>
                <div className={styles.heading}> About Us </div>
                <ul role="menu" className={styles.ul}>
                  {aboutLinks}
                </ul>
              </nav>
              <div className={cx({ [styles.links]: !forceStackedFooter }, { [styles['stacked']]: forceStackedFooter })}>
                <div className={styles.heading}> For Partners </div>
                <ul role="menu" className={styles.ul}>
                  <li className={styles.li} key="advertiseListing" role="presentation">
                    <div
                      className={styles.link}
                      role="menuitem"
                      tabIndex={0}
                      onClick={() => {
                        handler.handleUserAuthentication().then(handleUserAuthentication => {
                          handleUserAuthentication.sendToListingsLink(store.loginInfo, addListing);
                        });
                      }}
                    >
                      Advertise a Listing
                    </div>
                  </li>
                  {partnerLinks}
                  <li className={styles.li} key="manageListings" role="presentation">
                    <div
                      className={styles.link}
                      role="menuitem"
                      tabIndex={0}
                      onClick={() => {
                        this.props.handler.handleUserAuthentication().then(handleUserAuthentication => {
                          handleUserAuthentication.sendToListingsLink(
                            store.loginInfo,
                            `https://${settings.environment.PropertyControlCenter}`
                          );
                        });
                      }}
                    >
                      Manage Listings
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="be-ix-link-block">{/*Brightedge Link Block Target Div*/}</div>
            <div className={styles.about}>
              <div className={styles.info}>
                <p className={cx(styles.heading, styles.rights)}>&copy;{` ${now.getFullYear()} CoStar Group`}</p>
              </div>
              <LazyLoad>
                <span className={styles.social}>
                  <a
                    aria-label="Instagram Link"
                    rel="noopener noreferrer"
                    target="_blank"
                    role="button"
                    href="https://www.instagram.com/landdotcom/"
                  >
                    <img
                      className={styles['social-icon']}
                      src="/assets/images/instagram.svg"
                      alt="Instagram"
                    />
                  </a>

                  <a
                    aria-label="Facebook Link"
                    rel="noopener noreferrer"
                    target="_blank"
                    role="button"
                    href="https://www.facebook.com/landdotcom"
                  >
                    <img
                      className={styles['social-icon']}
                      src="/assets/images/facebook.svg"
                      alt="Facebook"
                    />
                  </a>

                  <a
                    aria-label="Twitter Link"
                    rel="noopener noreferrer"
                    target="_blank"
                    role="button"
                    href="https://twitter.com/landdotcom"
                  >
                    <img
                      className={styles['social-icon']}
                      src="/assets/images/twitter.svg"
                      alt="Twitter"
                    />
                  </a>

                  <a
                    aria-label="LinkedIn Link"
                    rel="noopener noreferrer"
                    target="_blank"
                    role="button"
                    href="https://www.linkedin.com/company/landnetwork"
                  >
                    <img
                      className={styles['social-icon']}
                      src="/assets/images/linkedin.svg"
                      alt="LinkedIn"
                    />
                  </a>

                  <a
                    aria-label="YouTube Link"
                    rel="noopener noreferrer"
                    target="_blank"
                    role="button"
                    href="https://www.youtube.com/c/Landsofamericapage"
                  >
                    <img
                      className={styles['social-icon']}
                      src="/assets/images/youtube.svg"
                      alt="Youtube"
                    />
                  </a>
                </span>
              </LazyLoad>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }
}

export default Footer;
