import { Helmet } from 'react-helmet-async';
import GlobalStore from 'store/globalStore';
import { Environment } from 'ssr/hydrationData';
import GlobalHandler from 'handler/globalHandler';

interface AppHeadProps {
  handler: GlobalHandler;
  store: GlobalStore;
}

const AppHead = (props: AppHeadProps) => {
  const { store } = props;
  const { appleTouchIcon, favIcon, manifest, msApplicationTileColor, msApplicationConfig, settings, noIndex } = store;
  const { environment } = settings;

  const isLocalEnvironment = (env: Environment): boolean => env.BaseDomain.startsWith('www-local.');
  const isProductionEnvironment = (env: Environment): boolean => env.Environment === 'Production';

  // We unconditionally add noindex and nofollow for the dev and staging environments.
  // We allow local environments to behave just like production for testing purposes.
  // Local environments are not externally accessible, so there's no risk of bots finding them.
  let metaRobots = 'max-image-preview:large, max-snippet:-1, max-video-preview:-1';
  if (!isProductionEnvironment(environment) && !isLocalEnvironment(environment)) {
    metaRobots = metaRobots.concat(', noindex, nofollow');
  } else if (noIndex) {
    metaRobots = metaRobots.concat(', noindex');
  }

  return (
    <Helmet>
      {/* Order matters in the head of the document. Do not change the order the items in the head unless you thoroughly test ALL Lighthouse performance metrics before and after making changes. */}

      <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="anonymous"></link>
      <link rel="manifest" href={`/assets/${manifest}`} />
      <link rel="icon" type="image/png" href={`/assets/${favIcon}`} />
      <link rel="apple-touch-icon" href={`/assets/${appleTouchIcon}`} />
      <meta name="msapplication-TileColor" content={`${msApplicationTileColor}`} />
      <meta name="msapplication-config" content={`/assets/${msApplicationConfig}`} />
      <link rel="preconnect" href="https://www.facebook.com" />
      <link rel="preconnect" href="https://connect.facebook.net" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="dns-prefetch" href="//ajax.googleapis.com" />
      <link rel="dns-prefetch" href="//apis.google.com" />
      <link rel="dns-prefetch" href="//maps.google.com" />
      <link rel="dns-prefetch" href="//secure.quantserve.com" />
      <link rel="dns-prefetch" href="//stats.g.doubleclick.net" />
      <link rel="dns-prefetch" href="//www.google.com" />
      <link rel="dns-prefetch" href="//www.googleadservices.com" />
      <link rel="dns-prefetch" href="//www.googletagmanager.com" />
      <link rel="dns-prefetch" href="//securepubads.g.doubleclick.net" />
      <script async={undefined}>
        {`var googletag = googletag || {};
      googletag.cmd = googletag.cmd || [];`}
      </script>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="google-site-verification" content="a7Bql6NxsGFv7cieDXWI9JyfH-ezuOx-7Mfb3isykWY" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=5, shrink-to-fit=no, viewport-fit=cover"
      />
      {/* Globally set noindex, no follow on Dev and Staging. Production noindex is set via response header in SSRMiddleWare.*/}
      {metaRobots ? <meta name="ROBOTS" content={metaRobots} /> : null}
    </Helmet>
  );
};

export default AppHead;
