import {
  TRANSACTION_TYPES,
  MARKET_STATUSES,
  PROPERTY_TYPES,
  LOCATION_TYPES,
  SORT_VALUE_IDS,
  DATE_LISTED,
  ACTIVITIES,
  PRICE_CHANGE_LOOKBACK_TIME,
  PRICE_CHANGE_TYPE
} from 'utils/enums';
import utils from 'utils/convenience';
import sharedUtils from 'utils/ssrCsrShared';
import { SuggestionOptions, SearchCriteriaOptions, LocationData } from 'store/commonInterfaces/iSearchObjects';

export const DefaultMarketStatuses: ReadonlyArray<MARKET_STATUSES> = [
  MARKET_STATUSES.AVAILABLE,
  MARKET_STATUSES.UNDER_CONTRACT
];

export default class SearchCriteria implements SearchCriteriaOptions {
  acresMax = null;
  acresMin = null;
  activityTypes: ReadonlyArray<ACTIVITIES> = [];
  amenityTypes: ReadonlyArray<number> = [];
  bathMax = null;
  bathMin = null;
  bedMax = null;
  bedMin = null;
  cityIdList: ReadonlyArray<number> = [];
  cityName = '';
  countyIdList: ReadonlyArray<number> = [];
  countyName = '';
  customSearchUrl = '';
  dateListed: DATE_LISTED = DATE_LISTED.ANY;
  hasCustomMap = false;
  hasHouse = null;
  hasVideo = false;
  hasVirtualTour = false;
  inventoryIdList: ReadonlyArray<number> = [];
  isDefaultGeoSearch = false;
  isDefaultStateAndTypeSearch = false;
  isDefaultStateSearch = false;
  isNearMeSearch = false;
  isSellerSearchPage = false;
  keywordQuery = null;
  lakeIdList: ReadonlyArray<number> = [];
  latitude = null;
  longitude = null;
  marketStatuses: ReadonlyArray<MARKET_STATUSES> = DefaultMarketStatuses;
  mineralRights = false;
  ownerFinancing = false;
  pageIndex = 0;
  priceMax = null;
  priceMin = null;
  priceChangeLookback = PRICE_CHANGE_LOOKBACK_TIME.NONE;
  priceChangeType = PRICE_CHANGE_TYPE.NONE;
  propertyTypes: ReadonlyArray<PROPERTY_TYPES> = [];
  radius = 0;
  regionIdList: ReadonlyArray<number> = [];
  regionName = '';
  sortOrderId: SORT_VALUE_IDS = SORT_VALUE_IDS.DEFAULT;
  sqftMax = null;
  sqftMin = null;
  stateAbbreviation = '';
  stateId = 0;
  stateName = '';
  transactionTypes: ReadonlyArray<TRANSACTION_TYPES> = [];
  zip = '';

  // Additional properties
  userSavedProperties = false;
  brokerId = 0;
  mapUpperRightLatitude = '';
  mapUpperRightLongitude = '';
  mapLowerLeftLatitude = '';
  mapLowerLeftLongitude = '';
  mapZoomLevel = 0;
  mapEncodedCords = '';
  tempSkipNavigation: boolean;

  constructor(currentState: Partial<SearchCriteria>, updateInfo: Partial<SearchCriteria>) {
    if (currentState) {
      // These could come in as nulls, Convert to booleans.
      currentState.hasCustomMap = !!currentState.hasCustomMap;
      currentState.hasVideo = !!currentState.hasVideo;
      currentState.hasVirtualTour = !!currentState.hasVirtualTour;
    }

    utils.coalesceState(this, currentState, updateInfo);
  }

  searchName(locations: ReadonlyArray<SuggestionOptions>) {
    //Single Geography = "[Geography Name]" e.g., "Travis County, TX"
    if (locations && locations.length === 1) {
      switch (locations[0].type) {
        case LOCATION_TYPES.PROPERTYID:
          return 'Property ' + locations[0].id;

        default:
          return locations[0].location;
      }
    }

    //Multiple Geographies = "[Geography Name] + X more" e.g., "Travis County, TX + 2 more"
    if (locations && locations.length > 1) {
      switch (locations[0].type) {
        case LOCATION_TYPES.PROPERTYID:
          return 'Property ' + locations[0].id + ' + ' + (locations.length - 1) + ' more';

        default:
          return locations[0].location + ' + ' + (locations.length - 1) + ' more';
      }
    }

    return 'National search';
  }

  get location(): LocationData {
    return sharedUtils.getLocationData(this);
  }
}

export interface LoadedSearchCriteria {
  targetResource: string;
  requestPath: string;
  searchCriteria: SearchCriteria;
  noIndex?: boolean;
}
