declare const module: any;
import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'components/App';
import { getElementByIdOrCreate } from 'utils/dom';
import { HydrationData } from 'ssr/hydrationData';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = getElementByIdOrCreate('root');
let didTimeOut = false;
let timer = null;
const timeout = 2 * 1000;

function app(): JSX.Element {
  const hydrationData = JSON.parse(window.serverState) as HydrationData;

  // To allow hot reloading in development mode we have to rehydrate from the window.serverstate every time a code change is made and saved
  // However this also means if a route is changed, and after that a change is made and saved, the hydration data will be what was loaded from the server initially and weird behavior may happen and you must refresh your browser.
  // But for production we remove window.serverState because it is not needed after it is handed off to App.
  if (process.env.NODE_ENV === 'production') {
    window.serverState = null;
  }

  return (
    <HelmetProvider>
      <BrowserRouter>
        <App renderingOnServer={false} hydrationData={hydrationData} />
      </BrowserRouter>
    </HelmetProvider>
  );
}

const render = () => {
  clearTimeout(timer);
  if (didTimeOut) {
    return;
  }
  // This will fall back to the React 17 version of hydrate.
  // We get many console errors with the new version of hydrate in React 18.
  // Here are some details about upgrading to the React 18 hydrateRoot method
  // https://18.react.dev/reference/react-dom/hydrate#suppressing-unavoidable-hydration-mismatch-errors
  hydrate(app(), rootElement);
};

if (module.hot) {
  module.hot.accept('components/App', () => {
    require('components/App');
    render();
  });
}
// if we fail to SSR, we will probably fail to hydrate. Try to detect this case and fall back to a full client side render

if (!Object.prototype.hasOwnProperty.call(window, '__LOADABLE_LOADED_CHUNKS__')) {
  const root = createRoot(rootElement);
  root.render(app());
} else {
  timer = setTimeout(() => {
    didTimeOut = true;
    hydrate(app(), rootElement);
  }, timeout);

  render();
}
