import LoaApp from 'components/App';
import DeviceInfo from 'store/models/deviceInfo';
import { US_STATES } from 'utils/enums';

export default class HandleDeviceInfo {
  app: LoaApp;

  constructor(app: LoaApp) {
    this.app = app;
  }

  getIpInfo = async () => {
    if (window && window.location.href.indexOf('www-local') > -1){
      this.app.setState({
        deviceInfo: new DeviceInfo(this.app.state.deviceInfo, {
          ipAddress:'localhost',
          city:'Austin',
          state:'TX',
          stateId: US_STATES.Texas,
          zip:'78701',
          countryCode:'US'})
      });
    } else {
      const deviceResponse = await fetch(`/api/device`);
      if (deviceResponse.ok) {
        const json = await deviceResponse.json();
        this.app.setState({
          deviceInfo: new DeviceInfo(this.app.state.deviceInfo, json)
        });
      }
    }
  };
}
