import { LinkBuilder, Routes } from './interfaces';

function sanitizeSegment(segment: string) {
  return segment ? segment.replace(/ /g, '-').toLowerCase() : '';
}

export function lafGetBrokerUrlSegment(contactName, companyName) {
  const nameParts = contactName?.split(',');
  const nameWithoutSuffix = nameParts?.length > 0 ? nameParts[0] : null;
  const names = nameWithoutSuffix?.split(' ') ?? '';

  const brokerName =
    names.length === 0 || companyName === contactName
      ? companyName
      : names.length === 1
      ? names[0]
      : names[0] + '-' + names[names.length - 1];

  return brokerName
    ? brokerName
        .replace('/', '-')
        .replace(/[^a-z0-9 \-]+/gi, '')
        .replace(/ /g, '-')
        .replace('--', '-')
        .toLowerCase()
    : 'seller';
}

export const lafRoutes: Partial<Routes> = {
  /********** Static routes **********/
  // LAF will redirect addListing to Land.com with this path. Note: Land.com URLs intentionally end with a forward slash.
  addListing: '/addlisting/',
  advertisingTerms: '/advertising-terms/',
  terms: '/about/termsandconditions/',
  termsAndConditionsForPhotos: '/terms-and-conditions-for-submitting-photos/',

  /********** Sitemap routes **********/
  // LAF does not have a sitemap intended for users.
  // LAF only has a sitemap for robots at /sitemapindex.html.
  sitemap: undefined,

  /********** Broker member and search routes **********/
  brokerDirectory: { route: '/real-estate-agents/:restofPath*', link: '/real-estate-agents/' },
  // LAF doesn't currently support broker search, but it probably will eventually.
  // We'll leave the routing for now. There's no links to these routes so it's harmless to have them.
  brokerResultsMember: {
    route: `/broker/:restofPath*/`,
    link: ({ contactName, companyName, accountId }) => {
      const brokerUrlSegment = lafGetBrokerUrlSegment(contactName, companyName);
      return `/broker/${brokerUrlSegment}-${accountId}/`;
    }
  },
  brokerResultsMemberListings: {
    route: `/broker/:brokerNameAndId/land/`,
    link: ({ contactName, companyName, accountId }) => {
      const brokerUrlSegment = lafGetBrokerUrlSegment(contactName, companyName);
      return `/broker/${brokerUrlSegment}-${accountId}/land/`;
    }
  },

  /********** Property listing routes **********/
  listing: '/property/:restofPath*',
  property: {
    route: '/property/:restofPath*',
    link: (location: LinkBuilder) => `/property/${location.canonicalLocation}-${location.propertyId}/`
  },

  /********** Property search routes **********/
  baseSearchUrl: {
    link: (location: LinkBuilder) => {
      const landForSale = '-land-for-sale';
      const { propertyType, stateName, cityName, countyName, regionName } = location;

      const propertyTypeSegment = propertyType ? `${sanitizeSegment(propertyType)}-for-sale/` : '';
      let hasForSale = !!propertyTypeSegment;

      const locationInState = stateName ? cityName ?? countyName ?? regionName : '';
      const locationInStateSegment = locationInState
        ? `${sanitizeSegment(locationInState)}${hasForSale ? '' : landForSale}/`
        : '';
      hasForSale = hasForSale || !!locationInStateSegment;

      const stateNameSegment = stateName ? `${sanitizeSegment(stateName)}${hasForSale ? '' : landForSale}/` : '';

      const searchPath = `${stateNameSegment}${locationInStateSegment}${propertyTypeSegment}` || 'all-land/';

      return `/search/${searchPath}`;
    }
  },
  // Since LAF routes are initially based on LW routes, use same "catch-all" route as used in lwRouting.ts.
  searchWithCriteria: ''
};
