import React from 'react';
import GlobalHandler from 'handler/globalHandler';
import GlobalStore from 'store/globalStore';
import PrivateRoute from 'components/common/PrivateRoute';
import AdminRoute from 'components/common/AdminRoute';
import { Route, Switch } from 'react-router-dom';
import { HomePageTypes } from 'utils/enums';
import ErrorBoundary from '../common/ErrorBoundary/ErrorBoundary';

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
  components: {
    Dashboard: React.ComponentType;
    BrokerDirectory: React.ComponentType;
    FarmCreditLeadDoc: React.ComponentType;
    FarmCreditListingDoc: React.ComponentType;
    Flyer: React.ComponentType;
    PropertySearch: React.ComponentType;
    PropertyDetail: React.ComponentType;
    Sitemap: React.ComponentType;
    TermsAndConditionsForPhotos: React.ComponentType;
    TermsOfUse: React.ComponentType;
    AdvertisingTerms: React.ComponentType;
    SavedSearch: React.ComponentType;
    ResetPassword: React.ComponentType;
    AccountSettings: React.ComponentType;
    Login: React.ComponentType;
    LandFeed: React.ComponentType;
    LandFeedStates: React.ComponentType;
    Home: React.ComponentType;
    FinanceCenterPage: React.ComponentType;
    ErrorPage: any;
    AddListing: React.ComponentType;
    SellerDetails: React.ComponentType;
  };
}

interface RoutingObject {
  path: string;
  Component: React.ComponentType;
  title?: string;
  homePageType?: HomePageTypes;
}

export default function Routes(props: Props) {
  const { store, handler, components } = props;
  const { routing, siteIdentity } = store;
  const { isLAF, isLand, isLW } = siteIdentity;
  const {
    AccountSettings,
    AddListing,
    AdvertisingTerms,
    BrokerDirectory,
    Dashboard,
    ErrorPage,
    FarmCreditLeadDoc,
    FarmCreditListingDoc,
    FinanceCenterPage,
    Flyer,
    Home,
    LandFeed,
    LandFeedStates,
    Login,
    SellerDetails,
    PropertySearch,
    PropertyDetail,
    ResetPassword,
    TermsAndConditionsForPhotos,
    TermsOfUse,
    SavedSearch,
    Sitemap
  } = components;

  // Order Matters for the routes to work properly.

  const homeRoute = { path: routing.home, Component: Home, homePageType: HomePageTypes.Home };
  const homeLandingRoutes: RoutingObject[] =
    store.siteIdentity.isLW || store.siteIdentity.isLAF
      ? [homeRoute]
      : [
          { path: routing.landingRegion.route, Component: Home, homePageType: HomePageTypes.Landing_Region },
          { path: routing.landingCounty.route, Component: Home, homePageType: HomePageTypes.Landing_County },
          { path: routing.landingParish.route, Component: Home, homePageType: HomePageTypes.Landing_County },
          { path: routing.landingBorough.route, Component: Home, homePageType: HomePageTypes.Landing_County },
          { path: routing.landingState.route, Component: Home, homePageType: HomePageTypes.Landing_State },
          homeRoute
        ];

  const regularRoutes: RoutingObject[] = [
    { path: routing.brokerResultsPropertyLink, Component: PropertySearch },
    { path: routing.brokerDirectory.route, Component: BrokerDirectory },
    { path: routing.farmCreditLeadDoc, Component: FarmCreditLeadDoc },
    { path: routing.farmCreditListingDoc, Component: FarmCreditListingDoc },
    { path: routing.flyer, Component: Flyer },
    { path: routing.passwordReset, Component: ResetPassword },
    { path: routing.terms, Component: TermsOfUse },
    { path: routing.termsAndConditionsForPhotos, Component: TermsAndConditionsForPhotos },
    { path: routing.addListing, Component: AddListing },
    { path: routing.advertisingTerms, Component: AdvertisingTerms },
    { path: routing.login, Component: Login },
    { path: routing.landFeed, Component: LandFeed },
    { path: routing.landFeedStates, Component: LandFeedStates },
    { path: routing.financeCenter, Component: FinanceCenterPage }
  ].filter(el => !!el.path);

  if (routing.sitemap) {
    regularRoutes.push(
      { path: routing.sitemap.route, Component: Sitemap },
      { path: routing.sitemapBourough, Component: Sitemap },
      { path: routing.sitemapCounty, Component: Sitemap },
      { path: routing.sitemapParish, Component: Sitemap },
      { path: routing.sitemapState, Component: Sitemap },
      { path: routing.sitemapFeatured, Component: Sitemap },
      { path: routing.sitemapNew, Component: Sitemap }
    );
  }

  const propertyDetailRoutes: RoutingObject[] = [
    { path: routing.property.route, Component: PropertyDetail },
    { path: routing.listing, Component: PropertyDetail }
  ];

  const privateRoutes: RoutingObject[] = [
    { path: routing.accountSettings, Component: AccountSettings, title: `${store.siteFullName} / Account Settings` },
    { path: routing.savedSearch, Component: SavedSearch, title: `${store.siteFullName} / Saved Searches` },
    { path: routing.savedProperties, Component: PropertySearch, title: `${store.siteFullName} / Saved Properties` },
    { path: routing.account, Component: AccountSettings, title: `${store.siteFullName} / Account Settings` }
  ];

  const isServerError = store.statusCode !== 200 && store.statusCode !== 403 && store.statusCode !== -1;
  return (
    <Switch>
      {/* This is a catch all route
      It allows us to render an error page for any route given to it
      So the url in the url address bar does not need a identifer indicating and error
      Withing the ErrorPage component we set isError to false and this never gets a chance
      to render again on any client navigation*/}
      {!store.renderingOnServer && store.routerLocationFromServer?.isError && (
        <ErrorPage handler={handler} store={store} code={store.statusCode} />
      )}

      {/* This is a catch all route
        It allows us to render an error page for any route given to it
        Making sure we render an error page on the server even though the
        serversiderparams.url is a valid url*/}

      {store.renderingOnServer && isServerError && (
        <ErrorPage handler={handler} store={store} code={store.statusCode} />
      )}

      {homeLandingRoutes.map(({ path, Component, homePageType }) => (
        <Route
          exact={true}
          key={path}
          path={path}
          render={props => (
            <ErrorBoundary history={props.history} handler={handler}>
              {homePageType !== null ? (
                <Component {...props} homePageType={homePageType} store={store} handler={handler} />
              ) : (
                <Component {...props} store={store} handler={handler} />
              )}
            </ErrorBoundary>
          )}
        />
      ))}

      {propertyDetailRoutes.concat(regularRoutes).map(({ path, Component }) => (
        <Route
          exact={true}
          key={path}
          path={path}
          render={props => (
            <ErrorBoundary history={props.history} handler={handler}>
              <Component {...props} store={store} handler={handler} />
            </ErrorBoundary>
          )}
        />
      ))}

      {/* A PrivateRoute will display the login screen before the defined component if no user is logged in. */}
      {privateRoutes.map(({ path, Component, title }) => (
        <PrivateRoute
          key={path}
          path={path}
          title={title}
          isLoggedIn={store.loginInfo.isLoggedIn}
          component={Component}
          store={store}
          handler={handler}
        />
      ))}

      {/* An AdminRoute will display the login screen before the defined component if no user is logged in. The user has to have isAdmin credentials */}
      <AdminRoute
        path={routing.dashboard}
        component={Dashboard}
        isAdmin={store.loginInfo.isLoggedIn && store.loginInfo.userInfo.isAdmin}
        store={store}
        handler={handler}
      />

      <Route
        path={routing.errorPage}
        render={props => <ErrorPage {...props} handler={handler} code={store.statusCode} />}
      />

      <Route
        data-testid={routing.brokerResultsMemberListings.route}
        key={routing.brokerResultsMemberListings.route}
        path={routing.brokerResultsMemberListings.route}
        exact={false}
        render={props => (
          <ErrorBoundary history={props.history} handler={handler}>
            <PropertySearch {...props} store={store} handler={handler} />
          </ErrorBoundary>
        )}
      />

      {isLand && (
        <Route
          data-testid={routing.brokerResultsMemberPaid.route}
          key={routing.brokerResultsMemberPaid.route}
          path={routing.brokerResultsMemberPaid.route}
          exact={false}
          render={props => {
            const { brokerId } = props.match.params;

            return (
              <ErrorBoundary history={props.history} handler={handler}>
                {isNaN(brokerId) ? (
                  <PropertySearch {...props} store={store} handler={handler} />
                ) : (
                  <SellerDetails {...props} brokerId={brokerId} store={store} handler={handler} />
                )}
              </ErrorBoundary>
            );
          }}
        />
      )}

      {isLand && (
        <Route
          data-testid={routing.brokerResultsMember.route}
          key={routing.brokerResultsMember.route}
          path={routing.brokerResultsMember.route}
          exact={false}
          render={props => (
            <ErrorBoundary history={props.history} handler={handler}>
              <PropertySearch {...props} store={store} handler={handler} />
            </ErrorBoundary>
          )}
        />
      )}

      {(isLAF || isLW) && (
        <Route
          data-testid={routing.brokerResultsMember.route}
          key={routing.brokerResultsMember.route}
          path={routing.brokerResultsMember.route}
          exact={false}
          render={props => {
            const { params } = props.match;
            const brokerId = isLW ? params.brokerId : params.restofPath.split('-').pop();

            return (
              <ErrorBoundary history={props.history} handler={handler}>
                <SellerDetails {...props} brokerId={brokerId} store={store} handler={handler} />
              </ErrorBoundary>
            );
          }}
        />
      )}

      <Route
        data-testid={routing.searchWithCriteria}
        key={routing.searchWithCriteria}
        path={routing.searchWithCriteria}
        render={props => (
          <ErrorBoundary history={props.history} handler={handler}>
            <PropertySearch {...props} store={store} handler={handler} />
          </ErrorBoundary>
        )}
      />

      {/* no match -> error page */}
      <Route render={props => <ErrorPage {...props} handler={handler} store={store} code={404} />} />
    </Switch>
  );
}
