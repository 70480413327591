import StyledLink from '../../common/StyledLink/StyledLink';
import { Link } from 'react-router-dom';
import { RouteLinkType } from 'components/routing/interfaces';

export interface FooterLinkInfo {
  url: string;
  newTab?: boolean;
}

export const sortLinks = (list: string[]): string[] => {
  const listCopy = [...list];
  return listCopy.sort(new Intl.Collator().compare);
};

export const renderLinks = (
  links: Record<string, FooterLinkInfo>,
  styles: { [className: string]: string }
): JSX.Element[] => {
  return Object.entries(links)
    .filter(([key, urlInfo]: [string, any]) => key && urlInfo.url)
    .map(([key, urlInfo]: [string, any]) => (
      <StyledLink key={key} linkName={key} urlInfo={urlInfo} className={styles.link} />
    ));
};

export const renderStateLinks = (
  list: string[],
  baseSearchUrl: RouteLinkType,
  listItemStyle: string,
  linkStyles: string[]
) =>
  list &&
  list.length > 0 &&
  list.map(state => {
    return (
      <li className={listItemStyle} key={state} role="presentation">
        <Link to={baseSearchUrl.link({ stateName: state })} className={linkStyles} role="menuitem">
          {`${state} Land For Sale`}
        </Link>
      </li>
    );
  });
