import utils from 'utils/convenience';

export default class Carousels {
  displayFullScreenCarousel = false;
  openVideo = false;
  openTour = false;
  openGallery = false;
  isLocalMap = false;
  isPropertyMap = false;
  currentCarouselSlideIndex = 0;

  constructor(currentState: Partial<Carousels> = {}, updateInfo: Partial<Carousels> = {}) {
    utils.coalesceState(this, currentState, updateInfo);
  }
}
