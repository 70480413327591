import LoginInfo from 'store/models/loginInfo';
import ModalHelper from 'store/models/modalHelper';
import SearchUI from 'store/models/searchUI';
import SavedSearch from 'store/models/savedSearch';
import GlobalUI from 'store/models/globalUI';
import SearchCriteria from 'store/models/searchCriteria';
import { LOCATION_TYPES, SITE_IDS } from 'utils/enums';
import {
  BrokerSearchPage,
  LandingPage,
  SellerDetailsPage,
  PropertyDetailPage,
  RouteContext,
  RouterLocationFromServer,
  SearchPage,
  Settings,
  Sitemap
} from 'ssr/hydrationData';
import DeviceInfo from './models/deviceInfo';
import BrokerDetails from './models/brokerDetails';
import { SuggestionOptions, SiteIdentity } from './commonInterfaces/iSearchObjects';
import { FeatureFlags } from './commonInterfaces/HydrationData';
import { FinanceCenterFullInfo } from './models/financeCenter';
import SITE_SETTINGS from './commonInterfaces/SiteSettings';
import { CookiesAndLocalStorageAvailability, Url, SiteSettings } from './commonInterfaces/GenericInterfaces';
import { Routing, routesBySiteId } from 'components/routing/routing';
import Carousels from './models/carousels';

export default class GlobalStore {
  carousels: Carousels;
  changePasswordModal: ModalHelper<null>;
  cookiesAndLocalStorageAvailability: CookiesAndLocalStorageAvailability;
  brokerDetails: BrokerDetails;
  brokerModalDetails: BrokerDetails;
  brokerSearchPage: BrokerSearchPage;
  debugLog: string[];
  deviceInfo: DeviceInfo;
  displayMapOnSearchPage: boolean;
  documentDomain: () => string;
  footerPropertyTypeNames: string[];
  footerStateNames: string[];
  financeCenter: FinanceCenterFullInfo;
  componentDirectory: string;
  componentHeaderName: string;
  componentHeaderShell: string;
  manifest: string;
  favIcon: string;
  appleTouchIcon: string;
  msApplicationTileColor: string;
  msApplicationConfig: string;
  fontFamily: string;
  gtmCode: string;
  globalUI: GlobalUI;
  hydrated: boolean;
  imageDomain: string;
  landingPage: LandingPage;
  loading: boolean;
  loginInfo: LoginInfo;
  loginModal: ModalHelper<null>;
  magazineModal: ModalHelper<{ propertyId: number; source: number; formId: string }>;
  sellerDetailsPage: SellerDetailsPage;
  ogSiteName: string;
  // Used as a fallback link for the "back" button on a property details page (shown when scrolled down).
  // Tracks the last URL used on the site, but does NOT get the last search result URL "properly" (use SearchUI field for that)
  prevSearchRouteUrl: string;
  propertyDetailPage: PropertyDetailPage;
  rootElement: HTMLElement;
  routeContext: RouteContext;
  // To only be set server side for error handling or correcting url's
  routerLocationFromServer: RouterLocationFromServer;
  routing: Routing;
  recentSearches: SuggestionOptions[];
  recentBrokerSearch: string;
  registerModal: ModalHelper<null>;
  renderingOnServer: boolean;
  resetModal: ModalHelper<null>;
  savedSearch: SavedSearch;
  schemaOrganization: string;
  searchCriteria: SearchCriteria;
  shouldDisplaySavedSearchPopover: boolean;
  siteIdentity: SiteIdentity;
  mapPositionForWhenLastLocationIsRemoved?: {
    bounds: {
      north: string;
      east: string;
      south: string;
      west: string;
    };
    zoomLevel: number;
  };
  searchPage: SearchPage;
  searchResultsCount: number;
  searchUI: SearchUI;
  noIndex?: boolean; // comes back from api/property/criteria, tells us if the current results page should not be crawlable
  searchPlaceholderText?: string;
  settings: Settings;
  shouldDisplayLoginPopover: boolean;
  siteId: SITE_IDS;
  siteFullName: string;
  siteShortName: string;
  siteLocation: string;
  siteUrl: string;
  sitemap: Sitemap;

  // -1 is our code for a client-side error, unlike 400 and 500 server-side errors
  statusCode: number;
  url: Url;
  featureFlags: FeatureFlags;
  isMobileDevice: boolean;
  isTabletDevice: boolean;

  // Using the window size in javascript to make rendering and styling decisions is a sure-fire to intruduce
  // server-side rendering (SSR) and content layout shift (CLS) issues. We should seriously consider removing
  // these variables from the store so as not to condone their usage. The only safe way to manipulate content
  // based on window size is to use media breakpoints in CSS.
  windowHeight: number;
  windowWidth: number;

  constructor(init: GlobalStore) {
    // set up siteId and siteIdentity first so they can be used below.
    this.siteId = init.settings.siteId || SITE_IDS.LAND;
    this.siteIdentity = {
      isLand: this.siteId === SITE_IDS.LAND,
      isLW: this.siteId === SITE_IDS.LANDWATCH,
      isLAF: this.siteId === SITE_IDS.LANDANDFARM
    };

    this.featureFlags = init.featureFlags || new FeatureFlags({});
    this.isMobileDevice = init.isMobileDevice;
    this.isTabletDevice = init.isTabletDevice;
    this.windowHeight = typeof window !== 'undefined' ? window.innerHeight : null;
    this.windowWidth = typeof window !== 'undefined' ? window.innerWidth : null;

    const { isLand } = this.siteIdentity;
    this.displayMapOnSearchPage = isLand && !this.isMobileDevice;

    this.brokerDetails = new BrokerDetails(init.brokerDetails, {});
    this.brokerModalDetails = new BrokerDetails({}, {});
    this.carousels = new Carousels({});
    this.changePasswordModal = new ModalHelper<null>(null, null);
    this.cookiesAndLocalStorageAvailability = {
      isLocalStorageAvailable: true,
      areCookiesAvailable: true
    };
    this.debugLog = init.debugLog || ['store created'];
    this.deviceInfo = new DeviceInfo(init.deviceInfo, {});
    this.documentDomain = () => this.settings.servers.Image;
    this.footerPropertyTypeNames = init.footerPropertyTypeNames || [];
    this.footerStateNames = init.footerStateNames || [];
    this.globalUI = new GlobalUI(init.globalUI, {});
    this.hydrated = init.hydrated || false;
    this.imageDomain = `${init.settings.servers.Image}`;
    this.landingPage = init.landingPage;
    this.searchPage = init.searchPage;
    this.brokerSearchPage = init.brokerSearchPage;
    this.sellerDetailsPage = init.sellerDetailsPage;
    this.loading = false;
    this.loginInfo = new LoginInfo(null, init.loginInfo);
    this.loginModal = new ModalHelper<null>(null, null);
    this.magazineModal = new ModalHelper<{ propertyId: number; source: number; formId: string }>(
      { data: { propertyId: 0, source: 0, formId: '' } },
      null
    );
    this.noIndex = init.noIndex;
    this.propertyDetailPage = init.propertyDetailPage;
    this.resetModal = new ModalHelper<null>(null, null);
    this.registerModal = new ModalHelper<null>(null, null);
    this.rootElement = null;
    this.routeContext = init.routeContext || {
      urlKey: '',
      isDiamondListing: false,
      locationType: LOCATION_TYPES.DEFAULT,
      locationName: '',
      pageType: '',
      component: '',
      financeCenter: null,
      pageTypeGA: '',
      locationId: -1
    };
    // To only be set server side for error handling or correcting url's
    this.routerLocationFromServer = init.routerLocationFromServer;
    this.routing = routesBySiteId(this.siteId);
    this.savedSearch = new SavedSearch(null, null);
    this.schemaOrganization = init.schemaOrganization;
    this.searchCriteria = new SearchCriteria(init.searchCriteria, {});
    this.searchResultsCount = init.searchResultsCount || 0;
    this.searchUI = new SearchUI(init.searchUI, {});
    this.settings = init.settings;
    this.shouldDisplayLoginPopover = false;

    //Get site-specific settings.
    const siteSettings: SiteSettings = SITE_SETTINGS[this.siteId];
    if (siteSettings) {
      this.componentDirectory = siteSettings?.componentDirectory;
      this.componentHeaderName = siteSettings?.componentHeaderName;
      this.componentHeaderShell = siteSettings?.componentHeaderShell;
      this.manifest = siteSettings?.manifest;
      this.favIcon = siteSettings?.favIcon;
      this.appleTouchIcon = siteSettings?.appleTouchIcon;
      this.msApplicationTileColor = siteSettings?.msApplicationTileColor;
      this.msApplicationConfig = siteSettings?.msApplicationConfig;
      this.fontFamily = siteSettings?.fontFamily;
      this.gtmCode = siteSettings?.gtmCode;
      this.ogSiteName = siteSettings?.ogSiteName;
      this.searchPlaceholderText = siteSettings?.searchPlaceholderText;
      this.siteFullName = siteSettings?.siteFullName;
      this.siteLocation = siteSettings?.siteLocation;
      this.siteShortName = siteSettings?.siteShortName;
      this.siteUrl = siteSettings.siteUrl;
    }

    this.sitemap = init.sitemap;
    this.statusCode = init.statusCode;
    this.recentSearches = [];
    this.renderingOnServer = init.renderingOnServer;
    this.financeCenter = init.financeCenter;

    if (init.searchPage) {
      const { searchResults, searchUI } = init.searchPage;
      this.searchResultsCount = searchResults.totalCount;
      // We'll use this to create an instance of MapPosition on the PropertySearch page.
      this.searchPage.searchResults.mapPosition = searchResults.mapPosition;
      this.searchUI = searchUI;
      this.searchCriteria = new SearchCriteria(init.searchCriteria || {}, {});
    }

    this.hydrated = true;
  }
}
