import utils from 'utils/convenience';
import ListingLogInfo from './listingLogInfo';
import { DetailsAPIProperty } from 'store/commonInterfaces/iSearchObjects';

export default class BrokerDetails {
  // seller details
  accountId: number = null;
  accountSubTypeId: number = null;
  accountType: number = null;
  address1: string = null;
  address2: string = null;
  alcCertified: boolean = null;
  alcAdvancedCertified: boolean = null;
  badgeId: number = null;
  canonicalUrl = '';
  city: string = null;
  companyName: string = null;
  companyState: string = null;
  companyCity: string = null;
  companyZip?: string = null;
  contactName: string = null;
  description: string[] = null;
  isFree: boolean = null;
  isSeller: boolean = null;
  licenseNumber: string = null;
  listingCount: number = null;
  logoId: number = null;
  optInLeadTargeting: any = null;
  parentAccountId?: number = null;
  parentAccountType?: number = null;
  phoneCell: string = null;
  phoneFax: string = null;
  phoneOffice: string = null;
  phoneTollFree: string = null;
  portraitId: number = null;
  portraitImageUpdateDate: boolean = null;
  propertyTitle: string = null;
  sellerListingStats: SellerListingStats = null;
  stateId: number = null;
  stateAbbreviation: string = null;
  totalRows = 0;
  phone: string = null;
  trackingPhoneNumber: string = null;
  url: string = null;
  zip: string = null;
  ga4Eventfn: () => void = null;

  // Other details for Broker Contact form:
  externalSourceId: string = null;
  financeCenter: any;
  listingId: number = null;
  listingLevel: number = null;
  listingUpgradeLevel: string = null;
  siteListingId: number = null;
  partnerId: number = null;
  price: number = null;
  listingLogInfo: ListingLogInfo = null;

  constructor(currentDetails: Partial<BrokerDetails> = {}, updateInfo: Partial<BrokerDetails> = {}) {
    utils.coalesceState(this, currentDetails, updateInfo);
    this.financeCenter = updateInfo.financeCenter || currentDetails.financeCenter || null;
  }

  static getBrokerPhoneNumber(broker: BrokerDetails, isFreeSeller = false, partnerPhone = null): string {
    const { phoneCell, phoneOffice, phoneTollFree, trackingPhoneNumber } = broker;
    // Other then partnerPhone (which only exists on some free listings), the order of these broker phone
    // numbers here should match what's in 'ListingDetailRepository.GetDataForDiamondPlacards'.
    // See this page for more info on phone number display rules:
    // https://wiki.costargroup.com/display/CL/Phone+number+display+rules+across+Marketplaces+platform
    return trackingPhoneNumber || (isFreeSeller && partnerPhone) || phoneCell || phoneOffice || phoneTollFree;
  }

  static getBrokerPhoneNumberForProvidedBySection(broker: BrokerDetails, propertyData: DetailsAPIProperty): string {
    const { phoneCell, phoneOffice, phoneTollFree, trackingPhoneNumber } = broker;

    // See this page for more info on phone number display rules:
    // https://wiki.costargroup.com/display/CL/Phone+number+display+rules+across+Marketplaces+platform
    return propertyData.isListHubListing
      ? phoneOffice
      : trackingPhoneNumber || phoneCell || phoneOffice || phoneTollFree;
  }

  static getFormattedBrokerPhoneNumber(brokerPhoneNumber: string): string {
    return brokerPhoneNumber ? `tel:${brokerPhoneNumber.split(' ').join('')}` : '';
  }
}

interface SellerListingStats {
  allTimePriceMin: number;
  allTimePriceMax: number;
  allTimeAcreageMin: number;
  allTimeAcreageMax: number;
  allTimeListingCount: number;
  lastThreeYearsPriceMin: number;
  lastThreeYearsPriceMax: number;
  lastThreeYearsAcreageMin: number;
  lastThreeYearsAcreageMax: number;
  lastThreeYearsListingCount: number;
  lastFiveYearsPriceMin: number;
  lastFiveYearsPriceMax: number;
  lastFiveYearsAcreageMin: number;
  lastFiveYearsAcreageMax: number;
  lastFiveYearsListingCount: number;
  lastTenYearsPriceMin: number;
  lastTenYearsPriceMax: number;
  lastTenYearsAcreageMin: number;
  lastTenYearsAcreageMax: number;
  lastTenYearsListingCount: number;
}
