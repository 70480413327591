import { SITE_IDS } from 'utils/enums';
import { Routes } from './interfaces';
import { landRoutes } from './landRouting';
import { lwRoutes } from './lwRouting';
import { commonRoutes } from './commonRouting';
import { lafRoutes } from './lafRouting';

export type Routing = Partial<Routes>;
export function routesBySiteId(siteId: SITE_IDS): Routing {
  switch (siteId) {
    case SITE_IDS.LAND:
      return { ...commonRoutes, ...landRoutes };

    case SITE_IDS.LANDWATCH:
      return { ...commonRoutes, ...lwRoutes };

    case SITE_IDS.LANDANDFARM:
      return { ...commonRoutes, ...lafRoutes };

    default:
      return null;
  }
}
