import React, { useContext } from 'react';
import { SITE_IDS } from 'utils/enums';
import SiteIdContext from 'components/SiteIdContext';

/**
 * Restrict a component to a particular site
 * You can now pass a single enum value or an array of enum values using Typescript union types on the props.
 * to={SITE_IDS.LANDWATCH} would only show on Landwatch
 * not={SITE_IDS.LAND} would show up everywhere except for Land.com
 *
 * Some Patterns
 * <Restricted to={[SITE_IDS.LAND, SITE_IDS.LANDWATCH]}> restricted to Land.com and LW </Restricted>
 * <Restricted to={SITE_IDS.LANDWATCH}> restricted to LW </Restricted>
 * <Restricted not={SITE_IDS.LANDWATCH}> not LW </Restricted>
 * <Restricted not={[SITE_IDS.Land, SITE_IDS.LANDWATCH]}> not Land.com or LW </Restricted>
 */

interface Props {
  to?: SITE_IDS | SITE_IDS[];
  not?: SITE_IDS | SITE_IDS[];
  children: React.ReactNode;
}

const Restricted: React.FunctionComponent<Props> = React.memo(function Restricted(props) {
  const siteIdContext = useContext(SiteIdContext);
  const { to, not } = props;
  const { siteId } = siteIdContext;

  try {
    const toIDs = to === undefined || to === null ? [] : Array.isArray(to) ? to : [to];
    const notIDs = not === undefined || not === null ? [] : Array.isArray(not) ? not : [not];

    if (toIDs.length && notIDs.length) {
      console.error(
        "Restricted component: The 'to' and 'not' properties are mutually exclusive. Use one property or the other, but not both."
      );
    } else if (toIDs.includes(siteId) || (notIDs.length && !notIDs.includes(siteId))) {
      return <>{props.children}</>;
    }
  } catch (err) {
    console.error('  :: Err Restricted ::', err);
  }

  return null;
});

export default Restricted;
