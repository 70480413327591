import { Route, Redirect } from 'react-router';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import GlobalHandler from 'handler/globalHandler';
import GlobalStore from 'store/globalStore';

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
  isAdmin: boolean;
  component: any;
  path: string;
}

const AdminRoute = (props: Props) => {
  const { component: Component, isAdmin, store, handler, path } = props;
  return !store.renderingOnServer ? (
    <Route
      path={path}
      render={props => {
        return isAdmin ? (
          <ErrorBoundary history={props.history} handler={handler}>
            <Component {...props} store={store} handler={handler} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location
              }
            }}
          />
        );
      }}
    />
  ) : null;
};

export default AdminRoute;
