import type LoaApp from 'components/App';
import HandleDeviceInfo from './handleDeviceInfo';
import HandleLog from './handleLog';
import HandleCarousel from './handleCarousel';
import type HandleGlobalUI from './handleGlobalUI';
import HandleGoogleAds from './handleGoogleAds';
import type HandleSearchUI from './handleSearchUI';
import HandleUserAuthenticationCore from './handleUserAuthenticationCore';
import { retry } from 'utils/retry';
import type HandleUserAuthentication from './handleUserAuthentication';
import type HandleCookies from './handleCookies';
import type HandlePropertySearch from './handlePropertySearch';
import type HandleBroker from './handleBroker';
import type { History } from 'history';

export default class GlobalHandler {
  app: LoaApp;

  handleCookiesInstance: HandleCookies;

  handleDeviceInfo: HandleDeviceInfo;

  handleLog: HandleLog;

  handlePropertySearchInstance: HandlePropertySearch;

  handleSearchUIInstance: HandleSearchUI;

  handleUserAuthenticationInstance: HandleUserAuthentication;

  handleBrokerInstance: HandleBroker;

  handleCarousel: HandleCarousel;

  handleGlobalUIInstance: HandleGlobalUI;

  handleGoogleAds: HandleGoogleAds;

  handleUserAuthenticationCore: HandleUserAuthenticationCore;

  constructor(loaApp: LoaApp) {
    this.app = loaApp;
    this.handleDeviceInfo = new HandleDeviceInfo(loaApp);
    this.handleLog = new HandleLog(loaApp);
    this.handleCarousel = new HandleCarousel(loaApp);
    this.handleGoogleAds = new HandleGoogleAds(loaApp);
    this.handleUserAuthenticationCore = new HandleUserAuthenticationCore(loaApp);
  }

  async handleGlobalUI(): Promise<HandleGlobalUI> {
    if (this.handleGlobalUIInstance) {
      return Promise.resolve(this.handleGlobalUIInstance);
    }

    return retry(() => import(/* webpackChunkName: "HandleGlobalUI" */ './handleGlobalUI'))
      .then(HandleGlobalUI => (this.handleGlobalUIInstance = new HandleGlobalUI.default(this.app)))
      .then(() => {
        return this.handleGlobalUIInstance;
      });
  }

  async handlePropertySearch(): Promise<HandlePropertySearch> {
    if (this.handlePropertySearchInstance) {
      return Promise.resolve(this.handlePropertySearchInstance);
    }

    return retry(() => import(/* webpackChunkName: "HandlePropertySearch" */ './handlePropertySearch'))
      .then(HandlePropertySearch => (this.handlePropertySearchInstance = new HandlePropertySearch.default(this.app)))
      .then(() => {
        return this.handlePropertySearchInstance;
      });
  }

  async handleCookies(): Promise<HandleCookies> {
    if (this.handleCookiesInstance) {
      return Promise.resolve(this.handleCookiesInstance);
    }

    return retry(() => import(/* webpackChunkName: "HandleCookies" */ './handleCookies'))
      .then(HandleCookies => (this.handleCookiesInstance = new HandleCookies.default(this.app)))
      .then(() => {
        return this.handleCookiesInstance;
      });
  }

  async handleBroker(): Promise<HandleBroker> {
    if (this.handleBrokerInstance) {
      return Promise.resolve(this.handleBrokerInstance);
    }

    return retry(() => import(/* webpackChunkName: "HandleBroker" */ './handleBroker'))
      .then(HandleBroker => (this.handleBrokerInstance = new HandleBroker.default(this.app)))
      .then(() => {
        return this.handleBrokerInstance;
      });
  }

  async handleSearchUI(): Promise<HandleSearchUI> {
    if (this.handleSearchUIInstance) {
      return Promise.resolve(this.handleSearchUIInstance);
    }

    return retry(() => import(/* webpackChunkName: "HandleSearchUI" */ './handleSearchUI'))
      .then(HandleSearchUI => (this.handleSearchUIInstance = new HandleSearchUI.default(this.app)))
      .then(() => {
        return this.handleSearchUIInstance;
      });
  }

  async handleUserAuthentication(): Promise<HandleUserAuthentication> {
    if (this.handleUserAuthenticationInstance) {
      return Promise.resolve(this.handleUserAuthenticationInstance);
    }

    return retry(() => import(/* webpackChunkName: "HandleUserAuthentication" */ './handleUserAuthentication'))
      .then(
        HandleUserAuthentication =>
          (this.handleUserAuthenticationInstance = new HandleUserAuthentication.default(
            this.app,
            this.handleUserAuthenticationCore
          ))
      )
      .then(() => {
        return this.handleUserAuthenticationInstance;
      });
  }

  async handleCheckForLoginViaToken(history: History): Promise<void> {
    return this.handleUserAuthenticationCore.checkLoginRequest(history);
  }
}
