import { PHONE_TYPE } from 'utils/enums';
import utils from 'utils/convenience';

export default class UserInfo {
  id = 0;
  contactName = '';
  confirmPassword = '';
  email = '';
  expires: Date = new Date(1970, 1, 1);
  isActive = false;
  isAdmin = false;
  isBlocked = false;
  isPremium = false;
  isSeller = false;
  isSignature = false;
  isLandsInsight = false;
  canUsePCC = false;
  newsletter = false;
  password = '';
  passwordsMatch = false;
  phone = '';
  phoneType: PHONE_TYPE = PHONE_TYPE.OFFICE;
  type = '';
  mailingAddress1 = '';
  mailingAddress2 = '';
  mailingCity = '';
  mailingState = '';
  mailingZip = '';
  billingAddress1 = '';
  billingAddress2 = '';
  billingCity = '';
  billingState = '';
  billingZip = '';
  listOfLiked: number[] = [];
  sendSavedPropertyConfirmation = true;

  constructor(currentState: Partial<UserInfo>, updateInfo: any) {
    // TODO:TS
    utils.coalesceState(this, currentState, updateInfo);

    // Must be computed after Password and ConfirmPassword have been set above.
    this.passwordsMatch = this.password === this.confirmPassword;
  }
}
