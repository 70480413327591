import utils from 'utils/convenience';
import { SuggestionOptions } from '../commonInterfaces/iSearchObjects';

export default class SearchUI {
  readonly loading: boolean = false;

  readonly autoCompleteValues: ReadonlyArray<SuggestionOptions> = [];

  readonly locationDisplayValueArray: string[] = [];

  readonly isSaveSearchExpanded: boolean = false;

  readonly isSavingSearch: boolean = false;

  constructor(currentState: Partial<SearchUI> = {}, updateInfo: Partial<SearchUI> = {}) {
    utils.coalesceState(this, currentState, updateInfo);
  }
}
