import LoaApp from 'components/App';
import { LOG_TYPES, EXTERNAL_LINK_TYPES } from 'utils/enums';
import utils from 'utils/convenience';
import { GtmTrackingDatalayerObject } from 'store/commonInterfaces/GenericInterfaces';
import { SearchCriteriaOptions } from '../store/commonInterfaces/iSearchObjects';
import ListingLogInfo from 'store/models/listingLogInfo';

export interface LogRequestBody {
  logType: LOG_TYPES;
  listingLogInfos: ListingLogInfo[];
  targetExternalUrl?: string;
  linkTypeValue?: EXTERNAL_LINK_TYPES;
}
export default class HandleLog {
  app: LoaApp;

  constructor(app: LoaApp) {
    this.app = app;
  }

  log(msg: string) {
    const log = this.app.state.debugLog.slice();
    log.push(msg);
    this.app.setState({
      debugLog: log
    });
  }

  dump() {
    return `\n${this.app.state.debugLog.join('\n')}\n\n`;
  }

  logGaPageView(page: string) {
    const { hashedEmail } = this.app.state.loginInfo;
    if (page === '/') {
      page = '/Home';
    }

    utils.pushToDataLayer([
      { event: 'PageView', pageName: page, hashedEmail: hashedEmail } as GtmTrackingDatalayerObject
    ]);
  }

  logStats(requestBody: LogRequestBody) {
    const { siteId, brokerDetails, searchCriteria, deviceInfo } = this.app.state;
    const accountId =
      requestBody.listingLogInfos.length === 1
        ? requestBody.listingLogInfos[0].accountId
        : brokerDetails?.accountId ?? searchCriteria?.brokerId;
    fetch('/api/Logging', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain'
      },
      body: JSON.stringify({
        ...requestBody,
        acctId: accountId,
        ipAddress: deviceInfo.ipAddress,
        siteId
      })
    });
  }

  logLead(listingLogInfo: ListingLogInfo[]) {
    this.logStats({
      logType: LOG_TYPES.EMAIL_LEAD,
      listingLogInfos: listingLogInfo
    });
  }

  logDetailView(listingLogInfo: ListingLogInfo[]) {
    this.logStats({
      logType: LOG_TYPES.DETAILS_VIEWED,
      listingLogInfos: listingLogInfo
    });
  }

  logPhoneClick(listingLogInfo: ListingLogInfo[]) {
    this.logStats({
      logType: LOG_TYPES.PHONE_CLICK,
      listingLogInfos: listingLogInfo
    });
  }

  logAttachmentClick(listingLogInfo: ListingLogInfo[]) {
    this.logStats({
      logType: LOG_TYPES.DOCUMENT_CLICK,
      listingLogInfos: listingLogInfo
    });
  }

  logSendFriend(listingLogInfo: ListingLogInfo[]) {
    this.logStats({
      logType: LOG_TYPES.SEND_FRIEND,
      listingLogInfos: listingLogInfo
    });
  }

  logShowcase(listingLogInfo: ListingLogInfo[]) {
    this.logStats({
      logType: LOG_TYPES.SHOWCASE,
      listingLogInfos: listingLogInfo
    });
  }

  logPrintFlyer(listingLogInfo: ListingLogInfo[]) {
    this.logStats({
      logType: LOG_TYPES.FLYERS_PRINTED,
      listingLogInfos: listingLogInfo
    });
  }

  logExternalLink(listingLogInfo: ListingLogInfo[], externalUrl: string, linkType: EXTERNAL_LINK_TYPES) {
    if (linkType === EXTERNAL_LINK_TYPES.EXTERNAL_LISTING_URL) {
      this.logStats({
        logType: LOG_TYPES.EXTERNAL_LINK_CLICK,
        listingLogInfos: listingLogInfo,
        targetExternalUrl: externalUrl,
        linkTypeValue: linkType
      });
      utils.pushToDataLayer([{ event: 'leadSubmit', leadType: 'external link' } as GtmTrackingDatalayerObject]);
    }
  }

  logAndOpenExternalLink(listingLogInfo: ListingLogInfo[], externalUrl: string, linkType: EXTERNAL_LINK_TYPES) {
    this.logExternalLink(listingLogInfo, externalUrl, linkType);
    window.open(externalUrl, '_blank');
  }

  logPopularSearch(sc: SearchCriteriaOptions) {
    const { siteId } = this.app.state;

    fetch(`/api/Popular/logPopularSearch/${siteId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain'
      },
      body: JSON.stringify(sc)
    });
  }

  logSellerViewed(listingLogInfo: ListingLogInfo[]) {
    this.logStats({
      logType: LOG_TYPES.SELLER_VIEWED,
      listingLogInfos: listingLogInfo
    });
  }

  logSellerResults(listingLogInfo: ListingLogInfo[]) {
    this.logStats({
      logType: LOG_TYPES.SELLER_RESULTS,
      listingLogInfos: listingLogInfo
    });
  }
}
