import UserInfo from './userInfo';
import utils from 'utils/convenience';

export default class LoginInfo {
  isLoggedIn: boolean = null;
  userInfo: UserInfo = new UserInfo({}, {});
  hashedEmail: string = null;

  constructor(currentState: Partial<LoginInfo>, updateInfo: any) {
    // TODO:TS
    utils.coalesceState(this, currentState, updateInfo);
  }
}
