import loadable from '@loadable/component';
import { retry } from 'utils/retry';

// Code Split Routes are Below

const AccountSettings = loadable(() =>
  retry(() => import(/* webpackChunkName: "AccountSettings" */ '../pages/AccountSettings'))
) as React.ComponentType;
const AddListing = loadable(
  () => import(/* webpackChunkName: "AddListing" */ '../pages/AddListing')
) as React.ComponentType;
const AdvertisingTerms = loadable(
  () => import(/* webpackChunkName: "AdvertisingTerms" */ '../pages/StaticLegalPages/AdvertisingTerms')
) as React.ComponentType;
const BrokerDirectory = loadable(() =>
  retry(() => import(/* webpackChunkName: "BrokerDirectory" */ '../pages/BrokerDirectory'))
) as React.ComponentType;
const BrokerDetails = loadable(() =>
  retry(() => import(/* webpackChunkName: "BrokerDetails" */ 'components/common/Broker/BrokerDetails'))
) as React.ComponentType;
const BrokerInactive = loadable(() =>
  retry(() => import(/* webpackChunkName: "BrokerInactive" */ 'components/common/Broker/BrokerInactive'))
) as React.ComponentType;
const BrokerLandStarAwards = loadable(() =>
  retry(() => import(/* webpackChunkName: "BrokerLandStarAwards" */ 'components/common/Broker/BrokerLandStarAwards'))
) as React.ComponentType;
const Dashboard = loadable(() =>
  retry(() => import(/* webpackChunkName: "Dashboard" */ '../pages/Dashboard'))
) as React.ComponentType;
const ErrorPage = loadable(() =>
  retry(() => import(/* webpackChunkName: "ErrorPage" */ '../pages/ErrorPage'))
) as React.ComponentType;
const FarmCreditLeadDoc = loadable(() =>
  retry(() => import(/* webpackChunkName: "FarmCreditLeadDoc" */ '../pages/FarmCreditLeadDoc'))
) as React.ComponentType;
const FarmCreditListingDoc = loadable(() =>
  retry(() => import(/* webpackChunkName: "FarmCreditListingDoc" */ '../pages/FarmCreditListingDoc'))
) as React.ComponentType;
const FinanceCenterPage = loadable(() =>
  retry(() => import(/* webpackChunkName: "FinanceCenter" */ '../pages/FinanceCenter'))
) as React.ComponentType;
const Flyer = loadable(() =>
  retry(() => import(/* webpackChunkName: "Flyer" */ '../pages/Flyer'))
) as React.ComponentType;
const Home = loadable(() => retry(() => import(/* webpackChunkName: "Home" */ '../pages/Home'))) as React.ComponentType;
const HomeFavorites = loadable(() =>
  retry(() => import(/* webpackChunkName: "HomeFavorites" */ '../pages/Home/Favorites/HomeFavorites'))
) as React.ComponentType;
const ResetPassword = loadable(() =>
  retry(() => import(/* webpackChunkName: "ResetPassword" */ '../pages/ResetPassword'))
) as React.ComponentType;
const LandFeed = loadable(() =>
  retry(() => import(/* webpackChunkName: "LandFeed" */ '../pages/LandFeed'))
) as React.ComponentType;
const LandFeedStates = loadable(() =>
  retry(() => import(/* webpackChunkName: "LandFeedStates" */ '../pages/LandFeedStates'))
) as React.ComponentType;
const Login = loadable(() =>
  retry(() => import(/* webpackChunkName: "Login" */ '../pages/Login'))
) as React.ComponentType;
const SellerDetails = loadable(() =>
  retry(() => import(/* webpackChunkName: "SellerDetails" */ '../pages/SellerDetails'))
) as React.ComponentType;
const PropertySearch = loadable(() =>
  retry(() => import(/* webpackChunkName: "PropertySearch" */ '../pages/PropertySearch'))
) as React.ComponentType;
const PropertyDetail = loadable(() =>
  retry(() => import(/* webpackChunkName: "PropertyDetail" */ '../pages/PropertyDetail'))
) as React.ComponentType;
const PropertyDetailLand = loadable(() =>
  retry(() => import(/* webpackChunkName: "PropertyDetailLand" */ '../pages/PropertyDetail/PropertyDetailLand'))
) as React.ComponentType;
const PropertyDetailLwLaf = loadable(() =>
  retry(() => import(/* webpackChunkName: "PropertyDetailLwLaf" */ '../pages/PropertyDetail/PropertyDetailLwLaf'))
) as React.ComponentType;
const SavedSearch = loadable(() =>
  retry(() => import(/* webpackChunkName: "SavedSearch" */ '../pages/SavedSearch'))
) as React.ComponentType;
const Sitemap = loadable(() =>
  retry(() => import(/* webpackChunkName: "Sitemap" */ '../pages/Sitemap/Sitemap'))
) as React.ComponentType;
const TermsOfUse = loadable(() =>
  retry(() => import(/* webpackChunkName: "TermsOfUse" */ '../pages/StaticLegalPages/TermsOfUse'))
) as React.ComponentType;
const TermsAndConditionsForPhotos = loadable(
  () =>
    import(
      /* webpackChunkName: "TermsAndConditionsForPhotos" */ '../pages/StaticLegalPages/TermsAndConditionsForPhotos'
    )
) as React.ComponentType;

// Must Match WebpackChunkName Exactly
export interface StaticAssets {
  AccountSettings: [string, string];
  AddListing: [string, string];
  AdvertisingTerms: [string, string];
  BrokerDirectory: [string, string];
  BrokerDetails: [string, string];
  BrokerInactive: [string, string];
  BrokerLandStarAwards: [string, string];
  ErrorPage: [string, string];
  FarmCreditLeadDoc: [string, string];
  FarmCreditListingDoc: [string, string];
  FinanceCenter: [string, string];
  Flyer: [string, string];
  HeaderLAF: [string, string];
  HeaderLand: [string, string];
  HeaderLW: [string, string];
  Home: [string, string];
  HomeFavorites: [string, string];
  LandFeed: [string, string];
  LandFeedStates: [string, string];
  Login: [string, string];
  MoreFiltersLwLaf: [string, string];
  PropertyDetail: [string, string];
  PropertyDetailLand: [string, string];
  PropertyDetailLwLaf: [string, string];
  PropertySearch: [string, string];
  PropertyTypeCarousel: [string, string];
  PropertyTypeGrid: [string, string];
  ResetPassword: [string, string];
  SavedSearch: [string, string];
  SellerDetails: [string, string];
  Sitemap: [string, string];
  TermsAndConditionsForPhotos: [string, string];
  TermsOfUse: [string, string];
  main: [string, string];
}

export default {
  AccountSettings,
  AddListing,
  AdvertisingTerms,
  BrokerDirectory,
  BrokerDetails,
  BrokerInactive,
  BrokerLandStarAwards,
  Dashboard,
  ErrorPage,
  FarmCreditLeadDoc,
  FarmCreditListingDoc,
  FinanceCenterPage,
  Flyer,
  Home,
  HomeFavorites,
  LandFeed,
  LandFeedStates,
  Login,
  SellerDetails,
  PropertySearch,
  PropertyDetail,
  PropertyDetailLand,
  PropertyDetailLwLaf,
  ResetPassword,
  SavedSearch,
  Sitemap,
  TermsAndConditionsForPhotos,
  TermsOfUse
};
