import { Component, Fragment } from 'react';
import GlobalHandler from 'handler/globalHandler';
import GlobalStore from 'store/globalStore';
import 'components/layout/Layout.scss';
import utils from 'utils/convenience';
import { setTimeout } from 'timers';
import Header from 'components/layout/Header';
import { CookiesAndLocalStorageAvailability } from 'store/commonInterfaces/GenericInterfaces';
import { EventDatalayerObject } from 'store/commonInterfaces/GenericInterfaces';
import { HydrationData } from 'ssr/hydrationData';
import RouteComponents from './routing/RouteComponents';
import Routes from './routing/Routes';
import AppHead from './AppHead';
import styles from './App.scss';
import SiteIdContext from './SiteIdContext';
import 'styles/themes.scss';
import { listHubApiJs } from 'utils/listHubApi';
import { BreakPoints } from 'utils/variables';
import Footer from './layout/Footer';
import { retry } from 'utils/retry';

declare global {
  interface Window {
    dataLayer: any;
    serverState: string;
  }
}

interface Props {
  renderingOnServer: boolean;
  // HydrationData is a string when we render from Client.tsx and it comes from Window.
  // HydrationData is an object coming from Server.tsx.
  hydrationData: string | HydrationData;
}

class LoaApp extends Component<Props, GlobalStore> {
  handler: GlobalHandler;
  gptScriptInjected = false;
  webVitalsActivated = false;
  currentLocation = '';
  shouldCheckLoggedInStatus = true;

  constructor(props) {
    super(props);
    this.state = new GlobalStore({ ...props.hydrationData, renderingOnServer: props.renderingOnServer });
    this.handler = new GlobalHandler(this);

    this.rehydrateHydrationData(props.hydrationData);
  }

  rehydrateHydrationData = (hydrationData: HydrationData) => {
    if (typeof window === 'undefined') {
      // If we're rendering server-side then this isn't necessary.
      return;
    }

    // If we're on the client then get the hydration data from the DOM.
    hydrationData.schemaOrganization = document.getElementById('schemaOrganization')?.innerHTML;
    hydrationData.schemaProduct = document.getElementById('productSchema')?.innerHTML;
    hydrationData.schemaResidence = document.getElementById('resAndGeoSchema')?.innerHTML;

    if (hydrationData.searchPage) {
      const { searchPage } = hydrationData;
      const { searchResults } = searchPage;

      searchPage.collectionPageSchema = document.getElementById('collectionPageSchema')?.innerHTML;
      searchPage.faqSchema = document.getElementById('faqSchema')?.innerHTML;
      searchPage.breadCrumbSchema = document.getElementById('breadCrumbSchema')?.innerHTML;

      searchResults.propertyResults.forEach(property => {
        property.schemaData = document.getElementById(`listingSchema_${property.id}`)?.innerHTML;
        property.description = document.getElementById(`placard-description-${property.id}`)?.innerHTML;
      });

      searchResults.similarProperties.forEach(property => {
        property.schemaData = document.getElementById(`listingSchema_${property.id}`)?.innerHTML;
        property.description = document.getElementById(`placard-description-${property.id}`)?.innerHTML;
      });
    } else if (hydrationData.landingPage) {
      const { landingPage } = hydrationData;

      landingPage.schemaOrganization = document.getElementById('schemaOrganization')?.innerHTML;
      landingPage.breadcrumbSchema = document.getElementById('breadcrumbSchema')?.innerHTML;

      landingPage.promotedProperties.forEach(property => {
        property.schemaData = document.getElementById(`listingSchema_${property.id}`)?.innerHTML;
        property.description = document.getElementById(`placard-description-${property.id}`)?.innerHTML;
      });
    } else if (hydrationData.propertyDetailPage) {
      const { propertyDetailPage } = hydrationData;
      const { propertyData } = propertyDetailPage;
      const { otherListings, soldListings } = propertyDetailPage;

      propertyData.videoSchema = document.getElementById('videoSchema')?.innerHTML;
      propertyData.listingSchema = document.getElementById('listingSchema')?.innerHTML;
      propertyData.productSchema = document.getElementById('productSchema')?.innerHTML;
      propertyData.resAndGeoSchema = document.getElementById('resAndGeoSchema')?.innerHTML;
      propertyData.breadcrumbSchema = document.getElementById('breadcrumbSchema')?.innerHTML;

      otherListings.forEach(property => {
        property.schemaData = document.getElementById(`listingSchema_${property.id}`)?.innerHTML;
        property.description = document.getElementById(`placard-description-${property.id}`)?.innerHTML;
      });

      soldListings.forEach(property => {
        property.schemaData = document.getElementById(`listingSchema_${property.id}`)?.innerHTML;
        property.description = document.getElementById(`placard-description-${property.id}`)?.innerHTML;
      });
    }
  };

  updateDimensions = () => {
    if (utils.getBreakpoint(this.state.windowWidth) !== utils.getBreakpoint(window.innerWidth)) {
      this.setState(() => ({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      }));
    }
  };

  componentDidUpdate() {
    this.handleRouteChange();
  }

  componentDidMount() {
    this.setState(() => ({ rootElement: document.getElementById('root'), ...this.windowEvents() }));
    this.getIpInfo();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('load', this.injectScripts);
    window.removeEventListener('scroll', this.injectGPTScript);
    window.removeEventListener('mousedown', this.checkLoggedInStatus);
    window.removeEventListener('touchstart', this.checkLoggedInStatus);
  }

  getGtmCode = () => {
    const store = this.state;
    return store.gtmCode;
  };

  getIpInfo = () => {
    if (this.state.deviceInfo.ipAddress === '0.0.0.0') {
      this.handler.handleDeviceInfo.getIpInfo();
    }
  };

  injectScriptTagBySrc = (src: string) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  injectScriptTagByText = (text: string) => {
    const container = document.createElement('script');
    container.text = text;
    document.getElementsByTagName('head')[0].appendChild(container);
  };

  shouldDelayLoadAd = () => {
    const { isLAF, isLW } = this.state.siteIdentity;
    const { isMobileDevice, routeContext, windowWidth } = this.state;
    const { pageType } = routeContext;

    // Using isMobileDevice here for SSR purposes.
    const isMobileWidth = isMobileDevice || utils.getBreakpoint(windowWidth) <= BreakPoints.small;

    if (isLW || !isMobileWidth) {
      // We don't delay load gpt.js at all on LW and any site in desktop mode.
      return false;
    }

    if (isLAF) {
      if (isMobileWidth && pageType === 'HomePage') {
        // The LAF homepage on mobile is the only page we can delay-load gpt.js.
        return true;
      }

      // Every other LAF page gets gpt.js loaded right away.
      return false;
    }

    // Everything else is Land and mobile which do get delay-loaded.
    return true;
  };

  injectGPTScript = () => {
    if (!this.gptScriptInjected) {
      this.injectScriptTagBySrc('https://securepubads.g.doubleclick.net/tag/js/gpt.js');
      this.gptScriptInjected = true;
      window.removeEventListener('scroll', this.injectGPTScript);
    }
  };

  injectScripts = () => {
    const { siteIdentity } = this.state;
    const { isLand, isLW } = siteIdentity;
    const delayTimeInMillis = 2250;

    setTimeout(() => {
      const autopilotScriptSrc = isLand
        ? '//cdn.bc0a.com/autopilot/f00000000298327/autopilot_sdk.js'
        : isLW
          ? '//cdn.bc0a.com/autopilot/f00000000298330/autopilot_sdk.js'
          : '';
      this.injectScriptTagByText(`
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),
            dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j, f);
      })(window,document,'script','dataLayer','${this.getGtmCode()}');`);

      if (this.state.featureFlags.injectMazeTrackingScript) {
        this.injectScriptTagByText(`
          (function (m, a, z, e) {
            var s, t;
            try {
              t = m.sessionStorage.getItem('maze-us');
            } catch (err) {}

            if (!t) {
              t = new Date().getTime();
              try {
                m.sessionStorage.setItem('maze-us', t);
              } catch (err) {}
            }

            s = a.createElement('script');
            s.src = z + '?apiKey=' + e;
            s.async = true;
            a.getElementsByTagName('head')[0].appendChild(s);
            m.mazeUniversalSnippetApiKey = e;
          })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', 'd8525713-f258-4416-869c-f9b748eb747f');
        `);
      }

      autopilotScriptSrc && this.injectScriptTagBySrc(autopilotScriptSrc);
      this.injectScriptTagByText(listHubApiJs(this.state.settings.environment.Environment));

      retry(() => import(/* webpackChunkName: "web-vitals" */ 'web-vitals'))
        .then(({ getCLS, getFCP, getLCP }) => {
          if (!this.webVitalsActivated) {
            this.webVitalsActivated = true;
            const sendToGoogleAnalytics = function ({ name, delta, id }) {
              const webVitalEvent: EventDatalayerObject[] = [];

              webVitalEvent.push({
                event: 'webVitalEvent',
                eventCategory: 'Web Vitals',
                eventAction: name,
                eventLabel: id,
                eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta).toString()
              });

              utils.pushToDataLayer(webVitalEvent);
            };

            getFCP(sendToGoogleAnalytics);
            getCLS(sendToGoogleAnalytics);
            getLCP(sendToGoogleAnalytics);
          }
        });
    }, delayTimeInMillis)


    const gptDelayInMillis = this.shouldDelayLoadAd() ? delayTimeInMillis : 0;
    setTimeout(this.injectGPTScript, gptDelayInMillis);
  };

  windowEvents = () => {
    const cookiesAndLocalStorageAvailability = this.getCookiesAndLocalStorageAvailability();
    let recentSearches = [];
    let recentBrokerSearch: string;
    let shouldDisplaySavedSearchPopover = false;
    if (cookiesAndLocalStorageAvailability.isLocalStorageAvailable) {
      recentSearches = utils.getRecentSearchesFromCookies();
      recentBrokerSearch = utils.getRecentBrokerSearchFromCookies();
      shouldDisplaySavedSearchPopover = utils.getDisplaySavedSearchPopoverFromCookies(
        this.state.siteIdentity,
        window.innerWidth
      );
    }
    window.addEventListener('resize', this.updateDimensions);

    // Inject the scripts after the page has loaded.
    window.addEventListener('load', this.injectScripts);

    // If the user starts scrolling the page then automatically inject gpt.js so the ads show up ASAP.
    window.addEventListener('scroll', this.injectGPTScript);

    // Check if the user is logged in every time they click or touch the page. However it will only check
    // a maximum of once every 2 minutes.
    window.addEventListener('mousedown', this.checkLoggedInStatus);
    window.addEventListener('touchstart', this.checkLoggedInStatus);

    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      recentSearches,
      recentBrokerSearch,
      shouldDisplaySavedSearchPopover,
      cookiesAndLocalStorageAvailability
    };
  };

  getCookiesAndLocalStorageAvailability(): CookiesAndLocalStorageAvailability {
    let isLocalStorageAvailable;
    let areCookiesAvailable;
    // check local storage
    try {
      const storage = window.localStorage;
      const testVar = '_test_';
      storage.setItem(testVar, testVar);
      storage.removeItem(testVar);
      isLocalStorageAvailable = true;
    } catch {
      isLocalStorageAvailable = false;
    }
    //check cookie
    try {
      document.cookie = 'testcookie';
      areCookiesAvailable = document.cookie.indexOf('testcookie') !== -1;
      if (areCookiesAvailable) {
        document.cookie = 'testcookie=; expires=Thu, 01 Jan 1970 00:00:01 UTC; path=/;';
      }
    } catch {
      areCookiesAvailable = false;
    }
    return { areCookiesAvailable, isLocalStorageAvailable };
  }

  handleRouteChange = () => {
    if (this.currentLocation !== window.location.pathname) {
      this.currentLocation = window.location.pathname;
      this.checkLoggedInStatus();
    }
  };

  checkLoggedInStatus = () => {
    if (window.location.search.includes('logintoken=')) {
      // If the url has a login token, we do not want to try and authenticate the user here, since there is code in
      // other components that is supposed to authenticate the user based on that and remove the token from the url.
      this.scheduleNextCheck();
    } else if (this.shouldCheckLoggedInStatus) {
      // Only check whether the user is logged in if it has been longer
      // than this.state.settings.environment.loginStatusInterval
      this.handler.handleUserAuthenticationCore.isLoggedIn().then(this.scheduleNextCheck, this.scheduleNextCheck);
    }
  };

  scheduleNextCheck = () => {
    this.shouldCheckLoggedInStatus = false;
    const timeout = parseInt(this.state.settings.environment.loginStatusInterval, 10);

    if (timeout > 0) {
      setTimeout(() => {
        this.shouldCheckLoggedInStatus = true;
      }, timeout);
    }
  };

  render() {
    const store = this.state;
    const { isLand } = store.siteIdentity;
    const { handler } = this;
    /* if we are viewing map on searchpage for LOA/LAND, hide Footer */
    const shouldShowFooter = !(isLand && store.displayMapOnSearchPage && store.routeContext.pageType === 'SearchPage');

    return (
      <Fragment>
        <SiteIdContext.Provider value={{ siteId: store.siteId }}>
          <AppHead store={store} handler={handler}></AppHead>
          <Header store={store} handler={handler} />
          <noscript>
            <div className={styles.noscript}>
              <strong>Javascript must be enabled.</strong>
              <iframe
                title="GTM"
                src={`https://www.googletagmanager.com/ns.html?id=${this.getGtmCode()}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              ></iframe>
            </div>
          </noscript>
          <div className={styles.main}>
            {/* This is necesssary for adding font dynamically
            Included are our fonts per site and fallback fonts which are the closest in size and styling to our designated fonts
            There will still be a FOUT on first page load but the fallback fonts should make it minimal.
            Browser Caching of the fonts should prevent the FOUT from reappearing on subsequent page loads. */}

            <style
              dangerouslySetInnerHTML={{
                __html: `body { font-family: ${store.fontFamily};}`
              }}
            />
            <Routes components={RouteComponents} handler={handler} store={store} />
          </div>
          {shouldShowFooter && <Footer handler={handler} store={store} />}
        </SiteIdContext.Provider>
      </Fragment>
    );
  }
}

export default LoaApp;
