import { thousandsmillions } from 'utils/format';
import { BreakPoints } from 'utils/variables';
import { isEqual } from 'utils/lodash';
import { DatalayerObject } from 'store/commonInterfaces/GenericInterfaces';
import { SiteIdentity, SuggestionOptions } from 'store/commonInterfaces/iSearchObjects';
import { FinanceCenterBasicInfo } from 'store/models/financeCenter';
import {
  ACCOUNT_SUB_TYPES,
  ACCOUNT_SUB_TYPES_URL_TO_VALUE,
  LogSeverity,
  MARKET_STATUSES,
  SELLER_SEARCH_SORT_ORDER_VALUE,
  SELLER_SEARCH_URL_TO_VALUE,
  SITE_IDS
} from './enums';
import LoaApp from 'components/App';
import { FeatureFlags } from 'store/commonInterfaces/HydrationData';
import SearchCriteria from 'store/models/searchCriteria';

// All the non-exported functions needed by the main bundle at startup will be exported 
// as default in order to not be duplicated in other bundles. All other functions will be 
// exported as named exports in order to promote tree-shaking and allow them to be bundled 
// where they are needed.

function getLoginPopoverDisplayedFromCookies(): string {
  if (typeof window === 'undefined' || !window.localStorage) {
    return null;
  }

  return JSON.parse(window.localStorage.getItem('loginPopoverDisplayed'));
}

function getDisplaySavedSearchPopoverFromCookies(siteIdentity: SiteIdentity, windowWidth: number): boolean {
  if (typeof window === 'undefined' || !window.localStorage) {
    return true;
  }

  // Only showing the popover at certain widths
  const breakpoint = siteIdentity.isLand ? BreakPoints.medium : BreakPoints.listViewMapView;

  const savedSearchPopoverDisplayedDate: string = JSON.parse(
    window.localStorage.getItem('savedSearchPopoverDisplayedDate')
  );

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  return (
    windowWidth >= breakpoint &&
    (savedSearchPopoverDisplayedDate !== null ? new Date(savedSearchPopoverDisplayedDate) < thirtyDaysAgo : true)
  );
}

function getRecentSearchesFromCookies(): SuggestionOptions[] {
  if (typeof window === 'undefined' || !window.localStorage) {
    return [];
  }

  const recentSearches: SuggestionOptions[] = JSON.parse(window.localStorage.getItem('recentSearches'));

  if (recentSearches) {
    recentSearches.forEach(e => {
      const sc = new SearchCriteria(e.criteria, {});
      e.criteria = sc;
    });
  }

  return recentSearches;
}

function getRecentBrokerSearchFromCookies(): string {
  if (typeof window === 'undefined' || !window.localStorage) {
    return null;
  }

  const recentSearch: string[] = JSON.parse(window.localStorage.getItem('recentBrokerSearch'));

  return recentSearch && recentSearch.length > 0 ? recentSearch[0] : null;
}

// Coalesce fields in current with fields in update and place results in target.
// Used in constructors of objects that store state in GlobalStore.
// This will only work if there are default values set for all keys in target.
function coalesceState<T>(target: T, current: T, update: T) {
  current = current || ({} as T);
  update = update || ({} as T);
  for (const fieldname of Object.keys(target)) {
    if (typeof target[fieldname] === 'object' && target[fieldname]) {
      if (Array.isArray(target[fieldname])) {
        if (Object.prototype.hasOwnProperty.call(update, fieldname) && update[fieldname] !== null) {
          target[fieldname] = update[fieldname].slice();
        } else if (Object.prototype.hasOwnProperty.call(current, fieldname) && current[fieldname] !== null) {
          target[fieldname] = current[fieldname].slice();
        }
      } else {
        this.coalesceState(target[fieldname], current[fieldname], update[fieldname]);
      }
    } else if (Object.prototype.hasOwnProperty.call(update, fieldname)) {
      target[fieldname] = update[fieldname];
    } else if (Object.prototype.hasOwnProperty.call(current, fieldname)) {
      target[fieldname] = current[fieldname];
    }
  }
}

export function splitStringAtSpace(text, limit) {
  // If we didn't hit the limit, return.
  if (!text || text.length <= limit) {
    return [text, ''];
  }

  // If we did, clip the string
  const validText = text.slice(0, limit + 1);
  const excessText = text.slice(limit + 1);

  // If we conveniently clipped after a word (the excess starts with
  // a space), then we return what we have.
  if (excessText.charAt(0) === ' ') {
    return [validText, excessText];
  }

  let sliceIndex = validText.lastIndexOf(' ');

  // If there is no space then split using the length of the validText
  // as the index at which to slice the text.
  if (sliceIndex === -1) {
    sliceIndex = validText.length - 1;
  }

  // We have clipped a word or after a space (excess doesn't start
  // with a space). Find the last space in the valid text and clip
  // at that position.
  const firstTextSelection = validText.slice(0, sliceIndex);
  const secondTextSelection = text.slice(sliceIndex);

  return [firstTextSelection, secondTextSelection];
}

export function renderValueFn(min, max, type) {
  const newMin = min === null ? 0 : min;
  const newMax = max === null ? 0 : max;
  type = type || '';

  if (type === 'ac') {
    return newMax > 0
      ? `${thousandsmillions(newMin)} ${type} - ${thousandsmillions(newMax)} ${type}`
      : `${thousandsmillions(newMin)} ${type} +`;
  }

  return newMax > 0
    ? `${type}${thousandsmillions(newMin)} - ${type}${thousandsmillions(newMax)}`
    : `${type}${thousandsmillions(newMin)} +`;
}

function getBreakpoint(windowWidth: number): BreakPoints {
  // returns all available breakpoints. Some parts of the application will handle multiple of these breakpoints with the same behavior.
  if (windowWidth < BreakPoints.small) {
    return BreakPoints.smallest;
  } else if (windowWidth >= BreakPoints.small && windowWidth < BreakPoints.medium) {
    return BreakPoints.small;
  } else if (windowWidth >= BreakPoints.medium && windowWidth < BreakPoints.listViewMapView) {
    return BreakPoints.medium;
  } else if (windowWidth >= BreakPoints.listViewMapView && windowWidth < BreakPoints.tablet) {
    // this should only be used by search results page, do not change behavior in other routes between ListViewMapView and Medium
    return BreakPoints.listViewMapView;
  } else if (windowWidth >= BreakPoints.tablet && windowWidth < BreakPoints.mediumLarge) {
    return BreakPoints.tablet;
  } else if (windowWidth >= BreakPoints.mediumLarge && windowWidth < BreakPoints.largerThanIpadPro) {
    return BreakPoints.mediumLarge;
  } else if (windowWidth >= BreakPoints.largerThanIpadPro && windowWidth < BreakPoints.large) {
    return BreakPoints.largerThanIpadPro;
  } else if (windowWidth >= BreakPoints.large) {
    return BreakPoints.large;
  }

  return undefined;
}

function pushToDataLayer(objectsToPush: DatalayerObject[]) {
  window.dataLayer = window.dataLayer || [];
  objectsToPush.forEach(objectToPush => {
    window.dataLayer.push({
      ...objectToPush
    });
  });
}

function getErrorPageText(code: number, siteIdentity: SiteIdentity): string {
  if (code === 500 || code === -1) {
    // sites share this message but other codes have unique messages by site
    return 'An error has occurred, website engineers have been notified';
  } else if (siteIdentity.isLAF || siteIdentity.isLW) {
    return 'We couldn’t find this page';
  } else {
    return 'You went off the grid! Let’s get you back on the map';
  }
}

function logToServer(message: string, referringUrl: string, logSeverity: LogSeverity, apiUrlRoot = '/'): void {
  try {
    const logBody = JSON.stringify({
      message,
      referringUrl,
      logSeverity
    });

    fetch(`${apiUrlRoot}api/Logging/Log/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain'
      },
      body: logBody
    });
  } catch (ex) {
    console.error('Error trying to log to server:', ex);
  }
}

function yieldToMainThread(task: () => void) {
  setTimeout(task, 0);
}

function yieldExternalLinkClickToMainThread(event: any, url: string) {
  event.preventDefault();
  this.yieldToMainThread(() => {
    window.open(url, '_blank');
  });
}

export async function getFeatureFlagBySite(flagKey: string): Promise<boolean> {
  let flagValue = false;

  try {
    const response = await fetch(`/ api / featureflag / ${flagKey} `);
    if (response.ok) {
      const json = await response.json();

      flagValue = json.value;
    }
  } catch (error) {
    console.error(error);
  }

  return flagValue;
}

export async function getAllFeatureFlagsBySite(): Promise<{ [key: string]: boolean }> {

  let flagValues = {};
  try {
    const response = await fetch(`/api/featureflag/all`);
    if (response.ok) {
      const json = await response.json();
      flagValues = json.values;
    }
  } catch (error) {
    console.error(error);
  }
  return flagValues;
}

export async function getFeatureFlagByUser(
  flagKey: string,
  customAttributes: { Key: string; Value: string }[]
): Promise<boolean> {
  let flagValue = false;
  try {
    const response = await fetch(`/api/featureflag/${flagKey}/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain'
      },
      body: JSON.stringify(Array.from(customAttributes))
    });
    if (response.ok) {
      const json = await response.json();
      flagValue = json.value;
    }
  } catch (error) {
    console.error(error);
  }
  return flagValue;
}

export async function getAllFeatureFlagsByUser(
  customAttributes: { Key: string; Value: string }[]
): Promise<{ Key: string; Value: boolean }> {
  try {
    return fetch(`/api/featureflag/all/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain'
      },
      body: JSON.stringify(Array.from(customAttributes))
    })
      .then(response => response.json())
      .then(flagValuesResponse => flagValuesResponse.values);
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export function IsListingForSale(status: MARKET_STATUSES) {
  return status !== MARKET_STATUSES.OFF_MARKET && status !== MARKET_STATUSES.SOLD;
}

export function getPropertyTitle(propertyData) {
  if (propertyData?.title) {
    return propertyData.title;
  } else if (propertyData?.areaLabel && propertyData?.county?.name && propertyData?.state?.stateName) {
    return `${propertyData.areaLabel} in ${propertyData.county.name}, ${propertyData.state.stateName}`;
  }

  return null;
}

export function isObjectEmpty(obj: object): boolean {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}

// Returns null finance center object if a listing price and acres do not
// meet the minPrice and minAcres of that finance center.
// This is a tool that hides the financeCenter checkbox from unqualified listings.
export function enforceFinanceCenterRequirements(
  price: number,
  acres: number,
  financeCenter: FinanceCenterBasicInfo
): FinanceCenterBasicInfo {
  if (!(financeCenter && price && acres)) {
    return financeCenter;
  }
  if (price < financeCenter.minPrice || acres < financeCenter.minAcres) {
    return null;
  }
  return financeCenter;
}

export function htmlToText(html: string): string {
  // replace html characters with spaces and then remove multi spaces for single. (Also removes &# rel items)
  return html
    .replace(/(<([^>]+)>)/g, ' ')
    .split(' ')
    .filter(text => text && !text.includes('&#'))
    .join(' ')
    .replace(/\s+/g, ' ');
}

export async function ProcessApiResponse(response: Response, app: LoaApp, featureFlags: FeatureFlags): Promise<any> {
  const data = await response.json();
  const isBundledResponse = data?.featureFlags && data?.pageData;
  if (isBundledResponse && !isEqual(featureFlags, data.featureFlags)) {
    app.setState({ featureFlags: data.featureFlags });
  }

  return data?.pageData ?? data;
}

export function getLatLong() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          // One decimal place gives a maximum of ~7 miles of error in either direction.
          lat: position.coords.latitude.toFixed(1),
          long: position.coords.longitude.toFixed(1)
        });
      },
      error => {
        reject(error);
      }
    );
  });
}

export const getNearMePath = (searchPath: string, lat: string, long: string): string => {
  if (!searchPath) {
    return '';
  }

  // remove trailing slash if one exists
  const pathNoTrailingSlash = searchPath.replace(/\/$/, '');

  // If the last segment is a page number, we need to insert the lat/long before the page number.
  const lastSegment = pathNoTrailingSlash.split('/').pop();
  if (lastSegment.startsWith('page-')) {
    const segmentsWithoutPage =
      pathNoTrailingSlash.split('/').slice(0, -1).join('/') + `/lat${lat}/long${long}/` + lastSegment;
    searchPath = segmentsWithoutPage;
  } else {
    searchPath = searchPath + `lat${lat}/long${long}`;
  }

  return searchPath;
}

export const generateParagraphsFromStringArray = (paragraphs: string[], descriptionStyle, emptyParagraphStyle) => {
  const paragraphHtml = [];
  let wasPreviousEmpty = false;
  // These paragraphs periodically have empty paragraphs, this is for formatting where it should be a new paragraph in html.
  // But we need to make sure not to make back to back empty paragraphs, the database sends back consectutive empty paragraphs.
  paragraphs?.forEach((p, index) => {
    if (p && p.trim()) {
      paragraphHtml.push(
        <p data-testid={'paragraph'} className={descriptionStyle} key={index}>
          {p}
        </p>
      );

      wasPreviousEmpty = false;
    } else {
      if (!wasPreviousEmpty && index !== paragraphs.length - 1 && index !== 0) {
        paragraphHtml.push(
          <p data-testid={'paragraph'} className={emptyParagraphStyle} key={index}>
            {' '}
          </p>
        );

        wasPreviousEmpty = true;
      }
    }
  });

  return paragraphHtml;
}

const shortPriceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
  useGrouping: true,
  // typescript doesn't know about the 'notation' option so we have to tell typescript to ignore it then tell eslint to ignore us telling typescript to ignore it.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  notation: 'compact',
  compactDisplay: 'short'
});

export const toShortPrice = (value: number): string => {
  return shortPriceFormatter.format(value);
}

export function getBrokerSearchSegmentMetadata(
  segments: string[]
): [ACCOUNT_SUB_TYPES, SELLER_SEARCH_SORT_ORDER_VALUE, string] {
  let accountTypeValue = ACCOUNT_SUB_TYPES.All;
  let sortValue = SELLER_SEARCH_SORT_ORDER_VALUE.Default;
  let pageValue = 'page-0';

  segments.forEach(segment => {
    if (ACCOUNT_SUB_TYPES_URL_TO_VALUE[segment]) {
      accountTypeValue = ACCOUNT_SUB_TYPES_URL_TO_VALUE[segment];
    }

    if (segment.startsWith('sort-')) {
      sortValue = SELLER_SEARCH_URL_TO_VALUE[segment] || SELLER_SEARCH_SORT_ORDER_VALUE.Default;
    }

    if (segment.startsWith('page-')) {
      pageValue = segment;
    }
  });

  return [accountTypeValue, sortValue, pageValue];
}

function replaceSpaces(segment: string) {
  return segment?.replace(/ /g, '-') || '';
}

export const tryFetch = async (path: string) => {
  try {
    const response = await fetch(path);
    if (response.status === 200) {
      return await response.json();
    }

    return null;
  } catch (error) {
    console.warn('tryFetch', path, error);
    return null;
  }
}

export function toSiteId(siteIdentity: SiteIdentity): SITE_IDS {
  if (siteIdentity.isLand) {
    return SITE_IDS.LAND;
  }

  if (siteIdentity.isLW) {
    return SITE_IDS.LANDWATCH;
  }

  if (siteIdentity.isLAF) {
    return SITE_IDS.LANDANDFARM;
  }

  return SITE_IDS.LAND;
}

export default {
  coalesceState,
  getBreakpoint,
  getDisplaySavedSearchPopoverFromCookies,
  getErrorPageText,
  getLoginPopoverDisplayedFromCookies,
  getRecentBrokerSearchFromCookies,
  getRecentSearchesFromCookies,
  logToServer,
  pushToDataLayer,
  replaceSpaces,
  yieldExternalLinkClickToMainThread,
  yieldToMainThread
}