// Returns the listhub function that inserts the listhub api into a page.
// Use it like this: <script>{ListHubApi()}</script>
// Once per page, call initializeListHub passing this.props.store.settings.environment.ListHubProviderId.
// Then call submitListHubEvent with the event name and the property's ListHubListingKey.
// Allowed event names can be found here: https://app.listhub.com/tracking-docs/v3/supported-events.html
// Main documentation is at: http://app.listhub.com/tracking-docs/v3/listing-metrics-js.html

function listHubApiJs(environment: string) {
  const isProd = environment === 'Production';

  const initLog = isProd ? '' : "console.log('initializing listhub api for provider', id);";

  const submitLog = isProd ? '' : "console.log('submitting listhub ', event, 'for ', listingId);";

  return `
    (function (l, i, s, t, h, u, b) {
      l['ListHubAnalyticsObject'] = h; l[h] = l[h] || function () {
        (l[h].q = l[h].q || []).push(arguments)
      }, l[h].d = 1 * new Date(); u = i.createElement(s),
      b = i.getElementsByTagName(s)[0]; u.async = 1; u.src = t; b.parentNode.insertBefore(u, b)
    })(window, document, 'script', '//tracking.listhub.net/la.min.js', 'lh');

    function initializeListHub(id) {
      ${initLog}
      lh('init', {provider: id, test: ${!isProd}});
    }

    function submitListHubEvent(event, listingId, listHubId) {
      ${submitLog}
      lh('submit', event, {lkey: listHubId});
    }
  `;
}
export { listHubApiJs };
