import { LocationData } from 'store/commonInterfaces/iSearchObjects';
import { LOCATION_TYPES } from './enums';
import { ProcessApiResponse } from 'utils/convenience';
import LoaApp from 'components/App';
import { FeatureFlags } from 'store/commonInterfaces/HydrationData';
import { FinanceCenterBasicInfo, FinanceCenterFullInfo } from 'store/models/financeCenter';

export default {
  getLocationData(criteria) {
    // type of incoming not defined so I can work with clientside and server side Search Criteria objects, which are not identical.
    if (criteria.inventoryIdList && criteria.inventoryIdList.length) {
      return new LocationData(LOCATION_TYPES.PROPERTYID, criteria.inventoryIdList.slice());
    }

    if (criteria.lakeIdList && criteria.lakeIdList.length) {
      return new LocationData(LOCATION_TYPES.LAKE, criteria.lakeIdList.slice(), criteria.stateId);
    }

    if (criteria.zip) {
      return new LocationData(LOCATION_TYPES.ZIP, [parseInt(criteria.zip, 10)], criteria.stateId);
    }

    if (criteria.cityIdList && criteria.cityIdList.length) {
      return new LocationData(LOCATION_TYPES.CITY, criteria.cityIdList.slice(), criteria.stateId);
    }

    if (criteria.countyIdList && criteria.countyIdList.length) {
      return new LocationData(LOCATION_TYPES.COUNTY, criteria.countyIdList.slice(), criteria.stateId);
    }

    if (criteria.regionIdList && criteria.regionIdList.length) {
      return new LocationData(LOCATION_TYPES.REGION, criteria.regionIdList.slice(), criteria.stateId);
    }

    if ((criteria.stateId as number) > 0) {
      return new LocationData(LOCATION_TYPES.STATE, [criteria.stateId], criteria.stateId);
    }

    if (criteria.brokerId > 0) {
      return new LocationData(LOCATION_TYPES.BROKER, [criteria.brokerId]);
    }

    if (criteria.latitude && criteria.longitude) {
      return new LocationData(LOCATION_TYPES.COORDS, []);
    }

    if (
      criteria.mapUpperRightLatitude &&
      criteria.mapUpperRightLongitude &&
      criteria.mapLowerLeftLatitude &&
      criteria.mapLowerLeftLongitude
    ) {
      const isPolygon: boolean = criteria.mapEncodedCords && criteria.mapEncodedCords.length > 0;
      return new LocationData(isPolygon ? LOCATION_TYPES.POLYGON : LOCATION_TYPES.REMAP, []);
    }

    return new LocationData(LOCATION_TYPES.DEFAULT, [], criteria.stateId);
  },

  // only accepts 3 location types. Must submit one of those to get finance center targeting for the area.
  async getFinanceCenterDataByLocationData(
    siteId: number,
    locationType: LOCATION_TYPES.REGION | LOCATION_TYPES.STATE | LOCATION_TYPES.COUNTY,
    idList: number[]
  ): Promise<FinanceCenterBasicInfo> {
    const resp = await fetch(`/api/location/financeByLocationData/${siteId}/${locationType}/${idList.join('-')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain'
      }
    });

    if (resp.ok) {
      return await resp.json();
    }

    return null;
  },

  async getFinanceCenterFullInfo(
    fcId: number,
    app: LoaApp,
    featureFlags: FeatureFlags
  ): Promise<FinanceCenterFullInfo> {
    try {
      const response = await fetch(`/api/content/financeCenterFullInfo/${fcId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json, text/plain'
        }
      });

      if (response.ok) {
        return await ProcessApiResponse(response, app, featureFlags);
      }

      return null;
    } catch (error) {
      console.warn(error);
      return null;
    }
  }
};
