import utils from 'utils/convenience';
import { LinkBuilder, Routes } from './interfaces';
import { getBrokerUrlSegment } from 'utils/urlCore';

function getLandingPath(location: LinkBuilder) {
  const { cityName, countyName, regionName, stateName, stateAbbreviation } = location;

  if ((cityName || countyName) && stateAbbreviation) {
    return `/${utils.replaceSpaces(cityName || countyName)}-${stateAbbreviation}/`;
  } else if (regionName) {
    return `/${utils.replaceSpaces(regionName)}-Region/`;
  } else if (stateName) {
    return `/${utils.replaceSpaces(stateName)}/`;
  }

  return '/United-States/';
}

export const landRoutes: Partial<Routes> = {
  /********** Static routes **********/
  addListing: '/addlisting/',
  advertisingTerms: '/terms/advertising/',
  farmCreditLeadDoc: '/farmcredit/documentation/leads/',
  farmCreditListingDoc: '/farmcredit/documentation/listings/',
  terms: '/termsofuse/',
  termsAndConditionsForPhotos: '/terms/advertising/terms-and-conditions-for-submitting-photos/',

  /********** Sitemap routes **********/
  sitemap: {
    route: '/sitemap/',
    link: (location: LinkBuilder) => {
      const sitemapLocation = location.canonicalLocation ? `${utils.replaceSpaces(location.canonicalLocation)}/` : '';
      return `/sitemap/${sitemapLocation}`;
    }
  },

  /********** Broker member and search routes **********/
  brokerDirectory: { route: '/broker/:restofPath*', link: '/broker/' },
  brokerResultsMember: { route: `/member/:brokerId`, link: ({ accountId }) => `/member/${accountId}/` },
  brokerResultsMemberPaid: {
    route: `/member/:brokerName/:brokerId`,
    link: ({ accountId, contactName, companyName }) => {
      const brokerUrlSegment = getBrokerUrlSegment(contactName, companyName);
      return `/member/${brokerUrlSegment}/${accountId}`;
    }
  },
  brokerResultsMemberListings: {
    route: `/member/:brokerName/:brokerId/listings/`,
    link: ({ accountId, contactName, companyName }) => {
      const brokerUrlSegment = getBrokerUrlSegment(contactName, companyName);
      return `/member/${brokerUrlSegment}/${accountId}/listings/`;
    }
  },

  /********** Property listing routes **********/
  listing: '/listing/:restofPath*',
  property: {
    route: '/property/:restofPath*',
    link: (location: LinkBuilder) => `/property/${location.canonicalLocation}/${location.propertyId}/`
  },

  /********** Property search routes **********/
  landingState: {
    route:
      '/(District-of-Columbia|Texas|North-Carolina|Georgia|Tennessee|Florida|Michigan|Virginia|New-York|Wisconsin|California|Missouri|South-Carolina|Arizona|Arkansas|Pennsylvania|Alabama|Kentucky|Illinois|Indiana|Colorado|Minnesota|Oklahoma|New-Mexico|Ohio|Mississippi|Oregon|Montana|Idaho|Maryland|Washington|West-Virginia|New-Hampshire|New-Jersey|Louisiana|Connecticut|Massachusetts|Utah|Iowa|Vermont|Kansas|Wyoming|Maine|South-Dakota|Alaska|Hawaii|Nevada|Nebraska|Delaware|Rhode-Island|North-Dakota)',
    link: (location: LinkBuilder) => getLandingPath(location)
  },
  landingRegion: {
    route: '/:regionName-Region',
    link: (location: LinkBuilder) => getLandingPath(location)
  },
  landingCounty: {
    route: '/:countyName-County-:stateAbbreviation',
    link: (location: LinkBuilder) => getLandingPath(location)
  },
  landingBorough: {
    route: '/:countyName-Borough-:stateAbbreviation'
  },
  landingParish: {
    route: '/:countyName-Parish-:stateAbbreviation'
  },
  baseSearchUrl: {
    link: (location: LinkBuilder) => {
      const { propertyType, customSegments } = location;
      const segments = customSegments
        ? `${customSegments}`
        : propertyType
        ? `${utils.replaceSpaces(propertyType).toLowerCase()}`
        : 'all-land';

      return `${getLandingPath(location)}${segments}/`;
    }
  },
  searchWithCriteria: '/:location/:query?'
};
