import { Component, ErrorInfo } from 'react';
import { History } from 'history';
import utils from 'utils/convenience';
import GlobalHandler from 'handler/globalHandler';
import { LogSeverity } from 'utils/enums';
import SearchUI from 'store/models/searchUI';

interface Props {
  handler: GlobalHandler;
  history: History;
  children: React.ReactNode;
}

class ErrorBoundary extends Component<Props> {
  constructor(props) {
    super(props);
  }

  // Error boundaries do not catch errors for:

  // Event handlers
  // Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
  // Server side rendering
  // Errors thrown in the error boundary itself (rather than its children)
  componentDidCatch(error: Error, info: ErrorInfo): void {
    // -1 is our code for a client-side error, unlike 400 and 500 server-side errors
    this.props.handler.app.setState({
      statusCode: -1,
      searchUI: new SearchUI(this.props.handler.app.state.searchUI, { loading: false })
    });

    const message = `Error: ${error}\nReact Component: ${info.componentStack}`;
    utils.logToServer(message, window.location.href, LogSeverity.CriticalYellow);

    if (window && window.location.href.indexOf('error') === -1) {
      this.props.history.replace(`/error/?eurl=${window.location.pathname}`);
    }
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
