export class FeatureFlags {
  constructor(data: Partial<FeatureFlags>) {
    Object.assign(this, data);
  }

  injectMazeTrackingScript: boolean;
  showNewAuction: boolean;
  showPriceHistoryLDP: boolean;
  showNewMapOnListingDetail: boolean;
  showPlacardCarouselonHomePage: boolean;
  showParcelLayerOnNewMap: boolean;
  showRiskFactorsOnMap: boolean;
  showNewFiltersOnLand: boolean;
}

export interface SitemapListing {
  canonicalUrl: string;
  displayText: string;
}

export interface SitemapGeoState {
  countyLabel: string;
  countyLabelPlural: string;
  numberOfCountiesInGeo: number;
  stateName: string;
  stateAbbreviation: string;
  stateId: number;
}

export interface PopularItem {
  description?: string;
  count: number;
  name: string;
  id: number;
  stateId?: number;
  stateAbbreviation?: string;
}

export interface PropertyAmenityCategory {
  name: string;
  amenities: string[];
}

export interface PropertyAmenities {
  name: string;
  categories: PropertyAmenityCategory[];
}

export interface Metric {
  name: string;
  value: string;
  rank: number;
  count: number;
}

export interface CountyInfo {
  countyId: number;
  countyName: string;
  regionId: number;
  stateInfo: GeoStateInfo;
  lafCountyId: number;
}

export interface GeoStateInfo {
  countyLabel: string;
  countyLabelPlural: string;
  stateAbbreviation: string;
  stateId: number;
  stateName: string;
  taxRate: number;
}

export enum SeoType {
  Unset = -1,
  State = 0,
  Region = 1,
  County = 2,
  Default = 3
}
